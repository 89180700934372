import { Color } from "../../../../lib/color";
import { Units } from "../../../../lib/measurements";
import {
  makeGTOtherPropertyIfNotNullRule,
  makeLTOtherPropertyIfNotNullRule,
  outletPropertyGetter,
} from "../../../../lib/settings2-cross-validation-utils";
import { CoreContext } from "../../../calculations/types";
import {
  isClosedSystem,
  isHeatingPlantSystem,
  isMechanical,
  isPressureOnly,
} from "../../../config";
import { getTooltip, getTooltipsEntityName } from "../../../tooltips/tooltips";
import { FlowSystem, flowSystemHasReturn } from "../../flow-systems";
import { FieldType, PropertyField } from "../property-field";
import { ConditionalPropertyField, filterConditionalFields } from "../utils";
import PlantEntity from "./plant-entity";
import { PlantType, ReturnSystemPlant } from "./plant-types";
import { PlantFieldHandlers } from "./types";
import { isHotWaterRheem } from "./utils";

export function createOutletsTabFields(
  context: CoreContext,
  filled: PlantEntity,
  handlers?: PlantFieldHandlers,
): PropertyField[] {
  const { drawing } = context;
  const tooltipsEntityName = getTooltipsEntityName(filled, drawing);
  const isReturnSystem = filled.plant.type === PlantType.RETURN_SYSTEM;
  const isDrainagePit = filled.plant.type === PlantType.DRAINAGE_PIT;
  const isDrainageGreaseInterTrap =
    filled.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP;
  const isRheem =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isHotWaterRheem(drawing, filled.plant.returnType);
  const isHotWaterGeneric =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    !isHotWaterRheem(drawing, filled.plant.returnType);
  const iAmClosedSystem =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isClosedSystem(
      drawing.metadata.flowSystems[filled.plant.outlets[0].outletSystemUid],
    );
  const isDuctManifold = filled.plant.type === PlantType.DUCT_MANIFOLD;

  let outletFlowSystems = drawing.metadata.flowSystemUidsInOrder.map(
    (uid) => drawing.metadata.flowSystems[uid],
  );
  if ("outletSystemUid" in filled.plant) {
    const outletSysRole =
      drawing.metadata.flowSystems[filled.plant.outletSystemUid].role;
    outletFlowSystems = drawing.metadata.flowSystemUidsInOrder
      .map((uid) => {
        const fs = drawing.metadata.flowSystems[uid];
        return fs.role === outletSysRole ? fs : null;
      })
      .filter(Boolean) as FlowSystem[];
  }

  const res: ConditionalPropertyField[] = [
    {
      property: "plant.outletSystemUid",
      title: "Outlet Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: outletFlowSystems,
      },
      multiFieldId: "outletSystemUid",
      isShown: !isReturnSystem && !isDuctManifold,
    },
    {
      property: "plant.outletHeightAboveFloorM",
      title: "Outlet Height Above Floor",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "outletHeightAboveFloorM",
      units: Units.Meters,
      isShown:
        !isDrainagePit &&
        !isDrainageGreaseInterTrap &&
        !isReturnSystem &&
        !isDuctManifold,
    },
    ...createHotWaterPlantOutlets(context, filled, handlers),
  ];

  return filterConditionalFields(res);
}

export function createHotWaterPlantOutlets(
  context: CoreContext,
  filled: PlantEntity,
  handlers?: PlantFieldHandlers,
): ConditionalPropertyField[] {
  const { drawing } = context;
  const tooltipsEntityName = getTooltipsEntityName(filled, drawing);
  const res: ConditionalPropertyField[] = [];
  const isRheem =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isHotWaterRheem(drawing, filled.plant.returnType);

  if (filled.plant.type !== PlantType.RETURN_SYSTEM) {
    return res;
  }

  const shouldAddRecirculation = (index: number) => {
    return (filled.plant as ReturnSystemPlant).outlets[index].addRecirculation;
  };

  const numberOfOutlets = filled.plant.outlets.length;
  filled.plant.outlets.forEach((outlet, index) => {
    const isMechanicalSystem = isMechanical(
      drawing.metadata.flowSystems[outlet.outletSystemUid],
    );
    const isHeatingOutlet = isHeatingPlantSystem(
      drawing.metadata.flowSystems[outlet.outletSystemUid],
    );

    const iAmClosedSystem =
      filled.plant.type === PlantType.RETURN_SYSTEM &&
      isClosedSystem(drawing.metadata.flowSystems[outlet.outletSystemUid]);

    const disabledFlowSystems = Object.values(drawing.metadata.flowSystems)
      .filter((fs) => {
        return (
          (outlet.addRecirculation && !flowSystemHasReturn(fs)) ||
          !(isPressureOnly(fs) || isMechanical(fs))
        );
      })
      .map((fs) => fs.uid);

    res.push(
      {
        property: `plant.outlet-group-${index}`,
        title: `Outlet Group ${index + 1}`,
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Title,
        params: null,
        multiFieldId: `plant-outlet-group-${index}`,
        isShown: (filled.plant as ReturnSystemPlant).outlets.length > 1,
      },
      {
        property: `plant.outlets.${index}.outletSystemUid`,
        title: "Outlet Flow System",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.FlowSystemChoice,
        params: {
          systems: drawing.metadata.flowSystemUidsInOrder.map(
            (uid) => drawing.metadata.flowSystems[uid],
          ),
          disabledSystems: disabledFlowSystems,
        },
        multiFieldId: `plant-outlets-${index}-outletSystemUid`,
        isShown: true,
      },
      {
        property: `plant.outlets.${index}.heightAboveFloorM`,
        title: "Outlet Height Above Floor",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: null, max: null },
        multiFieldId: `plant-outlets-${index}-heightAboveFloorM`,
        units: Units.Meters,
        isShown: true,
      },
      {
        property: `plant.outlets.${index}.outletTemperatureC`,
        title: "Outlet Temperature",
        hint: getTooltip(tooltipsEntityName, "Outlet Temperature"),
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: null, max: null },
        multiFieldId: `plant-outlets-${index}-outletTemperatureC`,
        units: Units.Celsius,
        isShown: !isRheem,
        validationRules: [
          isHeatingOutlet
            ? makeGTOtherPropertyIfNotNullRule(
                "Outlet temperature",
                "Return temperature",
                outletPropertyGetter<number | null>(
                  context,
                  filled.uid,
                  index,
                  "returnLimitTemperatureC",
                ),
              )
            : makeLTOtherPropertyIfNotNullRule(
                "Outlet temperature",
                "Return temperature",
                outletPropertyGetter<number | null>(
                  context,
                  filled.uid,
                  index,
                  "returnLimitTemperatureC",
                ),
              ),
        ],
      },
      {
        property: `plant.outlets.${index}.addRecirculation`,
        title: "Is there a recirculation system?",
        hint: getTooltip(tooltipsEntityName, "Is there a recirculation system"),
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Boolean,
        params: null,
        multiFieldId: `plant-outlets-${index}-addRecirculation`,
        isShown: true,
      },
      {
        property: `plant.outlets.${index}.recircPumpOnReturn`,
        title: `Is the recirculation pump on the return?`,
        hint: getTooltip(tooltipsEntityName, "Pump on the return or feed"),
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Boolean,
        params: null,
        multiFieldId: `plant-outlets-${index}-recircPumpOnReturn`,
        isShown: shouldAddRecirculation(index) && iAmClosedSystem,
      },
      {
        property: `plant.outlets.${index}.calculatePipeHeatLoad`,
        title: `Include Pipe Heat Load?`,
        hint: getTooltip(tooltipsEntityName, "Should include pipe heat load"),
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Boolean,
        params: null,
        multiFieldId: `plant-outlets-${index}-calculatePipeHeatLoad`,
        isShown: shouldAddRecirculation(index) && iAmClosedSystem,
      },
      {
        property: `plant.outlets.${index}.returnLimitTemperatureC`,
        title: `${isHeatingOutlet ? "Minimum" : "Maximum"} Return Temperature`,
        hint: getTooltip(
          isHeatingOutlet ? tooltipsEntityName : null,
          "Minimum Return Temperature",
        ),
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: null, max: null },
        multiFieldId: `plant-outlets-${index}-returnLimitTemperatureC`,
        units: Units.Celsius,
        isShown: shouldAddRecirculation(index),
        validationRules: [
          isHeatingOutlet
            ? makeLTOtherPropertyIfNotNullRule(
                "Return temperature",
                "Outlet temperature",
                outletPropertyGetter<number | null>(
                  context,
                  filled.uid,
                  index,
                  "outletTemperatureC",
                ),
              )
            : makeGTOtherPropertyIfNotNullRule(
                "Return temperature",
                "Outlet temperature",
                outletPropertyGetter<number | null>(
                  context,
                  filled.uid,
                  index,
                  "outletTemperatureC",
                ),
              ),
        ],
      },
      {
        property: `plant.outlets.${index}.returnVelocityMS`,
        title: "Maximum Return Velocity (M/s)",
        hasDefault: true,
        highlightOnOverride: Color.YELLOW,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: `plant-outlets-${index}-returnVelocityMS`,
        units: Units.MetersPerSecond,
        isShown:
          (shouldAddRecirculation(index) && !isMechanicalSystem) ||
          (isRheem && iAmClosedSystem),
      },
      {
        property: `plant.outlets.${index}.pressureDropKPA`,
        title: "Internal Pressure Drop",
        hint: getTooltip(tooltipsEntityName, "Internal Pressure Drop"),
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: `plant-outlets-${index}-pressureDropKPA`,
        units: Units.KiloPascals,
        isShown: shouldAddRecirculation(index),
      },
      {
        property: `plant.outlets.${index}.addReturnToPSDFlowRate`,
        title: "Add Return to PSD Flow Rate",
        hint: getTooltip(tooltipsEntityName, "Add Return to PSD Flow Rate"),
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Boolean,
        params: null,
        multiFieldId: `plant-outlets-${index}-addReturnToPSDFlowRate`,
        isShown: shouldAddRecirculation(index) || isRheem,
      },
      {
        type: FieldType.Button,
        property: `removePlantOutlet${index}`,
        title: "Remove Outlet",
        hasDefault: false,
        isCalculated: false,
        multiFieldId: null,
        size: "sm",
        pill: true,
        variant: "outline-danger",
        params: {
          handler: async () =>
            handlers?.removeHotWaterOutletHandler?.(context, filled.uid, index),
        },
        isShown: numberOfOutlets > 1,
      },
      {
        property: `plant.separator-${index}`,
        title: "",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Divider,
        multiFieldId: `plant-separator-${index}`,
        isShown: numberOfOutlets > 1,
      },
    );
  });

  res.push({
    type: FieldType.Button,
    property: "addPlantOutlet",
    title: "+ Add a New Outlet",
    hasDefault: false,
    isCalculated: false,
    multiFieldId: null,
    size: "sm",
    pill: true,
    variant: "outline-success",
    params: {
      handler: async () =>
        handlers?.addNewHotWaterOutletHandler?.(context, filled.uid),
    },
    isShown: true,
  });

  return res;
}
