import Flatten from "@flatten-js/core";
import { getEntitySystem } from "../../../../common/src/api/calculations/utils";
import CoreBaseBackedObject from "../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import {
  CoolDragEntityConcrete,
  DrawableEntityConcrete,
  isCoolDragEntity,
} from "../../../../common/src/api/document/entities/concrete-entity";
import {
  ENTITY_TYPE_ABBREV,
  ENTITY_TYPE_MIN_X_MODE_SCALE_DRAW,
  ENTITY_TYPE_X_MODE_COLOR,
  EntityType,
} from "../../../../common/src/api/document/entities/types";
import { getLevelBelowUid } from "../../../../common/src/api/document/entities/utils";
import { lighten } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import CanvasContext from "../../../src/htmlcanvas/lib/canvas-context";
import { trackEvent } from "../../api/mixpanel";
import {
  EntityEvent,
  EntityParam,
  HighlightedGroup,
  UIState,
} from "../../store/document/types";
import { MainEventBus } from "../../store/main-event-bus";
import { launchCoolDragTool } from "../lib/black-magic/cool-drag-tool";
import DrawableObject from "../lib/drawable-object";
import { HeatmapMode } from "../lib/heatmap/heatmap";
import { Interaction } from "../lib/interaction";
import { isSizable } from "../lib/object-traits/sizeable-object";
import { DrawingContext } from "../lib/types";
import {
  DrawableObjectConcrete,
  HoverableObjectConcrete,
  isHoverableObject,
  isHoverableObjectAny,
} from "../objects/concrete-object";
import { ResizeControl } from "../objects/resize-control";
import {
  DrawingMode,
  MouseMoveResult,
  UNHANDLED,
  drawingModeEnumToLabel,
} from "../types";
import { cooperativeYield } from "../utils";

export const KNOWN_MISSING_ENTITIES: Set<string> = new Set();

export const MAX_INTERACTION_RECIPIENT_RADIUS_WC = 100;
export default interface Layer {
  name: string;
  uidsByZIndex: Map<string, number>;

  draw(
    context: DrawingContext,
    active: boolean,
    shouldContinue: () => boolean,
    exclude: Set<string>,
    ...args: any[]
  ): Promise<any>;

  drawReactiveLayer(
    context: DrawingContext,
    interactive: string[],
    reactive: Set<string>,
  ): any;

  onMouseMove(
    event: MouseEvent,
    context: CanvasContext,
    entityUid: string | undefined,
  ): MouseMoveResult;
  onMouseDown(event: MouseEvent, context: CanvasContext): boolean;
  onMouseUp(event: MouseEvent, context: CanvasContext): boolean;
  onMouseLeave(event: MouseEvent, context: CanvasContext): boolean;

  // Cooldrag is a subset of multi drag. Cool drag gets called in parallel to multi
  // drag if there was a multi drag. Cool drag makes pipe sliding, snapping mechanics
  // work on the hydraulics components.
  // Calling this function transfers responsibility of event handling from the object
  // to the layer.
  onCoolDragStart(
    event: MouseEvent,
    world: Coord,
    context: CanvasContext,
    coolDragEntities: CoolDragEntityConcrete[],
    subject: CoolDragEntityConcrete,
  ): any;

  onMultiDragStart(
    event: MouseEvent,
    world: Coord,
    context: CanvasContext,
    subject: DrawableObject,
  ): any;

  onMultiDrag(
    event: MouseEvent,
    world: Coord,
    grabState: any,
    context: CanvasContext,
  ): void;
  onMultiDragFinish(
    event: MouseEvent,
    grabState: any,
    context: CanvasContext,
  ): void;

  isSelected(object: DrawableObjectConcrete | string): boolean;

  offerInteraction(
    interaction: Interaction,
    filter?: (objects: DrawableEntityConcrete[]) => boolean,
    sortBy?: (objects: DrawableEntityConcrete[]) => any,
    reduce?: (results: DrawableEntityConcrete[][]) => DrawableEntityConcrete[],
  ): DrawableEntityConcrete[] | null;

  off(): void;
  reloadLevel(): void;
}

export enum SelectMode {
  Replace,
  Toggle,
  Add,
  Exclude,
}

export abstract class LayerImplementation implements Layer {
  name: string;
  context: CanvasContext;
  uidsByZIndex: Map<string, number> = new Map();
  resizeBoxes: Map<string, ResizeControl> = new Map();
  hasFailedToRenderLayer: boolean = false;

  constructor(context: CanvasContext) {
    this.context = context;

    MainEventBus.$on("current-level-changed", this.reloadLevel.bind(this));
    MainEventBus.$on(EntityEvent.ADD_ENTITY, this.addEntity.bind(this));
    MainEventBus.$on(
      EntityEvent.POST_DELETE_ENTITY,
      this.deleteEntity.bind(this),
    );

    this.reloadLevel();
  }

  off() {
    MainEventBus.$off("current-level-changed", this.reloadLevel.bind(this));
    MainEventBus.$off(EntityEvent.ADD_ENTITY, this.addEntity.bind(this));
    MainEventBus.$off(
      EntityEvent.POST_DELETE_ENTITY,
      this.deleteEntity.bind(this),
    );
  }

  addEntity({ entity }: EntityParam) {
    if (this.shouldAccept(entity)) {
      if (this.uidsByZIndex.has(entity.uid)) {
        // This can happen if we were asked to reset level during a multi operation with a lagged event
        // that was behind the global state. But we will tolerate this.
        return;
      }

      this.uidsByZIndex.set(entity.uid, this.getEntityZIndex(entity));
    }
  }

  istempVisibleSystemUidsOff(
    context: DrawingContext,
    object: DrawableObjectConcrete | undefined,
  ): boolean {
    if (!object) {
      console.warn(
        "Attempting to check temp visible system UIDs without an object",
      );
      return false;
    }

    const systemUid = getEntitySystem(object.entity, context.globalStore);
    const tempVisibleSystemUids =
      context.doc.uiState.systemFilter.tempVisibleSystemUids;
    const hiddenSystemUids = context.doc.uiState.systemFilter.hiddenSystemUids;
    if (!systemUid) {
      return false;
    }
    if (tempVisibleSystemUids.includes(systemUid)) {
      return false;
    }
    if (!hiddenSystemUids.includes(systemUid)) {
      return false;
    }
    return true;
  }

  deleteEntity({ entity }: EntityParam) {
    if (this.uidsByZIndex.has(entity.uid)) {
      this.uidsByZIndex.delete(entity.uid);
    }
  }

  reloadLevel() {
    this.uidsByZIndex.clear();

    // Our sync with the globalstore is only eventually consistent, but this is still OK.
    // If we miss out things that would later be deleted, that's OK.
    // If we add things that would later be added, that's OK too.
    this.context.globalStore.forEach((o) => {
      if (this.shouldAccept(o.entity)) {
        this.uidsByZIndex.set(o.entity.uid, this.getEntityZIndex(o.entity));
      }
    });
  }

  get selectedIds(): string[] {
    return this.context.document.uiState.selectedUids;
  }

  get selectedObjects() {
    return this.selectedIds.map((uid) => this.context.globalStore.get(uid)!);
  }

  get selectedEntities() {
    return this.selectedObjects.map((o) => o.entity);
  }

  abstract shouldAccept(entity: DrawableEntityConcrete): boolean;

  abstract getEntityZIndex(entity: DrawableEntityConcrete): number;

  isSelected(object: DrawableObjectConcrete | string) {
    if (typeof object === "string") {
      return this.selectedIds.indexOf(object) !== -1;
    } else {
      return this.selectedIds.indexOf(object.uid) !== -1;
    }
  }

  async draw(
    context: DrawingContext,
    active: boolean,
    shouldContinue: () => boolean,
    exclude: Set<string>,
    mode: DrawingMode,
    withCalculation: boolean,
    forExport: boolean,
    showExport: boolean,
    heatmapMode: HeatmapMode | undefined,
    heatmapSettings: UIState["heatmapSettings"] | undefined,
    print?: boolean,
  ) {
    try {
      context.ctx.resetTransform();
      this.updateSelectionBoxes();

      const YIELD_LIMIT_MS = 25;
      let lastTimer = Date.now();

      let ctx = context.ctx;
      let hiddenCanvas: HTMLCanvasElement | null = null;
      if (!forExport) {
        hiddenCanvas = document.createElement("canvas");
        hiddenCanvas.width = context.vp.width;
        hiddenCanvas.height = context.vp.height;
        ctx = hiddenCanvas.getContext("2d")!;
      }

      const uidsInOrder = this.getVisibleUidsInOrder(context);
      for (let i = 0; i < uidsInOrder.length; i++) {
        const v = uidsInOrder[i];

        if (!exclude.has(v)) {
          if (!active || !this.isSelected(v)) {
            try {
              const o = this.context.globalStore.get(v);

              if (!o) {
                if (!KNOWN_MISSING_ENTITIES.has(v)) {
                  console.error("Attempted to draw missing entity", {
                    id: v,
                  });
                }
                continue;
              }

              const includesHighlight =
                this.context.document.uiState.exportSettings.includesHighlight;
              if (
                (showExport && context.doc.uiState.exportSettings.isAppendix) ||
                !(active || withCalculation) ||
                !this.istempVisibleSystemUidsOff(context, o)
              ) {
                if (print) {
                  if (o.entity.type === EntityType.ROOM) {
                    console.warn("drawing room", o.entity.uid);
                  }
                }
                o.draw(
                  {
                    ctx,
                    doc: context.doc,
                    catalog: context.catalog,
                    vp: context.vp,
                    drawing: context.drawing,
                    globalStore: context.globalStore,
                    locale: context.locale,
                    nodes: context.nodes,
                    priceTable: context.priceTable,
                    selectedUids: context.selectedUids,
                    graphics: {
                      unitWorldLength: 1,
                      worldToSurfaceScale: context.vp.currToSurfaceScale(
                        context.ctx,
                      ),
                    },
                    featureAccess: context.featureAccess,
                    featureFlags: context.featureFlags,
                  },
                  {
                    layerName: this.name,
                    active,
                    withCalculation,
                    forExport,
                    includesHighlight,
                    heatmapMode,
                    heatmapSettings,
                  },
                );
              }
            } catch (e) {
              // tslint:disable-next-line:no-console
              console.error(e);
            }
          }
        }
        const timerNow = Date.now();
        if (timerNow - lastTimer > YIELD_LIMIT_MS) {
          await cooperativeYield(shouldContinue);
          lastTimer = timerNow;
        }
      }

      if (hiddenCanvas) {
        context.ctx.resetTransform();
        context.ctx.drawImage(hiddenCanvas, 0, 0);
      }
      // this.highlightedGroupsDraw(context);
      this.xModeDraw(context, uidsInOrder);
    } catch (e) {
      if (!this.hasFailedToRenderLayer) {
        this.hasFailedToRenderLayer = true;
        trackEvent({
          type: "Layer Render Failed",
          props: {
            layer: this.name,
            active,
            mode: drawingModeEnumToLabel(mode),
            forExport,
            withCalculation,
            drawingLayout: context.doc.uiState.drawingLayout,
            activeflowSystemUid: context.doc.activeflowSystemUid,
            toolHandlerName: context.doc.uiState.toolHandlerName ?? undefined,
          },
        });
        console.error("Layer Render Failed", {
          error: e,
          layer: this.name,
          drawingLayout: context.doc.uiState.drawingLayout,
          activeflowSystemUid: context.doc.activeflowSystemUid,
          toolHandlerName: context.doc.uiState.toolHandlerName,
        });
      }
    }
  }

  xModeDraw(context: DrawingContext, uidsInOrder: string[]) {
    if (context.doc.uiState.xMode) {
      // show UIDs of all objects.
      const ctx = context.ctx;

      for (let i = 0; i < uidsInOrder.length; i++) {
        const v = uidsInOrder[i];

        ctx.resetTransform();
        const scale = context.vp.toSurfaceLength(1);

        try {
          const o = this.context.globalStore.get(v)!;
          if (!o.isActive()) {
            continue;
          }
          const shape = o.shape;
          if (!shape) {
            continue;
          }
          if (ENTITY_TYPE_MIN_X_MODE_SCALE_DRAW[o.type] > scale) {
            continue;
          }
          const box = shape.box;
          const center = box.center;

          const screenCenter = context.vp.toScreenCoord(center);

          let fontSize = 18;
          if (scale < 0.02) {
            fontSize = 8;
          } else if (scale < 0.1) {
            fontSize = 10;
          } else if (scale < 0.7) {
            fontSize = 12;
          } else {
            fontSize = 15;
          }

          ctx.font = fontSize + "px Arial";
          let width = Math.max(
            ctx.measureText(ENTITY_TYPE_ABBREV[o.type]).width,
            ctx.measureText(o.uid.substring(0, 4)).width,
          );
          width += 2;
          const height = fontSize + 2;
          ctx.fillStyle = lighten(ENTITY_TYPE_X_MODE_COLOR[o.type], 50, 0.5);
          ctx.fillRect(
            screenCenter.x - 1,
            screenCenter.y - fontSize - 1,
            width,
            height,
          );

          ctx.fillStyle = "black";
          // ctx.fillText(
          //   ENTITY_TYPE_ABBREV[o.type],
          //   screenCenter.x,
          //   screenCenter.y
          // );
          ctx.fillTextStable(
            o.uid.substring(0, 4),
            screenCenter.x,
            screenCenter.y,
            undefined,
            "bottom",
          );
        } catch (e) {
          // noop
        }
      }
    }
  }

  highlightedGroupsDraw(context: DrawingContext) {
    for (let i = 0; i < context.doc.uiState.highlightedGroups.length; i++) {
      const group = context.doc.uiState.highlightedGroups[i];
      this.highlightedGroupDraw(context, group);
    }
  }

  highlightedGroupDraw(context: DrawingContext, group: HighlightedGroup) {
    const ctx = context.ctx;
    const worldScale = this.context.viewPort.toSurfaceLength(1);

    for (let i = 0; i < group.entityUids.length; i++) {
      const v = group.entityUids[i];

      ctx.resetTransform();

      try {
        const o = this.context.globalStore.get(v)!;
        const entLevel = this.context.globalStore.levelOfEntity.get(v);
        if (entLevel !== this.context.document.uiState.levelUid) {
          continue;
        }
        if (!o.isActive) {
          continue;
        }
        const shape = o.shape;
        if (!shape) {
          continue;
        }

        // draw segments (pipes) differently to the other components
        // this is to handle diagonal pipes
        if (shape instanceof Flatten.Segment) {
          const xmin = context.vp.toScreenCoord({
            x: shape.box.xmin,
            y: 0,
          }).x;

          const xmax = context.vp.toScreenCoord({
            x: shape.box.xmax,
            y: 0,
          }).x;

          const ymin = context.vp.toScreenCoord({
            x: 0,
            y: shape.box.ymin,
          }).y;

          const ymax = context.vp.toScreenCoord({
            x: 0,
            y: shape.box.ymax,
          }).y;

          const width = xmax - xmin;
          const height = ymax - ymin;
          const length = Math.sqrt(width * width + height * height);
          const scale = worldScale * 50;

          ctx.translate(xmin + width / 2, ymin + height / 2);
          ctx.rotate(shape.slope);

          // fill the rect offset by half its size
          ctx.shadowBlur = 10;
          ctx.shadowColor = group.color;
          ctx.lineWidth = 5;
          ctx.strokeStyle = group.color;
          ctx.strokeRect(-length / 2, -scale, length, scale * 2);
          ctx.shadowBlur = 0;

          ctx.rotate(-shape.slope);
          ctx.translate(-(xmin + width / 2), -(ymin + height / 2));
          ctx.shadowBlur = 0;
        } else {
          const box = shape.box;
          const topleft = context.vp.toScreenCoord({
            x: box.xmin - 50,
            y: box.ymin - 50,
          });
          const bottomright = context.vp.toScreenCoord({
            x: box.xmax + 50,
            y: box.ymax + 50,
          });

          ctx.shadowBlur = 10;
          ctx.shadowColor = group.color;
          ctx.lineWidth = 5;
          ctx.strokeStyle = group.color;
          ctx.strokeRect(
            topleft.x,
            topleft.y,
            bottomright.x - topleft.x,
            bottomright.y - topleft.y,
          );
          ctx.shadowBlur = 0;
        }
      } catch (e) {
        // noop
      }
    }
  }

  getVisibleUidsInOrder(context: DrawingContext) {
    const visibleTopLeftCoord = context.vp.toWorldCoord({ x: 0, y: 0 });
    const visibleBottomRightCoord = context.vp.toWorldCoord({
      x: context.vp.width,
      y: context.vp.height,
    });

    const currLevel = this.context.document.uiState.levelUid!;
    const levelBelow = getLevelBelowUid(this.context.drawing, currLevel);
    const tree = this.context.globalStore.spatialIndex.get(currLevel);

    const query = {
      minX: visibleTopLeftCoord.x,
      maxX: visibleBottomRightCoord.x,
      minY: visibleTopLeftCoord.y,
      maxY: visibleBottomRightCoord.y,
    };
    const renderUids = new Set(tree?.search(query).map((v) => v.uid));
    if (levelBelow) {
      const treeBelow = this.context.globalStore.spatialIndex.get(levelBelow);
      const renderBelowSet = new Set(
        treeBelow?.search(query).map((v) => v.uid),
      );

      [...renderBelowSet].forEach((uid: string) => {
        renderUids.add(uid);
      });
    }

    return Array.from(renderUids)
      .filter((v) => this.uidsByZIndex.has(v))
      .sort((a, b) => {
        const aZIndex = this.uidsByZIndex.get(a)!;
        const bZIndex = this.uidsByZIndex.get(b)!;
        return aZIndex - bZIndex;
      });
  }

  updateSelectionBoxes() {
    const selectedResizableUids = [];
    for (const o of this.selectedObjects) {
      if (isSizable(o)) {
        if (!this.resizeBoxes.has(o.uid)) {
          this.resizeBoxes.set(
            o.uid,
            new ResizeControl(
              o,
              () => {
                this.context.$store.dispatch("document/validateAndCommit");
              },
              () => this.context.scheduleDraw(),
            ),
          );
        }
        selectedResizableUids.push(o.uid);
      }
    }
    for (const [uid, _resizeBox] of this.resizeBoxes) {
      if (!selectedResizableUids.includes(uid)) {
        this.resizeBoxes.delete(uid);
      }
    }
  }
  drawReactiveLayer(
    context: DrawingContext,
    uncommitted: string[],
    reactive: Set<string>,
  ) {
    const selectedSet = new Set(this.selectedIds);

    const uidsToDraw = Array.from(
      new Set([...uncommitted, ...Array.from(reactive), ...this.selectedIds]),
    ).filter((uid) => this.uidsByZIndex.has(uid));
    uidsToDraw.sort(
      (a, b) => this.uidsByZIndex.get(a)! - this.uidsByZIndex.get(b)!,
    );

    const newContext: DrawingContext = {
      ...context,
      selectedUids: new Set<string>([...selectedSet, ...uncommitted]),
    };

    uidsToDraw.forEach((uid) => {
      const o = this.context.globalStore.get(uid);
      if (o) {
        o.draw(newContext, {
          layerName: `${this.name}:reactive`,
          active: this.selectedIds.includes(o.uid),
          withCalculation: false,
          forExport: false,
        });
      }
    });
  }

  getInRangeUids(wc: Coord, radius: number): Set<string> {
    const tree = this.context.globalStore.spatialIndex.get(
      this.context.document.uiState.levelUid!,
    );

    const query = {
      minX: wc.x - radius,
      minY: wc.y - radius,
      maxX: wc.x + radius,
      maxY: wc.y + radius,
    };
    return new Set(tree?.search(query).map((o) => o.uid) || []);
  }

  offerInteraction(
    interaction: Interaction,
    filter?: (objects: DrawableEntityConcrete[]) => boolean,
    sortKey?: (objects: DrawableEntityConcrete[]) => any,
    reduce?: (objects: DrawableEntityConcrete[][]) => DrawableEntityConcrete[],
  ): DrawableEntityConcrete[] | null {
    const candidates: Array<[any, DrawableEntityConcrete[]]> = [];

    const inRange = this.getInRangeUids(
      interaction.worldCoord,
      interaction.worldRadius + MAX_INTERACTION_RECIPIENT_RADIUS_WC,
    );
    if (interaction.mustConsiderUids)
      interaction.mustConsiderUids.forEach((element) => {
        inRange.add(element);
      });
    const uidsInOrder = Array.from(inRange)
      .filter((uid) => this.uidsByZIndex.has(uid))
      .sort((a, b) => this.uidsByZIndex.get(a)! - this.uidsByZIndex.get(b)!);

    for (let i = uidsInOrder.length - 1; i >= 0; i--) {
      const uid = uidsInOrder[i];

      if (this.context.globalStore.has(uid)) {
        const object = this.context.globalStore.get(uid)!;
        if (object.entity === undefined) {
          throw new Error("object is deleted but still in this layer " + uid);
        }
        const objectCoord = object.toObjectCoord(interaction.worldCoord);
        const objectLength = object.toObjectLength(interaction.worldRadius);
        if (object.inBounds(objectCoord, objectLength)) {
          const result = object.offerInteraction(interaction);
          if (result && result.length) {
            if (filter === undefined || filter(result)) {
              if (sortKey === undefined) {
                if (reduce === undefined) {
                  return result;
                } else {
                  candidates.push([null, result]);
                }
              } else {
                candidates.push([sortKey(result), result]);
              }
            }
          }
        }
      }
    }

    if (candidates.length) {
      if (reduce === undefined) {
        let best = candidates[0][1];
        let bestScore = candidates[0][0];

        for (let i = 1; i < candidates.length; i++) {
          if (candidates[i][0] > bestScore) {
            bestScore = candidates[i][0];
            best = candidates[i][1];
          }
        }
        return best;
      } else {
        return reduce(candidates.map((v) => v[1]));
      }
    }

    return null;
  }

  getSortedUidsNearMouse(
    event: MouseEvent,
    context: CanvasContext,
    pixels: number = 5,
  ) {
    const wc = context.viewPort.toWorldCoord({
      x: event.clientX,
      y: event.clientY,
    });
    const worldScale = 1 / this.context.viewPort.toSurfaceLength(1);

    const inRange = this.getInRangeUids(
      wc,
      pixels * worldScale + MAX_INTERACTION_RECIPIENT_RADIUS_WC,
    );

    return Array.from(inRange)
      .filter((uid) => this.uidsByZIndex.has(uid))
      .sort((a, b) => this.uidsByZIndex.get(a)! - this.uidsByZIndex.get(b)!);
  }

  onMouseDown(event: MouseEvent, context: CanvasContext) {
    const uidsInOrder = this.getSortedUidsNearMouse(event, context);

    for (let i = uidsInOrder.length - 1; i >= 0; i--) {
      const uid = uidsInOrder[i];

      if (this.context.globalStore.has(uid)) {
        const object = this.context.globalStore.get(uid)!;

        if (isHoverableObject(object) && !object.isInteractable) {
          return false;
        }

        try {
          if (object.onMouseDown(event, context)) {
            return true;
          }
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.log(e);
        }
      }
    }

    return false;
  }

  // a list of elements that are in the hovered state, but not necessarily
  // hovered because
  hoveredIds = [] as string[];

  onMouseMove(
    event: MouseEvent,
    context: CanvasContext,
    entityUid?: string,
  ): MouseMoveResult {
    // Hovering.
    let hoverTarget: HoverableObjectConcrete | null = null;
    if (entityUid) {
      const obj = this.context.globalStore.get(entityUid);
      if (isHoverableObjectAny(obj)) {
        hoverTarget = obj;
      }
    }
    let changed = false;

    const worldScale = 1 / this.context.viewPort.toSurfaceLength(1);
    const uidsInOrder = this.selectedIds.concat(
      this.getSortedUidsNearMouse(event, context),
    );

    const wc = context.viewPort.toWorldCoord({
      x: event.clientX,
      y: event.clientY,
    });

    for (let i = uidsInOrder.length - 1; i >= 0; i--) {
      const uid = uidsInOrder[i];

      const object = this.context.globalStore.get(uid)!;
      if (!object) {
        continue;
      }

      if (isHoverableObject(object) && object.isInteractable) {
        if (
          !hoverTarget &&
          object.inBounds(
            object.toObjectCoord(wc),
            // add a little buffer to avoid flickering in and out of hover.
            object.isHovering ? object.toObjectLength(5 * worldScale) : 1,
          )
        ) {
          if (!object.isHovering) {
            changed = true;
            object.isHovering = true;
          }
          if (this.hoveredIds.indexOf(object.uid) === -1) {
            this.hoveredIds.push(object.uid);
          }
          hoverTarget = object;
        }
      }
    }

    this.hoveredIds = this.hoveredIds.filter((id) => {
      if (id !== hoverTarget?.uid) {
        const object = this.context.globalStore.get(id);
        if (isHoverableObject(object) && object.isInteractable) {
          if (object.isHovering) {
            changed = true;
            object.isHovering = false;
          }
        }
        return false;
      } else {
        return true;
      }
    });

    const q: HoverableObjectConcrete[] = hoverTarget ? [hoverTarget] : [];
    while (q.length) {
      const o = q.pop()!;
      const siblings = o.getHoverSiblings();
      for (const so of siblings) {
        if (isHoverableObject(so.object) && so.object.isInteractable) {
          if (!so.object.isHovering) {
            changed = true;
            so.object.isHovering = true;
          }
        }
        if (this.hoveredIds.indexOf(so.object.uid) === -1) {
          this.hoveredIds.push(so.object.uid);
        }
        if (so.cascade) {
          q.push(so.object);
        }
      }
    }

    if (changed) {
      this.context.scheduleDraw();
    }

    for (let i = uidsInOrder.length - 1; i >= 0; i--) {
      const uid = uidsInOrder[i];

      if (this.context.globalStore.has(uid)) {
        try {
          const object = this.context.globalStore.get(uid)!;
          const res = object.onMouseMove(event, context);
          if (res.handled) {
            return res;
          }
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.log("warning: error in object handler for mouseMove: ");
          // tslint:disable-next-line:no-console
          console.log(e);
        }
      }
    }
    return UNHANDLED;
  }

  onMouseUp(event: MouseEvent, context: CanvasContext) {
    const uidsInOrder = this.selectedIds.concat(
      this.getSortedUidsNearMouse(event, context),
    );

    for (let i = uidsInOrder.length - 1; i >= 0; i--) {
      const uid = uidsInOrder[i];
      if (this.context.globalStore.has(uid)) {
        const object = this.context.globalStore.get(uid)!;

        if (isHoverableObject(object) && !object.isInteractable) {
          return false;
        }

        try {
          if (object.onMouseUp(event, context)) {
            return true;
          }
        } catch (e) {
          // tslint:disable-next-line:no-console
          console.log("warning: error in object handler for mouseMove: ");
          // tslint:disable-next-line:no-console
          console.log(e);
        }
      }
    }

    if (!event.shiftKey && !event.ctrlKey) {
      this.context.document.uiState.selectedUids.splice(0);
    }

    return false;
  }

  onMouseLeave(event: MouseEvent, context: CanvasContext) {
    // If we are entering a entity popup DOM component, we should not clear the hovering state.
    if (event.relatedTarget && event.relatedTarget instanceof HTMLElement) {
      // print classes of entire parent chain
      let parent: HTMLElement | null = event.relatedTarget;
      let hasEntityElement = false;
      while (parent) {
        if (parent.hasAttribute("data-entity-element")) {
          hasEntityElement = true;
          break;
        }
        parent = parent.parentElement;
      }

      if (hasEntityElement) {
        return true;
      }
    }

    if (this.hoveredIds.length == 0) {
      return true;
    }

    // clear hovering state
    this.hoveredIds.forEach((id) => {
      const entity = context.globalStore.get(id);
      if (!entity || !isHoverableObject(entity)) return;
      entity.isHovering = false;
    });
    this.hoveredIds = [];

    // redraw
    this.context.scheduleDraw();

    return true;
  }

  // lower order gets drag event processed first.

  onCoolDragStart(
    event: MouseEvent,
    world: Coord,
    context: CanvasContext,
    entities: CoolDragEntityConcrete[],
    subject: CoolDragEntityConcrete | null,
  ) {
    launchCoolDragTool({
      context,
      entities,
      subject,
      world,
      event,
    });
  }

  onMultiDragStart(
    event: MouseEvent,
    world: Coord,
    context: CanvasContext,
    subject: DrawableObject,
  ) {
    const coolDragEntities = this.selectedObjects
      .map((o) => o.entity)
      .filter((e) => isCoolDragEntity(e)) as CoolDragEntityConcrete[];

    if (coolDragEntities.length > 0) {
      if (!subject) {
        throw new Error("subject not found");
      }
      this.onCoolDragStart(
        event,
        world,
        context,
        coolDragEntities,
        subject instanceof CoreBaseBackedObject ? subject.entity : null,
      );
    }
  }

  onMultiDrag(
    _event: MouseEvent,
    _world: Coord,
    _grabState: any,
    _context: CanvasContext,
  ): void {
    // Noop
  }
  onMultiDragFinish(
    _event: MouseEvent,
    _grabState: any,
    _context: CanvasContext,
  ): void {
    // noop
  }
}

export interface CoolDragGrabState {
  initialObjectCoords: Map<string, Coord>;
  initialWC: Coord;
  subject: DrawableEntityConcrete | null;
  // Start with dragging everything literally.
  entities: CoolDragEntityConcrete[];
}
