import { GetPressureLossOptions } from "../../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../../calculations/types";
import {
  Calculation,
  LiveCalculation,
} from "../../document/calculations-objects/types";
import { CalculatableEntityConcrete } from "../../document/entities/concrete-entity";
import CoreBaseBackedObject from "./coreBaseBackedObject";
import { SelectionTarget } from "./types";

export abstract class CoreCalculatableObject<
  T extends CalculatableEntityConcrete = CalculatableEntityConcrete,
> extends CoreBaseBackedObject<T> {
  abstract getCalculationEntities(
    context: CoreContext,
  ): CalculatableEntityConcrete[];

  abstract collectCalculations(context: CoreContext): Calculation;
  abstract collectLiveCalculations(context: CoreContext): LiveCalculation;

  // Quantity, and string into index.
  abstract getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult;

  getCalculationUid(_context: CoreContext): string {
    return this.entity.uid + ".calculation";
  }

  abstract preCalculationValidation(
    context: CoreContext,
  ): SelectionTarget | null;

  abstract costBreakdown(context: CoreContext): CostBreakdown | null;
}
