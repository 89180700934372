import Flatten from "@flatten-js/core";
import { CoreConnectableObjectConcrete, CoreObjectConcrete } from ".";
import { Coord3D } from "../../lib/coord";
import { cloneNaive } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import { EdgeLiveCalculation } from "../document/calculations-objects/edge-calculation";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import {
  EdgeEntity,
  fillDefaultRoomEdgeFields,
} from "../document/entities/edge-entity";
import { EntityType } from "../document/entities/types";
import { CoreBaseEdge } from "./core-traits/coreBaseEdge";
import CoreRoom from "./coreRoom";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import { externalSegmentDetermineDirectionCW } from "./utils";

const Base = CoreBaseEdge(CoreCalculatableObject<EdgeEntity>);

export default class CoreEdge extends Base {
  type: EntityType.EDGE = EntityType.EDGE;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.edgeContext}`;
  }

  get filledEntity(): EdgeEntity {
    return fillDefaultRoomEdgeFields(this.context, this.entity);
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }
  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    const entity = cloneNaive(this.entity);

    entity.uid = this.getCalculationUid(context);
    (entity as any).endpointUid = [
      (
        this.globalStore.get(
          entity.endpointUid[0],
        ) as CoreConnectableObjectConcrete
      ).getCalculationNode(context, this.uid).uid,

      (
        this.globalStore.get(
          entity.endpointUid[1],
        ) as CoreConnectableObjectConcrete
      ).getCalculationNode(context, this.uid).uid,
    ];
    return [entity];
  }
  collectCalculations(context: CoreContext): CalculationConcrete {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(context: CoreContext): EdgeLiveCalculation {
    return context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  getCoreNeighbours(): CoreObjectConcrete[] {
    return this.entity.endpointUid
      .map((uid) => this.globalStore.get(uid)!)
      .concat(
        this.globalStore
          .getFensByRoomEdge(this.uid)
          .map((uid) => this.globalStore.get(uid)),
        this.globalStore
          .getWallsByRoomEdge(this.uid)
          .map((uid) => this.globalStore.get(uid)),
      );
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    return null;
  }

  vectorFrom(vertexUid: string): Flatten.Vector {
    if (this.entity.endpointUid[0] === vertexUid) {
      return this.vector;
    } else {
      return this.vector.multiply(-1);
    }
  }

  get lengthM(): number {
    return this.vector.length / 1000;
  }

  // If this belongs to a room, this points inwards
  get normal() {
    const rooms = this.context.globalStore.getPolygonsByEdge(this.uid);
    if (rooms.length === 0) {
      return this.vector.rotate90CCW();
    }

    const room = this.context.globalStore.get<CoreRoom>(rooms[0]);

    const direction = externalSegmentDetermineDirectionCW(
      this.context,
      this.worldEndpoints() as [Coord3D, Coord3D],
      room.entity.edgeUid,
    );

    const vec = Flatten.vector(
      direction[1].x - direction[0].x,
      direction[1].y - direction[0].y,
    );
    if (vec.length === 0) {
      console.error("Zero length vector", {
        direction,
        edge: this,
        edgeUid: this.uid,
        room,
      });
    }
    return vec.rotate90CCW().normalize();
  }
}
