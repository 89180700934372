import { Color } from "../../../lib/color";
import {
  chooseByUnitsMetric,
  convertMeasurementSystemNonNull,
  convertMeasurementToMetric,
  Units,
  UnitsContext,
} from "../../../lib/measurements";
import { assertUnreachable, cloneSimple } from "../../../lib/utils";
import { NodeProps } from "../../../models/CustomEntity";
import { CoreContext } from "../../calculations/types";
import { findFireNodeName, findFireSubGroup } from "../../calculations/utils";
import {
  HCAAFixtureDisplayNames,
  HCAAFixtureType,
} from "../../catalog/psd-standard/hcaaEquation";
import {
  GRILLE_FLOW_RATE_TYPES,
  GRILLE_FLOW_RATE_TYPES_HINT,
  isDrainage,
  isGas,
  isGermanStandard,
  isLUStandard,
  isStormwater,
  isVentilation,
  SupportedPsdStandards,
} from "../../config";
import { determineConnectableSystemUid } from "../../coreObjects/utils";
import { I18N } from "../../locale/values";
import { getTooltip, getTooltipsEntityName } from "../../tooltips/tooltips";
import { DrawingState } from "../drawing";
import { getFlowSystem } from "../utils";
import {
  getCatalogFixtureDrainageUnits,
  getFixtureContinuousFlow,
  getFixtureDesignFlowRate,
  getFixtureLoadingUnits,
} from "./fixtures/fixture-entity";
import {
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "./property-field";
import { CenteredEntity, DrawableEntity } from "./simple-entities";
import { EntityType } from "./types";

export const MAX_DWELLINGS = 100;
export const DEFAULT_GRILL_ZETA = 6;
export const DEFAULT_GRILL_PRESSURE_DROP_PA = 10;
export const DEFAULT_GRILL_SLOT_WIDTH_MM = 600;
export const DEFAULT_GRILL_SLOT_HEIGHT_MM = 150;
export const DEFAULT_GRILL_SQUARE_WIDTH_HORZ_MM = 300;
export const DEFAULT_GRILL_SQUARE_HEIGHT_HORZ_MM = 300;
export const DEFAULT_GRILL_SQUARE_WIDTH_VERT_MM = 300;
export const DEFAULT_GRILL_SQUARE_HEIGHT_VERT_MM = 150;
export const DEFAULT_GRILL_DIAMETER_MM = 300;
export const DEFAULT_GRILL_HEIGHT_ABOVE_FLOOR_M = 2.2;

export enum NodeType {
  LOAD_NODE,
  DWELLING,
  FIRE,
  VENTILATION,
}

export enum NodeVariant {
  FIXTURE_GROUP = "FIXTURE-GROUP",
  FIXTURE = "FIXTURE",
  CONTINUOUS = "CONTINUOUS",
  GRILL = "GRILL",
}

export interface LoadNode {
  type: NodeType.LOAD_NODE;
  loadingUnits: number | null;
  designFlowRateLS: number | null;
  continuousFlowLS: number | null;
  gasFlowRateMJH: number;
  gasPressureKPA: number | null;
  variant:
    | NodeVariant.FIXTURE_GROUP
    | NodeVariant.FIXTURE
    | NodeVariant.CONTINUOUS;

  drainageFixtureUnits: number | null;

  hcaaFixtureType: HCAAFixtureType | null;
  diversity: number | null;
}

export interface FireNode {
  type: NodeType.FIRE;
  customEntityId: string;
  subGroupId: string;
  variant: NodeVariant.CONTINUOUS;
  kvValue: number | null;
}

export interface DwellingNode {
  type: NodeType.DWELLING;
  dwellings: number;
  continuousFlowLS: number | null;
  gasFlowRateMJH: number;
  gasPressureKPA: number | null;
  loadingUnits: number | null;
  designFlowRateLS: number | null;
  drainageFixtureUnits: number | null;
}
export interface VentilationNode {
  type: NodeType.VENTILATION;
  variant: NodeVariant.GRILL;
  heightAboveFloorM: number | null;
  flowDirection: "in" | "out";
  orientation: "horizontal" | "vertical";
  shape: "square" | "circ" | "slot";
  widthMM: number | null;
  heightMM: number | null;
  diameterMM: number | null;

  flowRateType:
    | "constant"
    | "percentage"
    | "variable"
    | "room"
    | "room-size"
    | null;
  continuousFlowLS: number;
  percentageFlow: number;
  variableFlowShares: number;
  pressureDropMethod: "zeta" | "pressure drop";
  zeta: number;
  pressureDropPA: number;
}

export default interface LoadNodeEntity extends DrawableEntity, CenteredEntity {
  type: EntityType.LOAD_NODE;
  systemUidOption: string | null;
  color: Color | null;
  calculationHeightM: number | null;

  node: LoadNode | DwellingNode | FireNode | VentilationNode;
  minPressureKPA: number | null;
  maxPressureKPA: number | null;

  linkedToUid: string | null;
  customNodeId?: number | string;
  name?: string | null;
  loadingUnitVariant?: string | null;
}

export function makeLoadNodesFields(
  context: CoreContext,
  entity: LoadNodeEntity,
): PropertyField[] {
  const { drawing, catalog, locale, globalStore } = context;
  const systemUid = determineConnectableSystemUid(globalStore, entity);
  const tooltipsEntityName = getTooltipsEntityName(entity, drawing);
  const fields: PropertyField[] = [];

  const psdStrategy = drawing
    ? drawing.metadata.calculationParams.psdMethod
    : SupportedPsdStandards.as35002021LoadingUnits;

  if (
    psdStrategy === SupportedPsdStandards.cibseGuideG &&
    (entity.node.type !== NodeType.LOAD_NODE ||
      entity.node.variant !== NodeVariant.FIXTURE) &&
    entity.node.type !== NodeType.VENTILATION
  ) {
    fields.push({
      property: "loadingUnitVariant",
      title: "Loading Unit Variant",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Low", key: "low" },
          { name: "Medium", key: "medium" },
          { name: "High", key: "high" },
        ],
      },
      multiFieldId: null,
    });
  }
  if (!systemUid || !isStormwater(drawing.metadata.flowSystems[systemUid])) {
    fields.push(
      {
        property: "systemUidOption",
        title: "Flow System",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.FlowSystemChoice,
        params: {
          systems: drawing.metadata.flowSystemUidsInOrder
            .map((uid) => drawing.metadata.flowSystems[uid])
            .filter((fs) => !isStormwater(fs)),
        },
        multiFieldId: "systemUid",
      },
      {
        property: "color",
        title: "Color",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Color,
        params: null,
        multiFieldId: "color",
      },
    );
  }

  const nameField: PropertyField = {
    property: "name",
    title: "Name",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Text,
    params: null,
    multiFieldId: "name",
  };

  const nodeIsGas = isGas(drawing.metadata.flowSystems[systemUid!]);

  const nodeIsStormwater = isStormwater(
    drawing.metadata.flowSystems[systemUid!],
  );

  const nodeIsDrainage = isDrainage(drawing.metadata.flowSystems[systemUid!]);
  const nodeIsVentilation = isVentilation(
    drawing.metadata.flowSystems[systemUid!],
  );
  const drainageMethod = drawing.metadata.calculationParams.drainageMethod;
  const continuousFlowTooltip = "Continuous flow is for water only";

  switch (entity.node.type) {
    case NodeType.LOAD_NODE:
      if (!nodeIsGas) {
        switch (entity.node.variant) {
          case undefined:
          case null:
          case NodeVariant.FIXTURE:
            fields.unshift(nameField);
            const psdStrategy = drawing
              ? drawing.metadata.calculationParams.psdMethod
              : SupportedPsdStandards.as35002021LoadingUnits;

            fields.push(
              {
                property: "node.loadingUnits",
                title: I18N.loadingUnits[locale],
                hasDefault: typeof entity.customNodeId !== "undefined",
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "loadingUnits",
                hideFromPropertyWindow:
                  !isLUStandard(psdStrategy) || nodeIsDrainage,
              },
              {
                property: "node.designFlowRateLS",
                title: "Full Flow Rate",
                hasDefault: typeof entity.customNodeId !== "undefined",
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "designFlowRateLS",
                units: Units.LitersPerSecond,
                hideFromPropertyWindow: isLUStandard(psdStrategy),
              },
              {
                property: "node.drainageFixtureUnits",
                title: "Drainage Fixture Unit",
                hasDefault: false,
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "drainageFixtureUnits",
                units: Units.None,
              },
            );

            if (psdStrategy === SupportedPsdStandards.hcaa) {
              fields.push({
                property: "node.hcaaFixtureType",
                title: "HCAA Fixture Type",
                hasDefault: false,
                isCalculated: false,
                requiresInput: true,
                type: FieldType.Choice,
                params: {
                  choices: Object.entries(HCAAFixtureDisplayNames).map(
                    ([key, name]) => ({
                      name,
                      key,
                    }),
                  ),
                },
                multiFieldId: "hcaaFixtureType",
              });
            }
            break;
          case NodeVariant.CONTINUOUS:
            fields.unshift(nameField);

            fields.push(
              {
                property: "node.continuousFlowLS",
                title: "Continuous Flow",
                hasDefault: typeof entity.customNodeId !== "undefined",
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "continuousFlowLS",
                units: Units.LitersPerSecond,
                hint: continuousFlowTooltip,
              },
              {
                property: "node.drainageFixtureUnits",
                title: "Drainage Fixture Unit",
                hasDefault: false,
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "drainageFixtureUnits",
                units: Units.None,
              },
            );
            break;
          case NodeVariant.FIXTURE_GROUP:
            fields.push(
              {
                property: "node.loadingUnits",
                title: I18N.loadingUnits[locale],
                hasDefault: typeof entity.customNodeId !== "undefined",
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "loadingUnits",
                hideFromPropertyWindow: nodeIsDrainage,
              },
              {
                property: "node.designFlowRateLS",
                title: "Full Flow Rate",
                hasDefault: typeof entity.customNodeId !== "undefined",
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "designFlowRateLS",
                units: Units.LitersPerSecond,
              },
              {
                property: "node.continuousFlowLS",
                title: "Continuous Flow",
                hasDefault: typeof entity.customNodeId !== "undefined",
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "continuousFlowLS",
                units: Units.LitersPerSecond,
                hint: continuousFlowTooltip,
              },
              {
                property: "node.drainageFixtureUnits",
                title: "Drainage Fixture Unit",
                hasDefault: false,
                isCalculated: false,
                type: FieldType.Number,
                params: { min: 0, max: null },
                multiFieldId: "drainageFixtureUnits",
                units: Units.None,
              },
            );
            break;
          default:
            // Do not assert unreachable.
            assertUnreachable(entity.node);
        }
      }
      break;
    case NodeType.DWELLING:
      fields.push(
        {
          property: "node.dwellings",
          title: "Dwelling Units",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: MAX_DWELLINGS, noslider: true },
          multiFieldId: "dwellings",
        },
        {
          property: "node.loadingUnits",
          title: I18N.loadingUnits[locale],
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: "loadingUnits",
        },
        {
          property: "node.designFlowRateLS",
          title: "Full Flow Rate",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: "designFlowRateLS",
          units: Units.LitersPerSecond,
        },
      );

      if (!nodeIsGas) {
        fields.push(
          {
            property: "node.continuousFlowLS",
            title: "Continuous Flow",
            hasDefault: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: "continuousFlowLS",
            units: Units.LitersPerSecond,
            hint: continuousFlowTooltip,
          },
          {
            property: "node.drainageFixtureUnits",
            title: "Drainage Fixture Unit",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: "drainageFixtureUnits",
            units: Units.None,
          },
        );
      }
      break;
    case NodeType.FIRE:
      let fireNode = entity.node;
      fields.push(
        {
          property: "node.kvValue",
          title: "Kv Value",
          hasDefault: true,
          highlightOnOverride: Color.YELLOW,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: "kvValue",
          units: Units.Kv,
        },
        {
          property: "node.continuousFlowLS",
          title: "Continuous Flow",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.SettingNumberFieldBind,
          settingPropertyParam: {
            entity: fireNode,
            fetchValue: () => {
              let fireGroup = findFireSubGroup(
                drawing,
                fireNode.customEntityId,
                fireNode.subGroupId,
              );
              console.log("fireGroup", fireGroup);
              return fireGroup.continuousFlowRateLS;
            },
            confirmMessage: (value: any) => {
              return {
                title: `Warning: Change Continuous FlowRate`,
                message: `The change for group ${findFireNodeName(
                  drawing,
                  fireNode.customEntityId,
                  fireNode.subGroupId,
                )}'s continuous flowRate operating will be applied to all nodes in the sub group. Are you sure you want to change the value to ${value}?`,
              };
            },
            validateValue: (value: any) => {
              if (Number(value) < 0 || !Number.isInteger(Number(value))) {
                return false;
              }
              return true;
            },
            updateValue: (value: number) => {
              let fireGroup = findFireSubGroup(
                drawing,
                fireNode.customEntityId,
                fireNode.subGroupId,
              );

              if (fireGroup !== undefined) {
                // Convert to metric unit
                fireGroup.continuousFlowRateLS = convertMeasurementToMetric(
                  convertMeasurementSystemNonNull(
                    drawing.metadata.units,
                    Units.LitersPerSecond,
                    value,
                    UnitsContext.NONE,
                  )[0],
                  value,
                  UnitsContext.NONE,
                )[1];
              } else {
                return;
              }
            },
            min: 0,
            max: Infinity,
          },
          multiFieldId: "continuousFlowLS",
          units: Units.LitersPerSecond,
          hint: continuousFlowTooltip,
        },
        {
          property: "",
          multiFieldId: "maximumContinuousNodes",
          title: "Maximum Nodes Operating Simultaneously",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.SettingNumberFieldBind,
          settingPropertyParam: {
            entity: fireNode,
            fetchValue: () => {
              let fireGroup = findFireSubGroup(
                drawing,
                fireNode.customEntityId,
                fireNode.subGroupId,
              );
              return fireGroup.maxiumumSimutaneousNode;
            },
            confirmMessage: (value: any) => {
              return {
                title: `Warning: Change Maximum Simutaneous Operating Nodes`,
                message: `The change for group ${findFireNodeName(
                  drawing,
                  fireNode.customEntityId,
                  fireNode.subGroupId,
                )}'s maximum simutaneous operating will be applied to all nodes in the sub group. Are you sure you want to change the value to ${value}?`,
              };
            },
            validateValue: (value: any) => {
              if (Number(value) < 0 || !Number.isInteger(Number(value))) {
                return false;
              }
              return true;
            },
            updateValue: (value: number) => {
              let fireGroup = findFireSubGroup(
                drawing,
                fireNode.customEntityId,
                fireNode.subGroupId,
              );

              if (fireGroup !== undefined) {
                fireGroup.maxiumumSimutaneousNode = value;
              } else {
                return;
              }
            },
            min: 0,
            max: Infinity,
          },
        },
      );
      break;
    case NodeType.VENTILATION:
      fields.push({
        property: "node.flowRateType",
        title: "Flow Rate Mode",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: GRILLE_FLOW_RATE_TYPES,
        },
        hint: GRILLE_FLOW_RATE_TYPES_HINT,
        multiFieldId: "flowRateType",
      });

      switch (entity.node.flowRateType) {
        case "constant": {
          fields.push({
            property: "node.continuousFlowLS",
            title: "Air Flow Rate",
            hasDefault: false,
            isCalculated: true,
            type: FieldType.Number,
            params: { min: 0, max: null, initialValue: 0 },
            multiFieldId: "continuousFlowLS",
            unitContext: UnitsContext.VENTILATION,
            units: Units.LitersPerSecond,
          });
          break;
        }
        case "percentage": {
          fields.push({
            property: "node.percentageFlow",
            title: "Percentage of Load",
            hasDefault: false,
            isCalculated: true,
            hint: "The percentage of the zone's total load that will be assigned to this grille's flow rate.",
            type: FieldType.Number,
            params: { min: 0, max: 100, initialValue: 0 },
            multiFieldId: "percentageFlow",
            units: Units.Percent,
          });
          break;
        }
        case "variable": {
          fields.push({
            property: "node.variableFlowShares",
            title: "Share Units",
            hasDefault: false,
            isCalculated: false,
            hint: "The zone's remaining load (after Manual and Percentage grille loads are subtracted) will be divided proportionally among the share units in the zone.",
            type: FieldType.Number,
            params: { min: 0, max: null, initialValue: 0 },
            multiFieldId: "variableFlowShares",
            units: Units.None,
          });
          break;
        }
        case "room":
        case "room-size":
        case null:
          break;
        default:
          assertUnreachable(entity.node.flowRateType);
      }

      fields.push(
        {
          property: "node.orientation",
          title: "Orientation",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: [
              { name: "Horizontal", key: "horizontal" },
              { name: "Vertical", key: "vertical" },
            ],
          },
          multiFieldId: "orientation",
        },
        {
          property: "node.shape",
          title: "Shape",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: [
              { name: getFriendlyShapeName("slot"), key: "slot" },
              { name: getFriendlyShapeName("square"), key: "square" },
              { name: getFriendlyShapeName("circ"), key: "circ" },
            ],
          },
          multiFieldId: "shape",
        },
      );

      if (entity.node.shape === "slot" || entity.node.shape === "square") {
        fields.push(
          {
            property: "node.heightMM",
            title: "Height",
            hasDefault: true,
            isCalculated: false,
            type: FieldType.Number,
            units: Units.Millimeters,
            params: {
              min: 0,
              max: null,
            },
            multiFieldId: "heightMM",
          },
          {
            property: "node.widthMM",
            title: "Width",
            hasDefault: true,
            isCalculated: false,
            type: FieldType.Number,
            units: Units.Millimeters,
            params: {
              min: 0,
              max: null,
            },
            multiFieldId: "widthMM",
          },
        );
      }

      if (entity.node.shape === "circ") {
        fields.push({
          property: "node.diameterMM",
          title: "Diameter",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          units: Units.Millimeters,
          params: {
            min: 0,
            max: null,
          },
          multiFieldId: "diameterMM",
        });
      }

      fields.push(
        {
          property: "node.heightAboveFloorM",
          title: "Height Above Floor",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: null, max: null },
          multiFieldId: "heightAboveFloorM",
          units: Units.Meters,
        },
        {
          property: "node.pressureDropMethod",
          title: "Pressure Drop Method",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: [
              { name: "Zeta", key: "zeta" },
              { name: "Fixed", key: "pressure drop" },
            ],
          },
          multiFieldId: "pressureDropMethod",
        },
      );

      switch (entity.node.pressureDropMethod) {
        case "zeta":
          fields.push({
            property: "node.zeta",
            title: "Friction Loss Zeta",
            hasDefault: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: "zeta",
            units: Units.None,
            requiresInput: true,
          });
          break;
        case "pressure drop":
          fields.push({
            property: "node.pressureDropPA",
            title: "Directly Pressure Drop",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Number,
            multiFieldId: "pressureDropPA",
            unitContext: UnitsContext.VENTILATION,
            units: Units.Pascals,
            params: { min: null, max: null },
            requiresInput: true,
          });
          break;
        default:
          assertUnreachable(entity.node.pressureDropMethod);
      }
      break;
    default:
      assertUnreachable(entity.node);
  }

  if (!nodeIsGas && !nodeIsStormwater && !nodeIsVentilation) {
    fields.push(
      {
        property: "minPressureKPA",
        title: "Min. Pressure",
        hasDefault: true,
        highlightOnOverride: Color.YELLOW,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "minPressureKPA",
        units: Units.KiloPascals,
      },
      {
        property: "maxPressureKPA",
        title: "Max. Pressure",
        hasDefault: true,
        highlightOnOverride: Color.YELLOW,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "maxPressureKPA",
        units: Units.KiloPascals,
      },
    );
  }

  if (nodeIsGas) {
    if (entity.node.type === NodeType.DWELLING) {
      fields.push({
        property: "node.gasFlowRateMJH",
        title: "Gas Demand (Per Dwelling)",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Number,
        units: Units.MegajoulesPerHour,
        params: { min: 0, max: null },
        multiFieldId: "gasFlowRateMJH",
        unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
      });
    } else {
      fields.push(
        {
          property: "node.gasFlowRateMJH",
          title: "Gas Demand",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Number,
          units: Units.MegajoulesPerHour,
          params: { min: 0, max: null },
          multiFieldId: "gasFlowRateMJH",
          unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
        },
        {
          property: "node.diversity",
          title: "Diversity",
          hint: getTooltip(tooltipsEntityName, "Diversity"),
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: "diversity",
          units: Units.Percent,
        },
      );
    }

    fields.push({
      property: "node.gasPressureKPA",
      hint: "The default value may have been determined by an upstream gas pressure regulator.",
      title: "Gas Pressure",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      units: Units.GasKiloPascals,
      params: { min: 0, max: null },
      multiFieldId: "gasPressureKPA",
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    });
  }

  return fields.map(withPropertyTracking(context, entity));
}

export function drawingContainsCustomNode(
  drawing: DrawingState,
  customNodeId: number,
): boolean {
  let found = false;
  for (const level of Object.values(drawing.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (
        entity.type === EntityType.LOAD_NODE &&
        entity.customNodeId === customNodeId
      ) {
        found = true;
        break;
      }
    }
    if (found) break;
  }
  return found;
}

// These are calculated values required by the load node.
// TODO: SEED-167 make the changes persist across sessions and users
// TODO: SEED-112 These calculation results should be available live-updated and processed in the background
export interface LoadNodeDrawingModeCalculations {
  gasPressureKPA: number | null;
}

export function fillDefaultLoadNodeFields(
  context: CoreContext,
  entity: LoadNodeEntity,
) {
  const { globalStore, drawing, catalog, nodes } = context;
  const calculations = globalStore.getCalculation(entity);
  const result = cloneSimple(entity);

  const systemUid = determineConnectableSystemUid(globalStore, entity);
  const system = getFlowSystem(drawing, systemUid);

  if (
    result.loadingUnitVariant === undefined ||
    result.loadingUnitVariant === null
  ) {
    result.loadingUnitVariant =
      drawing.metadata.calculationParams.loadingUnitVariant;
  }

  result.systemUidOption = system ? system.uid : null;

  if (system) {
    if (result.color == null) {
      result.color = system.color;
    }
  } else {
    if (result.color == null) {
      result.color = { hex: "#888888" };
    }
  }

  if (typeof result.customNodeId !== "undefined") {
    const node = nodes.find(
      (node: NodeProps) =>
        node.id === result.customNodeId ||
        node.uid === result.customNodeId ||
        // We need to check dbid because legacy nodes are missing the id/uid attribute.
        node.dbid === result.customNodeId,
    )!;

    if (!node) {
      console.warn(`custom node ${result.customNodeId} missing`);
      return result;
    }

    result.name = node.name;
    result.minPressureKPA =
      (result.minPressureKPA === null &&
        (node.minPressure ? Number(node.minPressure) : null)) ||
      result.minPressureKPA;
    result.maxPressureKPA =
      (result.maxPressureKPA === null &&
        (node.maxPressure ? Number(node.maxPressure) : null)) ||
      result.maxPressureKPA;

    const psdStrategy = drawing
      ? drawing.metadata.calculationParams.psdMethod
      : SupportedPsdStandards.as35002021LoadingUnits;

    let loadingUnits = 0;
    let designFlowRateLS = 0;
    let continuousFlow = 0;
    let drainageFixtureUnits = 0;

    let dwellingMultiplier = 1;
    if (result.node.type === NodeType.DWELLING) {
      dwellingMultiplier = result.node.dwellings;
    }

    // Ensure Node is not FIRE node
    // Inside is logic for loading unit, which can be drainage or dwelling
    if (
      result.node.type === NodeType.LOAD_NODE ||
      result.node.type === NodeType.DWELLING
    ) {
      if (
        !result.node.loadingUnits ||
        !result.node.designFlowRateLS ||
        !result.node.continuousFlowLS
      ) {
        for (var i = 0; i < node.fixtures.length; i++) {
          if (
            result.node.loadingUnits === null &&
            isLUStandard(psdStrategy) &&
            result.systemUidOption
          ) {
            const loadingUnitsResult = getFixtureLoadingUnits({
              fixtureName: node.fixtures[i],
              systemUid: result.systemUidOption,
              fallbackSystemUid:
                result.systemUidOption === "hot-water"
                  ? "warm-water"
                  : undefined,
              psdStrategy,
              loadingUnitVariant: result.loadingUnitVariant!,
              catalog,
            });

            if (loadingUnitsResult) {
              loadingUnits += loadingUnitsResult * dwellingMultiplier;
            }
          }

          if (result.node.designFlowRateLS === null && result.systemUidOption) {
            designFlowRateLS +=
              dwellingMultiplier *
              (getFixtureDesignFlowRate({
                fixtureName: node.fixtures[i],
                systemUid: result.systemUidOption,
                fallbackSystemUid:
                  result.systemUidOption === "hot-water"
                    ? "warm-water"
                    : undefined,
                drawing: drawing,
                catalog,
              }) || 0);
          }

          if (result.node.continuousFlowLS === null && result.systemUidOption) {
            continuousFlow +=
              dwellingMultiplier *
              (getFixtureContinuousFlow({
                fixtureName: node.fixtures[i],
                catalog,
                systemUid: result.systemUidOption,
                fallbackSystemUid:
                  result.systemUidOption === "hot-water"
                    ? "warm-water"
                    : undefined,
              }) || 0);
          }

          // drainage fixture units
          const catalogDFU =
            getCatalogFixtureDrainageUnits({
              fixtureName: node.fixtures[i],
              catalog,
              drawing,
            }) ?? 0;

          drainageFixtureUnits += dwellingMultiplier * catalogDFU;
        }

        if (result.node.loadingUnits === null) {
          result.node.loadingUnits = loadingUnits;
        }
        if (result.node.designFlowRateLS === null) {
          result.node.designFlowRateLS = designFlowRateLS;
        }
        if (result.node.continuousFlowLS === null) {
          result.node.continuousFlowLS = continuousFlow;
        }
        if (result.node.drainageFixtureUnits === null) {
          result.node.drainageFixtureUnits = drainageFixtureUnits;
        }
      }
    }
  }

  if (result.node.type !== NodeType.FIRE) {
    if (result.minPressureKPA === null) {
      result.minPressureKPA = chooseByUnitsMetric(
        context.drawing.metadata.units,
        {
          [Units.KiloPascals]: 200,
          [Units.GasKiloPascals]: 200,
          [Units.Pascals]: 200000,
          [Units.Psi]: 30,
          [Units.Bar]: 2,
          [Units.Mbar]: 2000,
        },
        UnitsContext.NONE,
      )[1];
    }
    if (result.maxPressureKPA === null) {
      result.maxPressureKPA = chooseByUnitsMetric(
        context.drawing.metadata.units,
        {
          [Units.KiloPascals]: 500,
          [Units.GasKiloPascals]: 500,
          [Units.Pascals]: 500000,
          [Units.Psi]: 75,
          [Units.Bar]: 5,
          [Units.Mbar]: 5000,
        },
        UnitsContext.NONE,
      )[1];
    }
    if (
      result.node.type !== NodeType.VENTILATION &&
      result.node.gasPressureKPA === null
    ) {
      // TODO: this should come from "live computation" layer, instead of this entityDependency
      // object which will be deprecated soon. (Not david's comment, copied from before)
      result.node.gasPressureKPA = calculations?.gasPressureKPA ?? 0;
    }

    if (result.node.type === NodeType.LOAD_NODE) {
      if (result.node.diversity === null) {
        result.node.diversity = 100;
      }
    }
  } else {
    let fireSubGroup = findFireSubGroup(
      context.drawing,
      result.node.customEntityId ?? "",
      result.node.subGroupId,
    );
    if (!result.minPressureKPA) {
      result.minPressureKPA = fireSubGroup?.minPressureKPA
        ? fireSubGroup?.minPressureKPA
        : null;
    }

    if (!result.maxPressureKPA) {
      result.maxPressureKPA = fireSubGroup?.maxPressureKPA
        ? fireSubGroup?.maxPressureKPA
        : null;
    }

    if (!result.node.kvValue) {
      result.node.kvValue = fireSubGroup?.kvValue
        ? fireSubGroup?.kvValue
        : null;
    }
  }

  if (result.node.type === NodeType.VENTILATION) {
    if (result.node.heightAboveFloorM == null) {
      result.node.heightAboveFloorM = DEFAULT_GRILL_HEIGHT_ABOVE_FLOOR_M;
    }

    if (result.node.zeta === null) {
      result.node.zeta = DEFAULT_GRILL_ZETA;
    }

    if (result.node.shape === "slot") {
      if (result.node.widthMM === null) {
        result.node.widthMM = DEFAULT_GRILL_SLOT_WIDTH_MM;
      }
      if (result.node.heightMM === null) {
        result.node.heightMM = DEFAULT_GRILL_SLOT_HEIGHT_MM;
      }
    }

    if (result.node.shape === "square") {
      if (result.node.orientation === "horizontal") {
        if (result.node.widthMM === null) {
          result.node.widthMM = DEFAULT_GRILL_SQUARE_WIDTH_HORZ_MM;
        }
        if (result.node.heightMM === null) {
          result.node.heightMM = DEFAULT_GRILL_SQUARE_WIDTH_HORZ_MM;
        }
      }

      if (result.node.orientation === "vertical") {
        if (result.node.widthMM === null) {
          result.node.widthMM = DEFAULT_GRILL_SQUARE_WIDTH_VERT_MM;
        }
        if (result.node.heightMM === null) {
          result.node.heightMM = DEFAULT_GRILL_SQUARE_HEIGHT_VERT_MM;
        }
      }
    }

    if (result.node.shape === "circ") {
      if (result.node.diameterMM === null) {
        result.node.diameterMM = DEFAULT_GRILL_DIAMETER_MM;
      }
    }

    if (result.node.flowRateType === null) {
      result.node.flowRateType =
        context.drawing.metadata.heatLoss.grilleFlowRateType ?? "room";
    }
  }
  return result;
}

export function getLoadNodeDrainageUnits(loadNodeEntity: LoadNodeEntity) {
  // By return 0, it won't effect the loading unit
  // to flow rate calculation.
  if (
    loadNodeEntity.node.type === NodeType.FIRE ||
    loadNodeEntity.node.type === NodeType.VENTILATION
  ) {
    return 0;
  }

  return loadNodeEntity.node.drainageFixtureUnits;
}

export function getLoadNodeCalculationUnits(options: {
  entity: LoadNodeEntity;
  psdStrategy: SupportedPsdStandards;
}) {
  const { entity, psdStrategy } = options;
  // By return 0, it won't effect the loading unit
  // to flow rate calculation.
  if (
    entity.node.type === NodeType.FIRE ||
    entity.node.type === NodeType.VENTILATION
  ) {
    return 0;
  }

  if (isGermanStandard(psdStrategy)) {
    return entity.node.designFlowRateLS;
  } else {
    return entity.node.loadingUnits;
  }
}

export function getLoadNodeGasDiversity(options: { entity: LoadNodeEntity }) {
  switch (options.entity.node.type) {
    case NodeType.LOAD_NODE:
      return options.entity.node.diversity;
    case NodeType.DWELLING:
      return 100;
    case NodeType.FIRE:
    case NodeType.VENTILATION:
      // TODO: SEED558, Here need some second thought, in future
      // consider disable user to do so by apply role to flow System
      return 0;
  }
  assertUnreachable(options.entity.node);
}

export function getFriendlyShapeName(shape: VentilationNode["shape"]) {
  switch (shape) {
    case "circ":
      return "Circular";
    case "slot":
      return "Slot";
    case "square":
      return "Square";
  }
  assertUnreachable(shape);
}
