import {
  isArchitectureElementEntity,
  isCenteredEntity,
  isConnectableEntity,
  isEdgeEntity,
  isPolygonEntity,
  isVirtualEdgeEntity,
} from "../document/entities/concrete-entity";
import CoreAnnotation from "./coreAnnotation";
import CoreArchitectureElement from "./coreArchitectureElement";
import CoreAreaSegment from "./coreAreaSegment";
import CoreBackgroundImage from "./coreBackgroundImage";
import CoreBigValve from "./coreBigValve";
import CoreCompound from "./coreCompound";
import CoreConduit from "./coreConduit";
import CoreDamper from "./coreDamper";
import CoreDirectedValve from "./coreDirectedValve";
import CoreEdge from "./coreEdge";
import CoreFen from "./coreFenestration";
import CoreFitting from "./coreFitting";
import CoreFixture from "./coreFixture";
import CoreFlowSource from "./coreFlowSource";
import CoreGasAppliance from "./coreGasAppliance";
import CoreLine from "./coreLine";
import CoreLoadNode from "./coreLoadNode";
import CoreMultiwayValve from "./coreMultiwayValve";
import CorePlant from "./corePlant";
import CoreRiser from "./coreRiser";
import CoreRoom from "./coreRoom";
import CoreSystemNode from "./coreSystemNode";
import CoreVertex from "./coreVertex";
import CoreWall from "./coreWall";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";

export type CoreObjectConcrete =
  | CoreBackgroundImage
  | CoreFitting
  | CoreConduit
  | CoreRiser
  | CoreSystemNode
  | CoreBigValve
  | CoreFixture
  | CoreDirectedValve
  | CoreLoadNode
  | CoreFlowSource
  | CorePlant
  | CoreGasAppliance
  | CoreCompound
  | CoreMultiwayValve
  | CoreEdge
  | CoreWall
  | CoreVertex
  | CoreRoom
  | CoreFen
  | CoreLine
  | CoreAnnotation
  | CoreArchitectureElement
  | CoreDamper
  | CoreAreaSegment;

export function isCoreConnectableObject(
  obj: CoreObjectConcrete,
): obj is CoreObjectConcrete {
  return isConnectableEntity(obj.entity);
}

export type CoreConnectableObjectConcrete =
  | CoreFitting
  | CoreRiser
  | CoreSystemNode
  | CoreDirectedValve
  | CoreLoadNode
  | CoreFlowSource
  | CoreMultiwayValve
  | CoreVertex;

export type CoreCenteredObjectConcrete =
  | CoreFitting
  | CoreRiser
  | CoreSystemNode
  | CoreBigValve
  | CoreFixture
  | CoreDirectedValve
  | CoreLoadNode
  | CoreFlowSource
  | CorePlant
  | CoreGasAppliance
  | CoreCompound
  | CoreMultiwayValve
  | CoreAreaSegment;

export type CoreEdgeObjectConcrete = CoreConduit | CoreEdge;
export type CoreVirtualEdgeObjectConcrete = CoreWall | CoreFen;

export type CorePolygonObjectConcrete = CoreRoom | CoreAreaSegment;

export function isCoreEdgeObject(
  obj: CoreBaseBackedObject,
): obj is CoreEdgeObjectConcrete {
  return isEdgeEntity(obj.entity);
}

export function isCenteredObject(
  obj: CoreBaseBackedObject,
): obj is CoreCenteredObjectConcrete {
  return isCenteredEntity(obj.entity);
}

export function isPolygonObject(
  obj: CoreBaseBackedObject,
): obj is CorePolygonObjectConcrete {
  return isPolygonEntity(obj.entity);
}

export function isVirtualEdgeObject(
  obj: CoreBaseBackedObject,
): obj is CoreVirtualEdgeObjectConcrete {
  return isVirtualEdgeEntity(obj.entity);
}

export function isArchitectureElement(
  obj: CoreBaseBackedObject,
): obj is CoreArchitectureElement {
  return isArchitectureElementEntity(obj.entity);
}

export type CoreCalculatedObjectConcrete =
  | CoreFitting
  | CoreConduit
  | CoreRiser
  | CoreSystemNode
  | CoreBigValve
  | CoreFixture
  | CoreDirectedValve
  | CoreLoadNode
  | CoreFlowSource
  | CorePlant
  | CoreGasAppliance
  | CoreCompound
  | CoreMultiwayValve
  | CoreEdge
  | CoreVertex
  | CoreRoom
  | CoreWall
  | CoreFen
  | CoreArchitectureElement
  | CoreDamper
  | CoreAreaSegment;
