import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { GuessEntity } from "../../../../../common/src/api/coreObjects/lib/types";
import { getEntityAnalyticProperties } from "../../../../../common/src/api/document/analytics/utils";
import { isCalculated } from "../../../../../common/src/api/document/calculations-objects";
import { DrawableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { Color } from "../../../../../common/src/lib/color";
import { Coord } from "../../../../../common/src/lib/coord";
import { trackEvent } from "../../../api/mixpanel";
import { getUiStateAnalyticProperties } from "../../../lib/analytic-utils";
import { getPropertyByString } from "../../../lib/utils";
import { DocumentState } from "../../../store/document/types";
import { shouldShowOnFloorAbove } from "../../layers/util";
import { DrawableObjectConcrete } from "../../objects/concrete-object";
import CanvasContext from "../canvas-context";
import {
  DrawingArgs,
  EntityDrawingArgs,
  IDrawable,
  MakeDrawableObject,
} from "../drawable-object";
import DrawableStore from "../drawableStore";
import { Interaction } from "../interaction";
import { DrawingContext, ValidationResult } from "../types";
import { isHydraulicEntity } from "./util";

/**
 * Persists for the lifetime of the application to prevent spamming Sentry/Mixpanel every time the canvas renders
 */
const BROKEN_UIDS: Set<string> = new Set();

export interface IDrawableObject extends IDrawable {
  document: DocumentState;

  drawableStore: DrawableStore;
  onSelect: (event: MouseEvent | KeyboardEvent) => void;
  onInteractionComplete: (event: MouseEvent | KeyboardEvent) => void;

  drawInternal(context: DrawingContext, args: DrawingArgs): void;
  isActive(): boolean;
  validate(context: CanvasContext, tryToFix: boolean): ValidationResult;
  getCopiedObjects(): DrawableObjectConcrete[];
  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void;
  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null;
  prepareDelete(
    context: CanvasContext,
    calleeEntityUid?: string,
  ): DrawableObjectConcrete[];
  inBounds(objectCoord: Coord, objectRadius?: number): boolean;
}

// This is needed to get it working for bare core objects. Damn.
export function Wrapper<
  T extends abstract new (...args: any[]) => CoreBaseBackedObject<I>,
  I extends DrawableEntityConcrete = GuessEntity<T>,
>(Base: T) {
  abstract class Generated extends Base {}
  return Generated;
}

export function Core2Drawable<
  // I extends DrawableEntityConcrete
  T extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<DrawableEntityConcrete>,
>(Base: T) {
  const DrawableBase = MakeDrawableObject(Base);

  // We must typecast from union to the generic type for the Base constructor
  // due to typescript's limitation that disallows extending Union types.
  abstract class Generated extends DrawableBase {
    document: DocumentState;
    onSelect: (event: MouseEvent | KeyboardEvent) => void;
    onInteractionComplete: (event: MouseEvent | KeyboardEvent) => void;

    abstract isActive(): boolean;
    get drawableStore(): DrawableStore {
      return this.globalStore;
    }

    lastOverrideColorList: Color[] | null = null;

    getOverrideColorList() {
      if (!this.lastOverrideColorList) {
        this.lastOverrideColorList = [];
        for (const f of this.flatProperties) {
          if (
            (f.hasDefault || this.entity.type === EntityType.CONDUIT) &&
            getPropertyByString(this.entity, f.property, true) != null
          ) {
            if (f.highlightOnOverride) {
              this.lastOverrideColorList.push(f.highlightOnOverride);
            }
          }
        }
      }
      return this.lastOverrideColorList;
    }

    onUpdate(): void {
      super.onUpdate();
      this.lastOverrideColorList = null;
    }

    onRedrawNeeded() {
      super.onRedrawNeeded();
    }

    canDraw(context: DrawingContext, args: DrawingArgs): boolean {
      if (context.doc.isPreview) {
        return this.isActive();
      }
      return !args.forExport;
    }

    drawInternal(context: DrawingContext, args: DrawingArgs): void {
      let overrideColorList: Color[] = [];
      if (
        args.includesHighlight ||
        (!args.forExport && !context.doc.isPreview)
      ) {
        overrideColorList = this.getOverrideColorList();
      }

      if (this.canDraw(context, args)) {
        context.ctx.save();

        const entityDrawingArgs: EntityDrawingArgs = {
          layerName: args.layerName,
          forExport: args.forExport,
          withCalculation: args.withCalculation,
          heatmapMode: args.heatmapMode,
          heatmapSettings: args.heatmapSettings,
          overrideColorList,
          selected: this.calculateSelected(args, context),
          layerActive: args.active,
        };

        try {
          this.applyGlobalAlphaAdjustments(context);
          this.drawEntity(
            {
              catalog: context.catalog,
              ctx: context.ctx,
              doc: context.doc,
              vp: context.vp,
              drawing: context.drawing,
              globalStore: context.globalStore,
              locale: context.locale,
              nodes: context.nodes,
              priceTable: context.priceTable,
              selectedUids: context.selectedUids,
              graphics: {
                unitWorldLength: 1,
                worldToSurfaceScale: context.vp.currToSurfaceScale(context.ctx),
              },
              featureAccess: context.featureAccess,
              featureFlags: context.featureFlags,
            },
            entityDrawingArgs,
          );
        } catch (e: unknown) {
          if (!BROKEN_UIDS.has(this.entity.uid)) {
            BROKEN_UIDS.add(this.entity.uid);
            trackEvent({
              type: "Entity Render Failed",
              props: {
                ...getEntityAnalyticProperties(context, this.entity),
                ...getUiStateAnalyticProperties(),
                forExport: entityDrawingArgs.forExport,
                withCalculation: entityDrawingArgs.withCalculation,
                selected: entityDrawingArgs.withCalculation,
                layerActive: entityDrawingArgs.layerActive,
                layerName: entityDrawingArgs.layerName,
              },
            });
            console.error("Skipping Entity Render", {
              error: e,
              id: this.entity.uid,
              message: (e as Error).message,
              ...getEntityAnalyticProperties(context, this.entity),
              ...entityDrawingArgs,
              ...getUiStateAnalyticProperties(),
            });
          }
        } finally {
          context.ctx.restore();
        }
      }
    }

    private applyGlobalAlphaAdjustments(context: DrawingContext) {
      if (isCalculated(this.entity)) {
        const lCalc = this.globalStore.getLiveCalculation(this.entity);
        if (lCalc && !lCalc.connected && isHydraulicEntity(this.entity)) {
          // semi transparent and greyscale when entity is disconnected
          context.ctx.globalAlpha = 0.5;
          // context.ctx.filter = "grayscale(50%)";
        }

        if (shouldShowOnFloorAbove(this.entity.uid, context, context.doc)) {
          context.ctx.globalAlpha = 0.5;
          // context.ctx.filter = "grayscale(50%)";
        }
      }
    }

    private calculateSelected(args: DrawingArgs, context: DrawingContext) {
      if (args.allSelected !== undefined) {
        return args.allSelected;
      }
      const isSelected = context.doc.uiState.selectedUids.includes(
        this.entity.uid,
      );
      return args.active && isSelected;
    }

    validate(_context: CanvasContext, _tryToFix: boolean): ValidationResult {
      return { success: true };
    }

    getCopiedObjects(): DrawableObjectConcrete[] {
      console.log("calling base getCopiedObjects for ", this.type, this.uid);
      return [this as any as DrawableObjectConcrete, ...this.getNeighbours()];
    }

    getNeighbours(): DrawableObjectConcrete[] {
      return this.getCoreNeighbours() as DrawableObjectConcrete[];
    }

    abstract drawEntity(context: DrawingContext, args: EntityDrawingArgs): void;

    abstract offerInteraction(
      interaction: Interaction,
    ): DrawableEntityConcrete[] | null;

    // Return list of objects to remove.
    abstract prepareDelete(
      context: CanvasContext,
      calleeEntityUid?: string,
    ): DrawableObjectConcrete[];

    abstract inBounds(objectCoord: Coord, objectRadius?: number): boolean;
  }

  return Generated;
}
