import Flatten from "@flatten-js/core";
import { sumBy } from "lodash";
import * as TM from "transformation-matrix";
import { polygonDirectedAreaM2 } from "../../../lib/mathUtils/mathutils";
import { CoreContext } from "../../calculations/types";
import { PolygonEntityConcrete } from "../../document/entities/concrete-entity";
import { EntityType } from "../../document/entities/types";
import CoreEdge from "../coreEdge";
import CoreVertex from "../coreVertex";
import CoreBaseBackedObject from "../lib/coreBaseBackedObject";
import { GuessEntity, SelectionTarget } from "../lib/types";
import { getEdgeContextInPolygon, traversePolygon } from "../utils";

export interface ICorePolygon {
  collectVerticesInOrder(): CoreVertex[];
  collectEdgesInOrder(): CoreEdge[];
  get areaM2(): number;
  get shape(): Flatten.Polygon;
}

export function CorePolygon<
  T extends abstract new (...args: any[]) => CoreBaseBackedObject<I>,
  I extends PolygonEntityConcrete = GuessEntity<T>,
>(Base: T) {
  abstract class Generated extends Base implements ICorePolygon {
    getHash(): string {
      let hashStr = "";
      this.collectVerticesInOrder().forEach((v) => (hashStr += v.getHash()));
      return hashStr;
    }

    get position(): TM.Matrix {
      // We don't draw by object location because the object doesn't really have an own location. Instead, its
      // location is determined by other objects.
      return TM.identity();
    }

    getCalculationUid(context: CoreContext): string {
      return this.uid + ".calculation";
    }

    preCalculationValidation(context: CoreContext): SelectionTarget | null {
      return null;
    }

    collectVerticesInOrder(): CoreVertex[] {
      if (this.entity.edgeUid.length < 2) {
        // This case shouldn't happen, but needs to be considered nevertheless.
        return [];
      }

      const firstEdgeContext = getEdgeContextInPolygon(
        this.context,
        this.entity.edgeUid,
        { edgeIndex: 0 },
      );
      const firstVertex = this.context.globalStore.get<CoreVertex>(
        firstEdgeContext.prevVertexUid,
      );
      const vertices: CoreVertex[] = [];
      traversePolygon({
        context: this.context,
        edgeUidsInOrder: this.entity.edgeUid,
        onVisit: (o) => {
          if (o.type === EntityType.VERTEX) {
            vertices.push(o);
          }
        },
        start: firstVertex,
      });
      return vertices;
    }

    collectVerticesInOrderCCW(): CoreVertex[] {
      const vertices = this.collectVerticesInOrder();
      if (polygonDirectedAreaM2(vertices.map((v) => v.toWorldCoord())) < 0) {
        return vertices.reverse();
      }
      return vertices;
    }

    collectEdgesInOrder(): CoreEdge[] {
      return this.entity.edgeUid.map((edgeUid) => {
        const edge = this.globalStore.get<CoreEdge>(edgeUid);
        if (edge === undefined || edge.type !== EntityType.EDGE) {
          throw new Error("Edge not found");
        }
        return edge;
      });
    }

    collectEdgesInOrderCCW(): CoreEdge[] {
      const edges = this.collectEdgesInOrder();
      if (polygonDirectedAreaM2(edges.map((e) => e.toWorldCoord())) < 0) {
        return edges.reverse();
      }
      return edges;
    }

    get perimeterM(): number {
      let edges = this.collectEdgesInOrder();
      return sumBy(edges, (e) => e.lengthM);
    }

    get areaM2(): number {
      let res = 0;
      let edges = this.collectEdgesInOrder();
      for (let i = 0; i < edges.length; i++) {
        let p1 = this.globalStore
            .get<CoreVertex>(edges[i].entity.endpointUid[0])
            .toWorldCoord(),
          p2 = this.globalStore
            .get<CoreVertex>(edges[i].entity.endpointUid[1])
            .toWorldCoord();
        // cross product
        res += p1.x * p2.y - p1.y * p2.x;
      }
      return Math.abs(res / 2) / 1e6;
    }

    getVisualDeps() {
      return super.getVisualDeps().concat(this.entity.edgeUid);
    }

    get shape(): Flatten.Polygon {
      let polygon: Flatten.Polygon = new Flatten.Polygon();
      polygon.addFace(
        this.collectVerticesInOrder().map(
          (p) => new Flatten.Point(p.toWorldCoord().x, p.toWorldCoord().y),
        ),
      );
      return polygon;
    }
  }
  return Generated;
}
