import { Color } from "../../../lib/color";
import { Units } from "../../../lib/measurements";
import { cloneNaive } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { getEffectiveHeatLoad } from "../../calculations/utils";
import CoreWall from "../../coreObjects/coreWall";
import {
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "./property-field";
import {
  DrawableEntity,
  NamedEntity,
  VirtualEdgeEntity,
} from "./simple-entities";
import { EntityType } from "./types";
import { getAvailableHeatLoadComponentMaterial } from "./utils";

export interface WallEntity
  extends DrawableEntity,
    NamedEntity,
    VirtualEdgeEntity {
  type: EntityType.WALL;

  WallId: string; // TDOO: implement wall templates in the catalog that this will refer to.

  color: Color | null;

  polygonEdgeUid: [string] | [string, string];

  wallMaterialUid: string | null;
  uValueW_M2K: number | null;
  widthMM: number | null;

  externalTemperatureC: number | null;
  wallType: WallType | null;
  neighboringSpaceTemperatureC: number | null;
}

export enum WallType {
  internal = "Internal Wall",
  external = "External Wall",
  party = "Party Wall",
}

export function fillDefaultWallFields(
  context: CoreContext,
  entity: WallEntity,
) {
  let { catalog, drawing } = context;
  let heatloadSpec = drawing.metadata.heatLoss;
  let { defaultMaterial } = heatloadSpec;
  let coreWall = context.globalStore.get<CoreWall>(entity.uid);
  let heatLoad = getEffectiveHeatLoad(context.catalog, context.drawing);

  let result = cloneNaive(entity);

  result.entityName = result.entityName === null ? "Wall" : result.entityName;

  result.wallType =
    result.wallType === null
      ? coreWall.isInternalWall()
        ? WallType.internal
        : WallType.external
      : result.wallType;

  if (
    result.wallMaterialUid !== null &&
    !heatLoad.material[result.wallType].table.hasOwnProperty(
      result.wallMaterialUid,
    )
  ) {
    entity.wallMaterialUid = null;
    result.wallMaterialUid = null;
  }

  result.wallMaterialUid =
    result.wallMaterialUid === null
      ? defaultMaterial[result.wallType]
      : result.wallMaterialUid;

  result.color =
    result.color === null
      ? context.drawing.metadata.heatLoss.defaultColor[result.wallType]
      : result.color;

  result.uValueW_M2K =
    result.uValueW_M2K === null
      ? +heatLoad.material[result.wallType].table[
          result.wallMaterialUid
        ].thermal_transmittance_W_per_m2K.toFixed(2)
      : result.uValueW_M2K;

  result.widthMM =
    result.widthMM === null
      ? coreWall.isInternalWall()
        ? getInternalWallWidth(entity, context)
        : heatloadSpec.wallSpec.externalWidthMM
      : result.widthMM;

  result.externalTemperatureC =
    result.externalTemperatureC === null
      ? heatloadSpec.externalWinterTemperatureC
      : result.externalTemperatureC;

  return result;
}

export function getInternalWallWidth(
  entity: WallEntity,
  context: CoreContext,
): number {
  const heatloadSpec = context.drawing.metadata.heatLoss;
  if (entity.wallType && entity.wallType === WallType.party) {
    return heatloadSpec.wallSpec.partyWidthMM;
  }

  // Return default internal width for external walls
  if (entity.polygonEdgeUid.length !== 2) {
    return heatloadSpec.wallSpec.internalWidthMM;
  }

  // Helper function to get the wall width from an edge UID
  const getMaxWallWidthFromEdge = (edgeUid: string): number | null => {
    const wallList = context.globalStore.getWallsByRoomEdge(edgeUid);
    const wallUid = wallList.find((uid) => uid !== entity.uid);

    if (wallUid) {
      const wall = context.globalStore.get<CoreWall>(wallUid);
      return wall.entity.widthMM || null;
    }

    return null;
  };

  const aWidth = getMaxWallWidthFromEdge(entity.polygonEdgeUid[0]);
  const bWidth = getMaxWallWidthFromEdge(entity.polygonEdgeUid[1]);

  if (aWidth !== null && bWidth !== null) {
    return Math.max(aWidth, bWidth);
  } else {
    return aWidth ?? bWidth ?? heatloadSpec.wallSpec.internalWidthMM;
  }
}

function makeNeighboringSpaceFields(
  context: CoreContext,
  entity: WallEntity,
): PropertyField[] {
  let properties: PropertyField[] = [
    {
      property: "neighboringSpaceTemperatureC",
      title: "Neighboring Space Temperature",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: -100, max: null },
      multiFieldId: "neighboringSpaceTemperatureC",
      units: Units.Celsius,
      requiresInput: true,
    },
  ];
  return properties;
}

export function makeWallFields(
  context: CoreContext,
  entity: WallEntity,
): PropertyField[] {
  let coreWall = context.globalStore.get<CoreWall>(entity.uid);
  let wallType: WallType =
    entity.wallType === null
      ? coreWall.isInternalWall()
        ? WallType.internal
        : WallType.external
      : entity.wallType;

  const wallTypes: { key: string; name: string }[] = [
    { key: WallType.external, name: WallType.external },
    { key: WallType.internal, name: WallType.internal },
    { key: WallType.party, name: WallType.party },
  ];

  let properties: PropertyField[] = [
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },
    {
      property: "wallMaterialUid",
      title: "Wall Material",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: getAvailableHeatLoadComponentMaterial(context, wallType),
      },
      multiFieldId: "wallMaterialUid",
    },
    {
      property: "uValueW_M2K",
      title: "U Value",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "uValueW_M2K",
      units: Units.None,
    },
    {
      property: "widthMM",
      title: "Wall Thickness",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "widthMM",
      units: Units.Millimeters,
    },
  ];
  if (coreWall.isCustomInternalWall()) {
    properties.push(...makeNeighboringSpaceFields(context, entity));
  }

  properties.push({
    property: "wallType",
    title: "Wall Type",
    hasDefault: true,
    isCalculated: true,
    type: FieldType.Choice,
    params: {
      choices: coreWall.isAutoInternalWall()
        ? wallTypes.filter((wallType) => wallType.key !== WallType.external)
        : wallTypes,
    },
    multiFieldId: "wallType",
    units: Units.None,
  });

  if (wallType == WallType.external) {
    properties.push({
      property: "externalTemperatureC",
      title: "Outside Temperature",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: -100, max: null },
      multiFieldId: "externalTemperatureC",
      units: Units.Celsius,
    });
  }
  return properties.map(withPropertyTracking(context, entity));
}
