import CoreBaseBackedObject from "../api/coreObjects/lib/coreBaseBackedObject";
import { DrawableEntityConcrete } from "../api/document/entities/concrete-entity";
import { FlatPropertyFields } from "../api/document/entities/property-field";
import { DrawableEntity } from "../api/document/entities/simple-entities";
import {
  EntityType,
  getEntityIndividualName,
} from "../api/document/entities/types";
import { flattenTabFields } from "../api/document/entities/utils";
import {
  fillEntityDefaults,
  makeInertEntityFields,
} from "../api/document/utils";
import { collect } from "./array-utils";
import { GlobalStore } from "./globalstore/global-store";
import { MultiMap } from "./multi-map";
import { cloneSimple, getPropertyByString, setPropertyByString } from "./utils";

export interface CorruptEntityDetails {
  entity: DrawableEntity;
  uid: string;
  individualName: string | null;
  type: EntityType;
  errorMessage?: string;
  subtype: string;
  tertiaryType: string;
  reason: CorruptEntityReason;
  levelUid: string | null;
  levelName: string;
  possibleFixes: PossibleFix[];
}

export interface PossibleFix {
  details: string;
  property: FlatPropertyFields;
  oldValueAsString: string;
  newValueAsString: string;
}

export enum CorruptEntityReason {
  FillFailure = "Failed to fill",
  PropertyListFailure = "Failed to list properties",
}

export function getEntityCorruptions(
  coreObject: CoreBaseBackedObject<DrawableEntityConcrete>,
  findFixes: boolean,
): CorruptEntityDetails[] {
  const corrupted: CorruptEntityDetails[] = [];

  try {
    coreObject.flatProperties;
  } catch (e: unknown) {
    corrupted.push(
      corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.PropertyListFailure,
        (e as Error).message,
        findFixes,
      ),
    );
  }

  try {
    fillEntityDefaults(coreObject.context, coreObject.entity);
  } catch (e: unknown) {
    corrupted.push(
      corruptEntitiesDetails(
        coreObject,
        CorruptEntityReason.FillFailure,
        (e as Error).message,
        findFixes,
      ),
    );
  }

  return corrupted;
}

/**
 * For now we are just checking whether or not entities can be filled.
 * Later we likely will want to do more checks.
 */
export function calculateCorruptEntities(
  globalStore: GlobalStore,
  findFixes: boolean,
): MultiMap<string, CorruptEntityDetails> {
  const corrupted: MultiMap<string, CorruptEntityDetails> = new MultiMap();
  for (let uid of globalStore.keys()) {
    const coreObject =
      globalStore.get<CoreBaseBackedObject<DrawableEntityConcrete>>(uid);
    corrupted.add(uid, ...getEntityCorruptions(coreObject, findFixes));
  }

  return corrupted;
}

function corruptEntitiesDetails(
  coreObject: CoreBaseBackedObject<DrawableEntityConcrete>,
  reason: CorruptEntityReason,
  message: string,
  findFixes: boolean,
): CorruptEntityDetails {
  const levelUid =
    coreObject.globalStore.levelOfEntity.get(coreObject.uid) ?? null;
  return {
    entity: coreObject.entity,
    uid: coreObject.uid,
    individualName: getEntityIndividualName(coreObject.entity),
    type: coreObject.type,
    subtype: coreObject.subtype ?? "",
    tertiaryType: coreObject.tertiaryType ?? "",
    errorMessage: message,
    reason,
    levelUid,
    levelName: levelUid
      ? (coreObject.drawing.levels[levelUid]?.name ?? "Missing level")
      : "Entity not on a level",
    possibleFixes: findFixes ? getPotentialFixes(coreObject, reason) : [],
  };
}

export function getPotentialFixes(
  obj: CoreBaseBackedObject<DrawableEntityConcrete>,
  reason: CorruptEntityReason,
): PossibleFix[] {
  // If we cant list flat properties, then we cannot provide fixes by checking defaults.
  if (reason === CorruptEntityReason.PropertyListFailure) {
    return [];
  }

  try {
    return collect(obj.flatProperties, (property) =>
      getPossibleFix(obj, property, reason),
    );
  } catch (e) {
    console.error("Failed to calculate possible fixes", e);
    return [];
  }
}

export function getPossibleFix(
  obj: CoreBaseBackedObject<DrawableEntityConcrete>,
  property: FlatPropertyFields,
  _reason: CorruptEntityReason,
): PossibleFix | undefined {
  if (property.readonly) {
    return undefined;
  }
  if (property.hasDefault && property.defaultValue) {
    if (
      doesOverridingPropertyValueWork(obj, property, property.defaultValue())
    ) {
      return {
        details: `Property Change To Default`,
        property,
        oldValueAsString: stringifyProperty(
          getPropertyByString(obj.entity, property.property, true),
        ),
        newValueAsString: stringifyProperty(property.defaultValue()),
      };
    }
  }
  if (!property.requiresInput) {
    if (doesOverridingPropertyValueWork(obj, property, null)) {
      return {
        details: `Property Change To Null`,
        property,
        oldValueAsString: stringifyProperty(
          getPropertyByString(obj.entity, property.property, true),
        ),
        newValueAsString: stringifyProperty(null),
      };
    }
  }
  return undefined;
}

export function stringifyProperty(prop: any): string {
  if (prop === undefined) {
    return "undefined";
  } else if (prop === null) {
    return "null";
  } else if (prop === "") {
    return "Empty";
  }
  return prop.toString();
}

export function doesOverridingPropertyValueWork(
  obj: CoreBaseBackedObject<DrawableEntityConcrete>,
  property: FlatPropertyFields,
  newValue: any,
): boolean {
  const cloned = cloneSimple(obj.entity);
  // This is pretty horrible. tl;dr is that beforeSet mutates the original entity from when we created the property list.
  // Since we want to avoiding modifying any existing entities, we need to recreate the properties themselves.
  const newProperty = flattenTabFields(
    makeInertEntityFields(obj.context, cloned),
  ).find((x) => x.property === property.property);
  if (!newProperty) {
    throw new Error(
      `Could find property ${property.property} after creating a copy`,
    );
  }
  // This is really important, some fields have dependants they reset when changed.
  property?.beforeSet?.call(property, newValue);
  setPropertyByString(cloned, property.property, newValue);

  try {
    fillEntityDefaults(obj.context, cloned);
    return true;
  } catch (e) {
    return false;
  }
}
