// record visible objects only.
import {
  CoreConnectableObjectConcrete,
  CoreObjectConcrete,
  isArchitectureElement,
  isCoreEdgeObject,
  isPolygonObject,
  isVirtualEdgeObject,
} from "../../api/coreObjects";
import { CoreCalculatableObject } from "../../api/coreObjects/lib/CoreCalculatableObject";
import CoreBaseBackedObject from "../../api/coreObjects/lib/coreBaseBackedObject";
import { ArchitectureElementEntity } from "../../api/document/entities/architectureElement-entity";
import {
  EdgeEntityConcrete,
  PolygonEntityConcrete,
  VirtualEdgeEntityConcrete,
  isArchitectureElementEntity,
  isEdgeEntity,
  isPolygonEntity,
  isTerminusEntity,
  isVirtualEdgeEntity,
} from "../../api/document/entities/concrete-entity";
import { EntityType } from "../../api/document/entities/types";
import Trie from "../prefix-trie";
import { assertUnreachable, cloneNaive } from "../utils";

export class ObjectStore extends Map<string, CoreBaseBackedObject> {
  dependsOn = new Map<string, Map<string, Set<string>>>();
  dependedBy = new Map<string, Map<string, Set<string>>>();
  protected connections = new Map<string, string[]>();
  protected graveyard = new Map<string, CoreBaseBackedObject>();
  protected preserveList = new Set<string>();

  protected oldVertices = new Map<string, string[]>(); // Map from edge uid or polygon uid to list of vertex uids
  protected verticesToEdge = new Map<string, string>(); // Map of vertices uid to edge uid

  protected oldEdges = new Map<string, string[]>(); // Map from polygon uid to list of edge uids
  protected edgeToPolygons = new Map<string, string[]>(); // Map of edge uid to polygon uids

  protected oldWallToRoomEdge = new Map<string, string[]>(); // Map of wall uid to room edge uid
  protected roomEdgeToWall = new Map<string, string[]>(); // Map of room edge uid to wall uid

  protected oldFenToRoomEdge = new Map<string, string[]>(); // Map of wall uid to room edge uid
  protected roomEdgeToFen = new Map<string, string[]>(); // Map of room edge uid to fen uid

  protected oldArcToPolygon = new Map<string, string>(); // Map of arc uid to polygon uid
  protected polygonToArcs = new Map<string, string[]>(); // Map of polygon uid to arc uid

  protected oldTerminusToEdge = new Map<string, string>(); // Map of terminus uid to edge uid
  protected edgeToTerminus = new Map<string, string[]>(); // Map of edge uid to terminus uids

  keyPrefixTrie = new Trie();

  // A flag that, if set true, allows the document mutator to apply state directly to entities
  // without triggering side effects that change the state. For example, the directed valve object
  // maintains the sourceUid value in the properties, but must be disabled for the mutator to apply
  // diffs sensibly.
  suppressSideEffects: boolean = false;

  constructor() {
    super();
  }

  clear() {
    super.clear();
    this.dependsOn.clear();
    this.dependedBy.clear();
    this.connections.clear();
    this.oldVertices.clear();
    this.graveyard.clear();
    this.preserveList.clear();
    this.keyPrefixTrie.clear();
    this.verticesToEdge.clear();
    this.edgeToPolygons.clear();
    this.edgeToTerminus.clear();
  }

  inGraveyard(uid: string): boolean {
    return this.graveyard.has(uid);
  }

  protected pairHash(uid1: string, uid2: string): string {
    return uid1 < uid2 ? uid1 + uid2 : uid2 + uid1;
  }

  getEdgeByVertices(uid1: string, uid2: string): string | null {
    return this.verticesToEdge.get(this.pairHash(uid1, uid2)) || null;
  }

  getTerminiByEdge(uid: string): string[] {
    return this.edgeToTerminus.get(uid) || [];
  }

  getPolygonsByVertex(uid: string): string[] {
    let ls = Array.from(
      new Set<string>(
        this.connections
          .get(uid)
          ?.map((edgeUid) => this.edgeToPolygons.get(edgeUid) || [])
          .flat() || [],
      ),
    );
    return ls;
  }

  getPolygonsByEdge(uid: string): string[] {
    let ls = Array.from(new Set<string>(this.edgeToPolygons.get(uid) || []));
    return ls;
  }
  getWallsByRoomEdge(uid: string): string[] {
    return this.roomEdgeToWall.get(uid) || [];
  }

  getFensByRoomEdge(uid: string): string[] {
    return this.roomEdgeToFen.get(uid) || [];
  }
  getAllRoomEdges(): string[] {
    return Array.from(this.roomEdgeToWall.keys()).filter((uid) =>
      this.has(uid),
    );
  }
  getAllFens(): string[] {
    return Array.from(
      new Set(
        Array.from(this.roomEdgeToFen.values()).reduce(
          (acc, cur) => acc.concat(cur),
          [],
        ),
      ),
    );
  }
  getArcsByPolygon(uid: string): string[] {
    return this.polygonToArcs.get(uid) || [];
  }

  getConnections(uid: string): string[] {
    return this.connections.get(uid) || [];
  }

  get<T = CoreObjectConcrete>(key: string): T;
  get(key: string) {
    const res = super.get(key);
    if (res) {
      return res;
    }
    return this.graveyard.get(key)!;
  }

  watchDependencies(uid: string, prop: string, deps: Set<string>) {
    if (!this.dependsOn.has(uid)) {
      this.dependsOn.set(uid, new Map<string, Set<string>>());
    }
    const depends = this.dependsOn.get(uid)!;
    if (!depends.has(prop)) {
      depends.set(prop, deps);
    } else {
      throw new Error(
        "Property " + uid + " " + prop + " already has deps registered.",
      );
    }

    deps.forEach((dep) => {
      if (!this.dependedBy.has(dep)) {
        this.dependedBy.set(dep, new Map<string, Set<string>>());
      }
      const depended = this.dependedBy.get(dep)!;
      if (!depended.has(uid)) {
        depended.set(uid, new Set<string>());
      }
      if (!depended.get(uid)!.has(prop)) {
        depended.get(uid)!.add(prop);
      } else {
        throw new Error(
          "Dependency " +
            dep +
            " already has prop " +
            uid +
            " " +
            prop +
            " registered.",
        );
      }
    });
  }

  onDocumentLoaded() {
    this.forEach((o) => o.onUpdate());
  }

  bustDependencies(uid: string) {
    if (this.dependedBy.has(uid)) {
      this.dependedBy.get(uid)!.forEach((props, target) => {
        if (this.has(target)) {
          const o = this.get<CoreCalculatableObject>(target)!;
          props.forEach((prop) => {
            o.cache.delete(prop);
          });
        }

        const tprops = this.dependsOn.get(target)!;
        Array.from(props).forEach((prop) => {
          const deps = tprops.get(prop)!;
          deps.forEach((dep) => {
            this.dependedBy.get(dep)!.get(target)!.delete(prop);
            if (!this.dependedBy.get(dep)!.get(target)!.size) {
              this.dependedBy.get(dep)!.delete(target);
            }
            if (!this.dependedBy.get(dep)!.size) {
              this.dependedBy.delete(dep);
            }
          });

          if (!this.dependsOn.get(target)!.delete(prop)) {
            throw new Error("dependency graph inconsistency");
          }
          if (this.dependsOn.get(target)!.size === 0) {
            this.dependsOn.delete(target);
          }
        });
      });
    }
  }

  onEntityChange(uid: string) {
    this.bustDependencies(uid);
    const o = this.get(uid)!;
    o.onUpdate();
    if (
      isCoreEdgeObject(o) ||
      isPolygonObject(o) ||
      isVirtualEdgeObject(o) ||
      isArchitectureElement(o)
    ) {
      this.updateVertices(uid);
    }
  }

  preserve(uids: string[]) {
    this.preserveList = new Set<string>(uids);
    this.graveyard.forEach((o, k) => {
      if (!this.preserveList.has(k)) {
        this.graveyard.delete(k);
      }
    });
  }

  delete(key: string) {
    this.bustDependencies(key);
    const val = this.get(key);

    if (
      this.oldVertices.has(key) ||
      this.oldEdges.has(key) ||
      this.oldArcToPolygon.has(key)
    ) {
      this.detatchOldVertices(key);
    }

    if (this.has(key)) {
      const val = this.get(key);
      if (val && isVirtualEdgeObject(val)) {
        if (this.oldFenToRoomEdge.has(key) || this.oldWallToRoomEdge.has(key)) {
          this.detatchOldVertices(key);
        }
      }
    }

    if (this.preserveList.has(key) && val) {
      this.graveyard.set(key, val);
    }

    if (isCoreEdgeObject(val)) {
      this.edgeToTerminus.delete(val.entity.uid);
    }

    this.keyPrefixTrie.remove(key);

    return super.delete(key);
  }

  set(key: string, value: CoreBaseBackedObject) {
    this.keyPrefixTrie.add(key);
    this.bustDependencies(key);
    if (this.graveyard.has(key)) {
      value = this.graveyard.get(key)!;
    }

    if (this.has(key)) {
      const val = this.get(key);
      if (
        (val && (isCoreEdgeObject(val) || isPolygonObject(val))) ||
        isVirtualEdgeObject(val) ||
        isArchitectureElement(val)
      ) {
        this.detatchOldVertices(key);
      }

      if (isTerminusEntity(val.entity)) {
        const oldEdge = this.oldTerminusToEdge.get(key);
        if (oldEdge) {
          this.edgeToTerminus
            .get(oldEdge)
            ?.splice(this.edgeToTerminus.get(oldEdge)?.indexOf(key)!, 1);
        }
      }
    }

    if (
      isCoreEdgeObject(value) ||
      isPolygonObject(value) ||
      isVirtualEdgeObject(value) ||
      isArchitectureElement(value)
    ) {
      this.attachVertices(value.entity);
    }

    if (isTerminusEntity(value.entity)) {
      if (!this.edgeToTerminus.has(value.entity.edgeUid)) {
        this.edgeToTerminus.set(value.entity.edgeUid, []);
      }
      this.edgeToTerminus.get(value.entity.edgeUid)!.push(value.uid);
      this.oldTerminusToEdge.set(value.uid, value.entity.edgeUid);
    }

    const result = super.set(key, value);
    return result;
  }

  search(prefix: string): string[] {
    return this.keyPrefixTrie.search(prefix);
  }

  searchObj<T = CoreObjectConcrete>(prefix: string): T[] {
    return this.search(prefix).map((k) => this.get<T>(k));
  }

  // undefined values only for deleting values. So hacky but we need to hack now.
  updateVertices(key: string) {
    let obj = this.get(key);

    if (isCoreEdgeObject(obj)) {
      this.bustDependencies(key);
      const e = this.get(key)!.entity as EdgeEntityConcrete;
      this.detatchOldVertices(key);
      this.attachVertices(e);
    } else if (isPolygonObject(obj)) {
      this.bustDependencies(key);
      const e = this.get(key)!.entity as PolygonEntityConcrete;
      this.detatchOldVertices(key);
      this.attachVertices(e);
    } else if (isVirtualEdgeObject(obj)) {
      this.bustDependencies(key);
      const e = this.get(key)!.entity as VirtualEdgeEntityConcrete;
      this.detatchOldVertices(key);
      this.attachVertices(e);
    } else if (isArchitectureElement(obj)) {
      this.bustDependencies(key);
      const e = this.get(key)!.entity as ArchitectureElementEntity;
      this.detatchOldVertices(key);
      this.attachVertices(e);
    }
  }

  private detatchOldVertices(uid: string) {
    //uid is the edge uid or polygon uid
    let object = this.get(uid)!;
    if (isVirtualEdgeObject(object)) {
      switch (object.entity.type) {
        case EntityType.WALL:
          this.oldWallToRoomEdge.get(uid)?.forEach((edgeUid) => {
            if (edgeUid === undefined) {
              return;
            }

            this.bustDependencies(edgeUid);

            let ix = this.roomEdgeToWall.get(edgeUid)?.indexOf(uid);
            if (ix !== undefined && ix !== -1) {
              this.roomEdgeToWall.get(edgeUid)?.splice(ix, 1);
              if (this.roomEdgeToWall.get(edgeUid)?.length === 0) {
                this.roomEdgeToWall.delete(edgeUid);
              }
            } else {
              throw new Error("edge not found in roomEdgeToWall");
            }
          });

          this.oldVertices.delete(uid);
          break;
        case EntityType.FENESTRATION:
          this.oldFenToRoomEdge.get(uid)?.forEach((edgeUid) => {
            if (edgeUid === undefined) {
              return;
            }

            this.bustDependencies(edgeUid);

            let ix = this.roomEdgeToFen.get(edgeUid)?.indexOf(uid);
            if (ix !== undefined && ix !== -1) {
              this.roomEdgeToFen.get(edgeUid)?.splice(ix, 1);
              if (this.roomEdgeToFen.get(edgeUid)?.length === 0) {
                this.roomEdgeToFen.delete(edgeUid);
              }
            } else {
              throw new Error("edge not found in roomEdgeToFen");
            }
          });

          this.oldVertices.delete(uid);
          break;
        default:
          assertUnreachable(object.entity);
      }
    } else if (isCoreEdgeObject(this.get(uid)!)) {
      this.oldVertices.get(uid)!.forEach((oldVal) => {
        if (oldVal === undefined) {
          return;
        }
        this.bustDependencies(oldVal);
        const arr = this.connections.get(oldVal);
        if (!arr) {
          throw new Error("old value didn't register a connectable. " + oldVal);
        }
        const ix = arr.indexOf(uid);
        if (ix === -1) {
          throw new Error("connections are in an invalid state");
        }

        const co = this.get(oldVal) as CoreConnectableObjectConcrete;
        if (co) {
          co.onDisconnect(uid);
        }
        arr.splice(ix, 1);
      });
      this.verticesToEdge.delete(
        this.pairHash(
          this.oldVertices.get(uid)![0],
          this.oldVertices.get(uid)![1],
        ),
      );
      this.oldVertices.delete(uid);
    } else if (isPolygonObject(object)) {
      this.oldEdges.get(uid)!.forEach((e) => {
        if (!this.edgeToPolygons.get(e)) {
          return;
        }
        let ix = this.edgeToPolygons.get(e)!.indexOf(uid);

        if (ix === -1) {
          throw new Error("polygons are in an invalid state");
        }
        this.edgeToPolygons.get(e)!.splice(ix, 1);
      });
      this.oldEdges.delete(uid);
    } else if (isArchitectureElement(object)) {
      let e = object.entity.parentUid!;
      let ix = this.polygonToArcs.get(e)!.indexOf(uid);

      if (ix === undefined) {
        throw new Error("Arc are in an invalid state");
      }
      this.polygonToArcs.get(e)!.splice(ix, 1);
      if (this.polygonToArcs.get(e)!.length === 0) this.polygonToArcs.delete(e);
      this.oldArcToPolygon.delete(uid);
    } else throw new Error("Invalid entity type");
  }
  private attachVertices(
    entity:
      | EdgeEntityConcrete
      | PolygonEntityConcrete
      | VirtualEdgeEntityConcrete
      | ArchitectureElementEntity,
  ) {
    if (isVirtualEdgeEntity(entity)) {
      if (!entity.polygonEdgeUid) return;
      switch (entity.type) {
        case EntityType.WALL:
          entity.polygonEdgeUid.forEach((newVal) => {
            if (!this.roomEdgeToWall.has(newVal)) {
              this.roomEdgeToWall.set(newVal, []);
            }
            this.roomEdgeToWall.get(newVal)!.push(entity.uid);
          });
          this.oldWallToRoomEdge.set(
            entity.uid,
            cloneNaive(entity.polygonEdgeUid),
          );
          break;
        case EntityType.FENESTRATION:
          entity.polygonEdgeUid.forEach((newVal) => {
            if (!this.roomEdgeToFen.has(newVal)) {
              this.roomEdgeToFen.set(newVal, []);
            }
            this.roomEdgeToFen.get(newVal)!.push(entity.uid);
          });
          this.oldFenToRoomEdge.set(
            entity.uid,
            cloneNaive(entity.polygonEdgeUid),
          );
          break;
        default:
          assertUnreachable(entity);
      }
    } else if (isEdgeEntity(entity)) {
      entity.endpointUid.forEach((newVal) => {
        if (newVal === undefined) {
          return;
        }
        this.bustDependencies(newVal);
        if (!this.connections.has(newVal)) {
          this.connections.set(newVal, []);
        }
        if (this.get(newVal)) {
          (this.get(newVal) as CoreConnectableObjectConcrete).onConnect(
            entity.uid,
          );
        }
        this.connections.get(newVal)!.push(entity.uid);
      });

      this.verticesToEdge.set(
        this.pairHash(entity.endpointUid[0], entity.endpointUid[1]),
        entity.uid,
      );
      this.oldVertices.set(entity.uid, [
        entity.endpointUid[0],
        entity.endpointUid[1],
      ]);
    } else if (isPolygonEntity(entity)) {
      entity.edgeUid.forEach((newVal) => {
        if (newVal === undefined) {
          return;
        }
        this.bustDependencies(newVal);
        if (!this.edgeToPolygons.has(newVal)) {
          this.edgeToPolygons.set(newVal, []);
        }
        this.oldEdges.set(entity.uid, cloneNaive(entity.edgeUid));
        this.edgeToPolygons.get(newVal)!.push(entity.uid);
      });
    } else if (isArchitectureElementEntity(entity)) {
      let e = entity.parentUid!;
      let a = entity.uid;
      if (!this.polygonToArcs.has(e)) {
        this.polygonToArcs.set(e, []);
      }
      this.polygonToArcs.get(e)!.push(a);
      this.oldArcToPolygon.set(a, e);
    } else throw new Error("Invalid entity type");
  }
}
