import Flatten from "@flatten-js/core";
import { bestVerticalDuctAxialAngleRAD } from "../../../../common/src/api/calculations/ventilation/ducts";
import CoreFitting, {
  FittingEntryType,
} from "../../../../common/src/api/coreObjects/coreFitting";
import {
  getDuctWidthMM,
  getPhysicalEndpoints,
} from "../../../../common/src/api/coreObjects/lib/ductFittingPrimitives";
import {
  FittingEntity,
  fillFittingDefaultFields,
  isDuctFittingEntity,
} from "../../../../common/src/api/document/entities/fitting-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { lighten } from "../../../../common/src/lib/color";
import { Coord, Coord3D, coordAdd } from "../../../../common/src/lib/coord";
import {
  EPS,
  assertUnreachable,
  floatEq,
} from "../../../../common/src/lib/utils";
import { Vector3 } from "../../../../common/src/lib/vector3";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import { getDragPriority } from "../../store/document";
import { DocumentState } from "../../store/document/types";
import { getGlobalContext } from "../../store/globalCoreContext";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { paintVerticalDuctShape } from "../lib/drawing-helpers/ducts";
import { LiveWarnings } from "../lib/entity-popups/live-warnings";
import { EntityPopupContent } from "../lib/entity-popups/types";
import {
  generateFittingHeatmap,
  isHeatmapEnabled,
} from "../lib/heatmap/heatmap";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import { ConnectableObject } from "../lib/object-traits/connectable";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { getHighlightColor } from "../lib/utils";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete } from "./concrete-object";
import DrawableConduit from "./drawableConduit";
const Base = HoverableObject(
  CalculatedObject(
    SelectableObject(
      CoolDraggableObject(
        ConnectableObject(
          CenteredObject(SnappableObject(Core2Drawable(CoreFitting))),
        ),
      ),
    ),
  ),
);

interface ArmRenderData {
  vector: Flatten.Vector;
  pipe: DrawableConduit;
  baseDrawnColor: string;
  filled: FittingEntity;
}

interface FittingRenderData {
  arms: ArmRenderData[];
  defaultWidth: number;
}

export const FITTING_MIN_SCALE_DRAWN = 0.025;

export const FITTING_DIAMETER_PIXELS = 0.75;

// Allow a height difference between vents of up to 1mm
export const VENT_DRAWING_TOLERANCE_MM = 1;

export const TURN_RADIUS_MM = 25;

export default class DrawableFitting extends Base {
  type: EntityType.FITTING = EntityType.FITTING;
  minimumConnections = 0;
  maximumConnections = null;
  dragPriority = getDragPriority(EntityType.FITTING);

  lastDrawnWidth!: number;
  pixelRadius: number = 5;
  lastDrawnLength!: number;

  lastRadials!: Array<[Coord, DrawableObjectConcrete]>;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<FittingEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
    if (this.document.uiState.bigHitbox.includes(this.uid)) {
      this.radius = 2000;
    }
  }

  lastFittingRenderData: FittingRenderData | null = null;

  getFittingRenderData(context: DrawingContext): FittingRenderData {
    if (!this.lastFittingRenderData) {
      const result: ArmRenderData[] = [];

      this.lastRadials = this.getRadials() as any as Array<
        [Coord, DrawableObjectConcrete]
      >;

      const scale =
        FITTING_DIAMETER_PIXELS / context.graphics.worldToSurfaceScale;

      const minJointLength = scale;
      let baseColorHex = this.baseDrawnColor(context).hex;
      baseColorHex = lighten(baseColorHex, -40);

      const defaultWidth = Math.max(minJointLength, 25);

      this.lastRadials.forEach(([wc, pipe]) => {
        if (pipe.type !== EntityType.CONDUIT) {
          return;
        }
        const oc = this.toObjectCoord(wc);
        const vec = new Flatten.Vector(
          Flatten.point(0, 0),
          Flatten.point(oc.x, oc.y),
        );

        if (vec.length > EPS) {
          result.push({
            vector: vec,
            pipe,
            baseDrawnColor: baseColorHex,
            filled: fillFittingDefaultFields(this.context, this.entity),
          });
        }
      });

      this.lastFittingRenderData = {
        arms: result,
        defaultWidth,
      };
    }
    return this.lastFittingRenderData;
  }

  onRedrawNeeded() {
    super.onRedrawNeeded();
    this.lastFittingRenderData = null;
  }

  drawDuctFitting(context: DrawingContext, args: EntityDrawingArgs): void {
    const { ctx, graphics } = context;
    const { forExport, heatmapMode } = args;

    const scale = graphics.worldToSurfaceScale;

    if (scale > FITTING_MIN_SCALE_DRAWN || forExport) {
      const targetWWidth = 15;
      const s = graphics.worldToSurfaceScale;
      const baseWidth = Math.max(
        1.5 / s,
        targetWWidth / graphics.unitWorldLength,
        (1.5 / s) * (5 + Math.log(s)),
      );
      ctx.lineWidth = Math.max(1 / s, baseWidth / 8);

      const liveCalcs = this.globalStore.getOrCreateLiveCalculation(
        this.entity,
      );
      const calcs = this.globalStore.getOrCreateCalculation(this.entity);
      const primitives =
        liveCalcs.physicalDuctPrimitives &&
        liveCalcs.physicalDuctPrimitives.length
          ? liveCalcs.physicalDuctPrimitives
          : calcs.physicalDuctPrimitives;
      if (!primitives) {
        // TODO: Draw a warning
        return;
      }
      const thisCoord = this.toWorldCoord();

      let baseColorHex = this.baseDrawnColor(context).hex;
      baseColorHex = lighten(baseColorHex, -40);

      ctx.strokeStyle = baseColorHex;
      let heatmapOverrideColor = false;

      if (
        isHeatmapEnabled(this.document) &&
        this.document.uiState.drawingLayout === "ventilation"
      ) {
        const filled = fillFittingDefaultFields(this.context, this.entity);
        const calculation = this.globalStore.getOrCreateCalculation(
          this.entity,
        );
        if (heatmapMode !== undefined && calculation !== undefined) {
          const color = generateFittingHeatmap(
            this,
            heatmapMode,
            calculation,
            filled,
            getGlobalContext(),
          );
          if (color !== undefined) {
            ctx.fillStyle = color;
            heatmapOverrideColor = true;
            // ctx.shadowColor = color;
            // ctx.shadowBlur = 15;
          } else {
            return;
          }
        }
      }

      this.withWorldAngle(context, { x: 0, y: 0 }, () => {
        const tower = this.getCalculationTower(context);
        const internalConduitUids: string[] = [];
        for (const lvl of tower) {
          if (lvl.length > 1) {
            internalConduitUids.push(lvl[1]!.uid);
          }
        }

        const axialRotDEG =
          bestVerticalDuctAxialAngleRAD(
            context,
            this.getSortedAnglesRAD().angles,
          ) *
          (180 / Math.PI);
        // For now, only draw horizontal plane ones
        outer: for (const p of primitives) {
          const eps = getPhysicalEndpoints(p);

          // Vertical conduits - special case
          if (
            p.type === "conduit" &&
            Math.abs(eps[0].coord.z - eps[1].coord.z) > EPS &&
            Math.abs(eps[0].coord.x - eps[1].coord.x) < EPS &&
            Math.abs(eps[0].coord.y - eps[1].coord.y) < EPS
          ) {
            console.log("Drawing vertical conduit");
            paintVerticalDuctShape(context, {
              size: p.eps[0].size,
              axialRotDEG,
            });
          }

          // Vertical endpoints - special case.
          for (const ep of eps) {
            if (ep.type === "external") {
              if (internalConduitUids.includes(ep.connection)) {
                paintVerticalDuctShape(context, {
                  size: ep.size,
                  axialRotDEG,
                });
              }
            }
          }

          for (let i = 1; i < eps.length; i++) {
            if (
              !floatEq(
                eps[i].coord.z,
                eps[i - 1].coord.z,
                VENT_DRAWING_TOLERANCE_MM,
              )
            ) {
              console.warn(
                "Skipping Draw of Entity due to inequal heights",
                this.entity.uid,
                eps[i],
                eps[i - 1],
              );
              continue outer;
            }
          }
          switch (p.type) {
            case "conduit": {
              const P0 = Flatten.point(
                p.eps[0].coord.x - thisCoord.x,
                p.eps[0].coord.y - thisCoord.y,
              );
              const P1 = Flatten.point(
                p.eps[1].coord.x - thisCoord.x,
                p.eps[1].coord.y - thisCoord.y,
              );
              const vector = new Vector3(
                p.eps[0].coord.x - p.eps[1].coord.x,
                p.eps[0].coord.y - p.eps[1].coord.y,
                p.eps[0].coord.z - p.eps[1].coord.z,
              );

              const orth = vector
                .rotateCWDEG(90)
                .unit.mul(0.5)
                .mul(getDuctWidthMM(p.eps[0].size));

              ctx.beginPath();
              ctx.moveTo(P0.x + orth.x, P0.y + orth.y);
              ctx.lineTo(P1.x + orth.x, P1.y + orth.y);
              ctx.stroke();

              ctx.beginPath();
              ctx.moveTo(P0.x - orth.x, P0.y - orth.y);
              ctx.lineTo(P1.x - orth.x, P1.y - orth.y);
              break;
            }
            case "elbow": {
              const vectors = [
                new Vector3(
                  p.eps[0].coord.x - p.center.x,
                  p.eps[0].coord.y - p.center.y,
                  p.eps[0].coord.z - p.center.z,
                ),
                new Vector3(
                  p.eps[1].coord.x - p.center.x,
                  p.eps[1].coord.y - p.center.y,
                  p.eps[1].coord.z - p.center.z,
                ),
              ];

              switch (p.jointType) {
                case "square":
                case "square-vanes": {
                  const P0 = Flatten.point(
                    p.eps[0].coord.x - thisCoord.x,
                    p.eps[0].coord.y - thisCoord.y,
                  );
                  const P1 = Flatten.point(
                    p.eps[1].coord.x - thisCoord.x,
                    p.eps[1].coord.y - thisCoord.y,
                  );

                  const PA0 = P0.translate(
                    vectors[0]
                      .rotateCWDEG(90)
                      .unit.mul(0.5)
                      .mul(getDuctWidthMM(p.eps[0].size))
                      .toFlatten(),
                  );
                  const PB0 = P0.translate(
                    vectors[0]
                      .rotateCWDEG(90)
                      .unit.mul(-0.5)
                      .mul(getDuctWidthMM(p.eps[0].size))
                      .toFlatten(),
                  );
                  const PA1 = P1.translate(
                    vectors[1]
                      .rotateCWDEG(90)
                      .unit.mul(-0.5)
                      .mul(getDuctWidthMM(p.eps[1].size))
                      .toFlatten(),
                  );
                  const PB1 = P1.translate(
                    vectors[1]
                      .rotateCWDEG(90)
                      .unit.mul(0.5)
                      .mul(getDuctWidthMM(p.eps[1].size))
                      .toFlatten(),
                  );
                  const lineA0 = Flatten.line(
                    PA0,
                    PA0.translate(vectors[0].toFlatten()),
                  );
                  const lineA1 = Flatten.line(
                    PA1,
                    PA1.translate(vectors[1].toFlatten()),
                  );
                  const lineB0 = Flatten.line(
                    PB0,
                    PB0.translate(vectors[0].toFlatten()),
                  );
                  const lineB1 = Flatten.line(
                    PB1,
                    PB1.translate(vectors[1].toFlatten()),
                  );
                  const PA = lineA0.intersect(lineA1)[0];
                  const PB = lineB0.intersect(lineB1)[0];

                  ctx.beginPath();
                  ctx.moveTo(PA0.x, PA0.y);
                  ctx.lineTo(PA.x, PA.y);
                  ctx.lineTo(PA1.x, PA1.y);
                  ctx.lineTo(PB1.x, PB1.y);
                  ctx.lineTo(PB.x, PB.y);
                  ctx.lineTo(PB0.x, PB0.y);
                  ctx.closePath();
                  ctx.stroke();

                  if (p.jointType === "square-vanes") {
                    // const numVanes = p.vanes!;
                    // square vanes are just a panel with turning vanes on it, not 1-3 long vanes
                    const numVanes = 3;
                    const cornerIsA =
                      PA.distanceTo(PA0)[0] < PB.distanceTo(PB1)[0];

                    const diagonalStart = cornerIsA ? PA : PB;
                    const diagonalVector = cornerIsA
                      ? Flatten.vector(PA, PB)
                      : Flatten.vector(PB, PA);

                    const isCW =
                      vectors[0]
                        .mul(-1)
                        .cross(vectors[1])
                        .dot(new Vector3(0, 0, 1)) > 0;
                    const angleStart = isCW
                      ? vectors[0].rotateCWDEG(-90).angleRAD
                      : vectors[1].rotateCWDEG(-90).angleRAD;
                    const angleEnd = isCW
                      ? vectors[1].rotateCWDEG(90).angleRAD
                      : vectors[0].rotateCWDEG(90).angleRAD;

                    for (let i = 0; i < numVanes; i++) {
                      const dist =
                        diagonalVector.length * ((i + 1) / (numVanes + 1));
                      const radius = diagonalVector.length / 6;
                      const point = diagonalStart.translate(
                        diagonalVector.normalize().multiply(dist - radius),
                      );
                      ctx.beginPath();
                      ctx.arc(point.x, point.y, radius, angleStart, angleEnd);
                      ctx.stroke();
                    }
                  }

                  break;
                }
                case "smooth":
                case "smooth-vanes":
                case "multi-piece": {
                  const P0 = Flatten.point(
                    p.eps[0].coord.x - thisCoord.x,
                    p.eps[0].coord.y - thisCoord.y,
                  );
                  const P1 = Flatten.point(
                    p.eps[1].coord.x - thisCoord.x,
                    p.eps[1].coord.y - thisCoord.y,
                  );
                  const vectors = [
                    new Vector3(
                      p.eps[0].coord.x - p.center.x,
                      p.eps[0].coord.y - p.center.y,
                      p.eps[0].coord.z - p.center.z,
                    ),
                    new Vector3(
                      p.eps[1].coord.x - p.center.x,
                      p.eps[1].coord.y - p.center.y,
                      p.eps[1].coord.z - p.center.z,
                    ),
                  ];

                  const orth0 = vectors[0].rotateCWDEG(90).unit;
                  const orth1 = vectors[1].rotateCWDEG(90).unit;

                  const line0 = Flatten.line(
                    P0,
                    P0.translate(orth0.toFlatten()),
                  );
                  const line1 = Flatten.line(
                    P1,
                    P1.translate(orth1.toFlatten()),
                  );

                  const curveCenter = line0.intersect(line1)[0];

                  const centerRadius = P0.distanceTo(curveCenter)[0];
                  const radiusA =
                    centerRadius - getDuctWidthMM(p.eps[0].size) / 2;
                  const radiusB =
                    centerRadius + getDuctWidthMM(p.eps[1].size) / 2;

                  const isCW = vectors[0].isClockwiseRotation(vectors[1]);
                  const angleStart = isCW
                    ? vectors[0].rotateCWDEG(-90).angleRAD
                    : vectors[1].rotateCWDEG(-90).angleRAD;
                  const angleEnd = isCW
                    ? vectors[1].rotateCWDEG(90).angleRAD
                    : vectors[0].rotateCWDEG(90).angleRAD;

                  // use dot product test

                  if (
                    p.jointType === "smooth-vanes" ||
                    p.jointType === "smooth"
                  ) {
                    ctx.beginPath();
                    ctx.arc(
                      curveCenter.x,
                      curveCenter.y,
                      radiusA,
                      angleStart,
                      angleEnd,
                      false,
                    );

                    ctx.arc(
                      curveCenter.x,
                      curveCenter.y,
                      radiusB,
                      angleEnd,
                      angleStart,
                      true,
                    );
                    ctx.closePath();
                    ctx.stroke();
                  } else if (p.jointType === "multi-piece") {
                    // draw polygon edge kind of thing.
                    const angleDiffRad =
                      (angleEnd - angleStart + Math.PI * 2) % (Math.PI * 2);
                    const angleDiffSegmentRad = angleDiffRad / (p.pieces! - 1);
                    const angles: number[] = [];

                    // angles.push(angleStart);
                    for (let i = 0; i < p.pieces! - 1; i++) {
                      angles.push(angleStart + angleDiffSegmentRad * (i + 0.5));
                    }
                    // angles.push(angleEnd);

                    const radiusBp =
                      radiusB / Math.cos(angleDiffSegmentRad / 2);
                    const radiusAp =
                      radiusA / Math.cos(angleDiffSegmentRad / 2);

                    for (const a of angles) {
                      const x = curveCenter.x + radiusAp * Math.cos(a);
                      const y = curveCenter.y + radiusAp * Math.sin(a);
                      ctx.beginPath();
                      ctx.moveTo(x, y);
                      ctx.lineTo(
                        curveCenter.x + radiusBp * Math.cos(a),
                        curveCenter.y + radiusBp * Math.sin(a),
                      );
                      ctx.stroke();
                    }

                    ctx.beginPath();
                    ctx.moveTo(
                      curveCenter.x + radiusA * Math.cos(angleStart),
                      curveCenter.y + radiusA * Math.sin(angleStart),
                    );
                    for (const a of angles) {
                      ctx.lineTo(
                        curveCenter.x + radiusAp * Math.cos(a),
                        curveCenter.y + radiusAp * Math.sin(a),
                      );
                    }
                    ctx.lineTo(
                      curveCenter.x + radiusA * Math.cos(angleEnd),
                      curveCenter.y + radiusA * Math.sin(angleEnd),
                    );
                    ctx.lineTo(
                      curveCenter.x + radiusB * Math.cos(angleEnd),
                      curveCenter.y + radiusB * Math.sin(angleEnd),
                    );
                    for (let i = angles.length - 1; i >= 0; i--) {
                      ctx.lineTo(
                        curveCenter.x + radiusBp * Math.cos(angles[i]),
                        curveCenter.y + radiusBp * Math.sin(angles[i]),
                      );
                    }
                    ctx.lineTo(
                      curveCenter.x + radiusB * Math.cos(angleStart),
                      curveCenter.y + radiusB * Math.sin(angleStart),
                    );
                    ctx.closePath();
                    ctx.stroke();
                  } else {
                    assertUnreachable(p.jointType);
                  }

                  if (p.jointType === "smooth-vanes") {
                    for (let i = 0; i < p.vanes!; i++) {
                      const radius =
                        radiusA +
                        (radiusB - radiusA) * ((i + 1) / (p.vanes! + 1));
                      ctx.beginPath();
                      ctx.arc(
                        curveCenter.x,
                        curveCenter.y,
                        radius,
                        angleStart,
                        angleEnd,
                      );
                      ctx.stroke();
                    }
                  }

                  break;
                }
              }

              break;
            }
            case "transition": {
              const vector = new Vector3(
                p.eps[0].coord.x - p.eps[1].coord.x,
                p.eps[0].coord.y - p.eps[1].coord.y,
                p.eps[0].coord.z - p.eps[1].coord.z,
              );

              const orth0 = vector
                .rotateCWDEG(90)
                .unit.mul(0.5)
                .mul(getDuctWidthMM(p.eps[0].size));
              const orth1 = vector
                .rotateCWDEG(90)
                .unit.mul(0.5)
                .mul(getDuctWidthMM(p.eps[1].size));

              const P0 = Flatten.point(
                p.eps[0].coord.x - thisCoord.x,
                p.eps[0].coord.y - thisCoord.y,
              );
              const P1 = Flatten.point(
                p.eps[1].coord.x - thisCoord.x,
                p.eps[1].coord.y - thisCoord.y,
              );

              const PA0 = P0.translate(orth0.toFlatten());
              const PB0 = P0.translate(orth0.toFlatten().multiply(-1));
              const PA1 = P1.translate(orth1.toFlatten());
              const PB1 = P1.translate(orth1.toFlatten().multiply(-1));

              ctx.beginPath();
              ctx.moveTo(PA0.x, PA0.y);
              ctx.lineTo(PA1.x, PA1.y);
              ctx.lineTo(PB1.x, PB1.y);
              ctx.lineTo(PB0.x, PB0.y);
              ctx.closePath();
              ctx.stroke();
              break;
            }
            case "takeoff": {
              const B = Flatten.point(
                p.branch.coord.x - thisCoord.x,
                p.branch.coord.y - thisCoord.y,
              );
              const M = Flatten.point(
                p.main.coord.x - thisCoord.x,
                p.main.coord.y - thisCoord.y,
              );
              // The vectors come back serialized and need to be converted back to Vector3
              const branchNormal = new Vector3(
                p.branchNormal.x,
                p.branchNormal.y,
                p.branchNormal.z,
              );
              const mainNormal = new Vector3(
                p.mainNormal.x,
                p.mainNormal.y,
                p.mainNormal.z,
              );

              const branchOrth = branchNormal
                .rotateCWDEG(90)
                .unit.mul(0.5)
                .mul(getDuctWidthMM(p.branch.size));

              const B0 = B.translate(branchOrth.toFlatten());
              const B1 = B.translate(branchOrth.toFlatten().multiply(-1));

              if (branchNormal.toFlatten().length <= EPS) {
                continue outer;
              }

              const L0 = Flatten.line(
                B0,
                B0.translate(branchNormal.toFlatten()),
              );
              const L1 = Flatten.line(
                B1,
                B1.translate(branchNormal.toFlatten()),
              );

              const LM = Flatten.line(M, M.translate(mainNormal.toFlatten()));
              const M0 = L0.intersect(LM)[0];
              const M1 = L1.intersect(LM)[0];

              ctx.beginPath();
              ctx.moveTo(B0.x, B0.y);
              ctx.lineTo(M0.x, M0.y);
              ctx.lineTo(M1.x, M1.y);
              ctx.lineTo(B1.x, B1.y);
              ctx.closePath();
              ctx.stroke();

              for (const shoe of p.shoes) {
                const shoeMainNormal = new Vector3(
                  shoe.mainNormal.x,
                  shoe.mainNormal.y,
                  shoe.mainNormal.z,
                );
                const shoeCoord = Flatten.point(
                  shoe.coord.x - thisCoord.x,
                  shoe.coord.y - thisCoord.y,
                );
                const p0 = coordAdd(
                  shoeCoord,
                  shoeMainNormal.mul(shoe.lengthMM),
                );
                const p1 = coordAdd(shoeCoord, branchNormal.mul(shoe.lengthMM));

                ctx.beginPath();
                ctx.moveTo(p0.x, p0.y);
                ctx.lineTo(p1.x, p1.y);
                ctx.lineTo(shoeCoord.x, shoeCoord.y);
                ctx.closePath();
                ctx.stroke();
              }

              break;
            }
            case "y": {
              const PM = Flatten.point(
                p.main.coord.x - thisCoord.x,
                p.main.coord.y - thisCoord.y,
              );
              const PB1 = Flatten.point(
                p.branches[0].coord.x - thisCoord.x,
                p.branches[0].coord.y - thisCoord.y,
              );
              const PB2 = Flatten.point(
                p.branches[1].coord.x - thisCoord.x,
                p.branches[1].coord.y - thisCoord.y,
              );

              const mainNormal = new Vector3(
                p.mainNormal.x,
                p.mainNormal.y,
                p.mainNormal.z,
              );
              const branchNormal1 = new Vector3(
                p.branchNormals[0].x,
                p.branchNormals[0].y,
                p.branchNormals[0].z,
              );
              const branchNormal2 = new Vector3(
                p.branchNormals[1].x,
                p.branchNormals[1].y,
                p.branchNormals[1].z,
              );

              const mainOrth = mainNormal
                .rotateCWDEG(90)
                .unit.mul(0.5)
                .mul(getDuctWidthMM(p.main.size));
              const branchOrth1 = branchNormal1
                .rotateCWDEG(90)
                .unit.mul(0.5)
                .mul(getDuctWidthMM(p.branches[0].size));
              const branchOrth2 = branchNormal2
                .rotateCWDEG(90)
                .unit.mul(0.5)
                .mul(getDuctWidthMM(p.branches[1].size));

              const MA = PM.translate(mainOrth.toFlatten());
              const MB = PM.translate(mainOrth.toFlatten().multiply(-1));
              const B1A = PB1.translate(branchOrth1.toFlatten());
              const B1B = PB1.translate(branchOrth1.toFlatten().multiply(-1));
              const B2A = PB2.translate(branchOrth2.toFlatten());
              const B2B = PB2.translate(branchOrth2.toFlatten().multiply(-1));

              let polygon: Coord[] = [];
              switch (p.style) {
                case "breech": {
                  const dimple = {
                    x: (PB1.x + PB2.x) / 2,
                    y: (PB1.y + PB2.y) / 2,
                  };
                  polygon = [MA, B1A, B2A, B2B, B1B, MB, dimple];
                  break;
                }
                case "square": {
                  polygon = [MA, B1A, B2A, B2B, B1B, MB];
                  break;
                }
                case "y-piece":
                  {
                    const L1A = Flatten.line(
                      B1A,
                      B1A.translate(branchNormal1.toFlatten()),
                    );
                    const L1B = Flatten.line(
                      B1B,
                      B1B.translate(branchNormal1.toFlatten()),
                    );
                    const L2A = Flatten.line(
                      B2A,
                      B2A.translate(branchNormal2.toFlatten()),
                    );
                    const L2B = Flatten.line(
                      B2B,
                      B2B.translate(branchNormal2.toFlatten()),
                    );
                    const LMA = Flatten.line(
                      MA,
                      MA.translate(mainNormal.toFlatten()),
                    );
                    const LMB = Flatten.line(
                      MB,
                      MB.translate(mainNormal.toFlatten()),
                    );

                    polygon = [MA, B1A, B2A, B2B, B1B, MB];
                    const P12A = L1A.intersect(L2B)[0];
                    const P12B = L1B.intersect(L2A)[0];
                    if (P12A && P12B) {
                      if (
                        Flatten.vector(P12A, P12B).dot(mainNormal.toFlatten()) >
                        0
                      ) {
                        polygon.push(P12A);
                      } else {
                        polygon.push(P12B);
                      }
                    }

                    const P1MA = L1A.intersect(LMB)[0];
                    const P1MB = L1B.intersect(LMA)[0];
                    if (P1MA && P1MB) {
                      if (
                        Flatten.vector(P1MA, P1MB).dot(
                          branchNormal2.toFlatten(),
                        ) > 0
                      ) {
                        polygon.push(P1MA);
                      } else {
                        polygon.push(P1MB);
                      }
                    }

                    const P2MA = L2A.intersect(LMB)[0];
                    const P2MB = L2B.intersect(LMA)[0];
                    if (P2MA && P2MB) {
                      if (
                        Flatten.vector(P2MA, P2MB).dot(
                          branchNormal1.toFlatten(),
                        ) > 0
                      ) {
                        polygon.push(P2MA);
                      } else {
                        polygon.push(P2MB);
                      }
                    }
                  }
                  break;
              }

              const PC = Flatten.point(
                p.center.x - thisCoord.x,
                p.center.y - thisCoord.y,
              );
              polygon.sort((a, b) => {
                const aAngle = Math.atan2(a.y - PC.y, a.x - PC.x);
                const bAngle = Math.atan2(b.y - PC.y, b.x - PC.x);
                return aAngle - bAngle;
              });

              ctx.beginPath();
              ctx.moveTo(polygon[0].x, polygon[0].y);
              for (let i = 1; i < polygon.length; i++) {
                ctx.lineTo(polygon[i].x, polygon[i].y);
              }
              ctx.closePath();
              ctx.stroke();

              break;
            }
            case "nipple": {
              // No-op
              break;
            }
            default:
              assertUnreachable(p);
          }

          if (heatmapOverrideColor) {
            ctx.globalAlpha = 0.9;
            ctx.fill();
          }
        }
      });
    }
  }

  drawPipeFitting(context: DrawingContext, args: EntityDrawingArgs): void {
    const { ctx, graphics } = context;
    const { selected, overrideColorList, heatmapMode } = args;

    const scale = graphics.worldToSurfaceScale;

    const minJointLength = FITTING_DIAMETER_PIXELS / scale;
    if (scale > FITTING_MIN_SCALE_DRAWN) {
      const render = this.getFittingRenderData(context);
      ctx.lineCap = "round";

      const defaultWidth = Math.max(minJointLength, 25);
      this.lastDrawnWidth = defaultWidth;
      this.lastDrawnLength = Math.max(minJointLength, TURN_RADIUS_MM);

      for (const arm of render.arms) {
        let targetWidth = defaultWidth;
        if (arm.pipe.lastDrawnWidth) {
          targetWidth = Math.max(
            defaultWidth,
            arm.pipe.lastDrawnWidth +
              Math.min(
                arm.pipe.lastDrawnWidth,
                FITTING_DIAMETER_PIXELS / scale / 2,
              ),
          );
        }

        const small = arm.vector
          .normalize()
          .multiply(Math.max(minJointLength, TURN_RADIUS_MM));
        if (selected || overrideColorList.length) {
          ctx.beginPath();
          ctx.lineWidth = targetWidth + FITTING_DIAMETER_PIXELS * 2;

          this.lastDrawnWidth =
            defaultWidth + (FITTING_DIAMETER_PIXELS * 2) / scale;
          ctx.strokeStyle = rgb2style(
            getHighlightColor(selected, overrideColorList, {
              hex: lighten(arm.baseDrawnColor, 50),
            }),
            0.5,
          );
          ctx.moveTo(0, 0);
          ctx.lineTo(small.x, small.y);
          ctx.stroke();
        }

        ctx.strokeStyle = arm.baseDrawnColor;

        if (isHeatmapEnabled(this.document)) {
          const filled = arm.filled;
          const calculation = this.globalStore.getOrCreateCalculation(
            this.entity,
          );
          if (heatmapMode !== undefined && calculation !== undefined) {
            const color = generateFittingHeatmap(
              this,
              heatmapMode,
              calculation,
              filled,
              getGlobalContext(),
            );
            if (color !== undefined) {
              ctx.strokeStyle = color;
              // ctx.shadowColor = color;
              // ctx.shadowBlur = 15;
            } else {
              return;
            }
          }
        }
        if (!this.isActive()) {
          ctx.strokeStyle = "rgba(150, 150, 150, 0.65)";
        }
        ctx.lineWidth = targetWidth;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(small.x, small.y);
        ctx.stroke();

        if (this.isHovering) {
          ctx.beginPath();
          ctx.lineWidth = targetWidth + 3;
          ctx.strokeStyle = "rgba(255, 255, 255, 0.5)";
          ctx.moveTo(0, 0);
          ctx.lineTo(small.x, small.y);
          ctx.stroke();
        }

        if (
          this.document.uiState.selectedUids.includes(this.entity.uid) &&
          isDuctFittingEntity(this.entity)
        ) {
          // TODO: If fitting Neighbor to it get selected
          const { referenceMap } = this.getCrossSectionBreakdown(context);
          Object.values(referenceMap).forEach((entry) => {
            switch (entry.type) {
              case FittingEntryType.FITTING: {
                // Ignore as no interest showing it
                break;
              }
              case FittingEntryType.CONDUIT: {
                const { ref, angleRefCenterFittingRotateDegCW } = entry;
                // Draw it
                // Convert the angle from degrees to radians
                const radianAngle =
                  (angleRefCenterFittingRotateDegCW - 90) * (Math.PI / 180);

                // Using trigonometry to determine the endpoint of the drawing line.
                // Let's assume a fixed length for the conduit drawing.
                const conduitLength = targetWidth * 3;
                const conduitEndX = conduitLength * Math.cos(radianAngle);
                const conduitEndY = conduitLength * Math.sin(radianAngle);

                // If you also want to label the conduit, add the following:
                ctx.fillStyle = "black";
                ctx.font = `${Math.min(
                  Math.floor(targetWidth / scale),
                  80,
                )}px Arial`;
                ctx.textAlign = "center";
                ctx.fillTextStable(
                  ref,
                  conduitEndX,
                  conduitEndY,
                  undefined,
                  "middle",
                ); // Adjust offsets as needed

                break;
              }
            }
          });
        }
      }
    }

    const liveCalcs = this.globalStore.getOrCreateLiveCalculation(this.entity);
    if (liveCalcs.warnings.length) {
      // cap ends are usually a sign of a problem and should be connected to something
      // or removed.
      const oldFilter = ctx.filter; // remove global transparency effects from  annotations
      const oldAlpha = ctx.globalAlpha;
      ctx.filter = "none";
      ctx.globalAlpha = 1;

      ctx.beginPath();
      ctx.arc(
        0,
        0,
        Math.max(minJointLength, TURN_RADIUS_MM, 6 / scale),
        0,
        2 * Math.PI,
      );
      ctx.strokeStyle = "red";
      ctx.lineWidth = 1 / scale;
      ctx.stroke();

      ctx.filter = oldFilter;
      ctx.globalAlpha = oldAlpha;
    }

    if (liveCalcs.isVentExit) {
      ctx.beginPath();

      ctx.arc(
        0,
        0,
        Math.max(minJointLength, TURN_RADIUS_MM, 6 / scale),
        0,
        2 * Math.PI,
      );
      ctx.strokeStyle = "rgba(0, 255, 0, 0.5)";
      ctx.lineWidth = 3 / scale;
      ctx.stroke();
    }
  }

  drawConnectable(context: DrawingContext, args: EntityDrawingArgs): void {
    const { ctx, doc, vp } = context;
    const { forExport } = args;

    try {
      switch (this.entity.fittingType) {
        case "pipe":
          if (forExport) {
            // Fittings are too bulky and not useful on an export (though they are useful during design)
            return;
          }
          this.drawPipeFitting(context, args);
          break;
        case "duct":
          if (args.withCalculation) {
            this.drawDuctFitting(context, args);
          }
          break;
        case "cable":
          throw new Error("Cable fittings are not implemented");
        default:
          assertUnreachable(this.entity);
      }
    } catch (e) {
      console.warn(e);
      if (forExport) {
        return;
      }
      ctx.fillStyle = "rgba(255, 100, 100, 0.4)";
      ctx.beginPath();
      ctx.arc(
        0,
        0,
        this.toObjectLength(vp.surfaceToWorldLength(10)),
        0,
        Math.PI * 2,
      );
      ctx.fill();
    }

    // Display Entity Name
    if (this.entity.entityName) {
      const name = this.entity.entityName;
      ctx.font = 70 + "pt " + DEFAULT_FONT_NAME;
      const nameWidth = ctx.measureText(name).width;
      const offsetx = -nameWidth / 2;
      ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
      // the 70 represents the height of the font
      const textHight = 70;
      const offsetY = -textHight * 1.5;
      ctx.fillRect(offsetx, offsetY, nameWidth, 70);
      ctx.fillStyle = this.displayEntity(doc).color!.hex;
      ctx.fillTextStable(name, offsetx, offsetY - 4, undefined, "top");
      ctx.textBaseline = "alphabetic";
    }
    // ctx.shadowBlur = 0;
  }

  displayEntity(_context: DocumentState) {
    return fillFittingDefaultFields(getGlobalContext(), this.entity);
  }

  inBounds(moc: Coord, radius: number = 0): boolean {
    if (!this.isActive()) {
      return false;
    }

    // DEV-301 https://h2xengineering.atlassian.net/browse/DEV-301
    // commented out code since the login of determining if a fitting is being clicked does not make sense
    // if (this.lastRadials && this.lastDrawnLength !== undefined && this.lastDrawnWidth !== undefined) {
    //     let selected = false;
    //     this.lastRadials.forEach(([wc]) => {
    //         const oc = this.toObjectCoord(wc);
    //         const vec = new Flatten.Vector(Flatten.point(0, 0), Flatten.point(oc.x, oc.y));
    //         if (vec.length > EPS) {
    //             const small = vec.normalize().multiply(this.lastDrawnLength);
    //             if (
    //                 Flatten.segment(Flatten.point(0, 0), Flatten.point(small.x, small.y))
    //                     .distanceTo(Flatten.point(moc.x, moc.y)
    //                 )[0] <=
    //                 this.lastDrawnWidth + radius
    //             ) {
    //                 selected = true;
    //             }
    //         }
    //     });
    //     return selected;
    // } else

    let l = this.toObjectLength(TURN_RADIUS_MM * 1.5);
    if (this.document.uiState.bigHitbox.includes(this.entity.uid)) {
      l = this.radius;
    }
    return moc.x * moc.x + moc.y * moc.y <= (l + radius) * (l + radius);
  }

  getPopupContent() {
    const liveCalcs = this.globalStore.getOrCreateLiveCalculation(this.entity);
    const result: EntityPopupContent[] = [];
    for (const warning of liveCalcs.warnings) {
      switch (warning.type) {
        case "INVALID_CAP_END": {
          result.push(LiveWarnings.INVALID_CAP_END);
        }
      }
    }
    return result;
  }

  protected refreshObjectInternal(_obj: FittingEntity): void {
    //
  }

  getConnectionCoord(connectionUid: string): Coord3D {
    if (this.document.uiState.drawingMode === DrawingMode.Calculations) {
      return super.getConnectionCoord(connectionUid);
    }
    const coord = this.toWorldCoord();
    return {
      x: coord.x,
      y: coord.y,
      z: this.entity.calculationHeightM || 0,
    };
  }

  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }
}
