import { CoreContext } from "../api/calculations/types";
import CorePlant from "../api/coreObjects/corePlant";
import {
  HotWaterOutlet,
  ReturnSystemPlant,
} from "../api/document/entities/plants/plant-types";
import { Settings2ValidationRule } from "./settings-2-validation-predicate";

type KeysMatching<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];

/**
 * Example:
 *
 * type T = {
 *  a: number;
 *  b: string;
 *  c: number;
 * };
 *
 * KeysMatching<T, number> =
 *  "a" | "c"
 *
 * KeysMatching<T, string> =
 *  "b"
 */

// returns true if currentProperty < otherProperty or otherProperty is null
export function makeLTOtherPropertyIfNotNullRule(
  currentPropertyName: string,
  otherPropertyName: string,
  getOtherProperty: () => number | null,
): Settings2ValidationRule<number> {
  return {
    predicate: (currentProperty: number) => {
      const otherProperty = getOtherProperty();
      return otherProperty === null || currentProperty < otherProperty;
    },
    warning: `${currentPropertyName} must be less than ${otherPropertyName}`,
  };
}

export function makeGTOtherPropertyIfNotNullRule(
  currentPropertyName: string,
  otherPropertyName: string,
  getOtherProperty: () => number | null,
): Settings2ValidationRule<number> {
  return {
    predicate: (currentProperty: number) => {
      const otherProperty = getOtherProperty();
      return otherProperty === null || currentProperty > otherProperty;
    },
    warning: `${currentPropertyName} must be greater than ${otherPropertyName}`,
  };
}

export function outletPropertyGetter<T>(
  context: CoreContext,
  uid: string,
  index: number,
  property: KeysMatching<HotWaterOutlet, T>,
) {
  return () => {
    const obj = context.globalStore.get<CorePlant>(uid);
    const plant = obj.entity.plant as ReturnSystemPlant;
    const currentOutlet = plant.outlets[index];
    return currentOutlet[property];
  };
}
