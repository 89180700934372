import { TRACKER } from "../../../lib/analytics";
import { Color } from "../../../lib/color";
import { Units, UnitsContext } from "../../../lib/measurements";
import { Settings2ValidationRule } from "../../../lib/settings-2-validation-predicate";
import { Choice } from "../../../lib/utils";
import { createEntryWithUnit } from "../../calculations/heatloss/heat-loss-result-type";
import { CoreContext } from "../../calculations/types";
import { FittingReference } from "../../coreObjects/coreFitting";
import { getEntityAnalyticProperties } from "../analytics/utils";
import { UnitsParameters } from "../drawing";
import { FlowSystem } from "../flow-systems";
import { DrawableEntityConcrete } from "./concrete-entity";
import { FittingModelLevelsView } from "./fitting-entity";
import { EntityType } from "./types";

export enum PropertyCategory {
  Room = "room",
}

export enum FieldType {
  Text = "text",
  TextArea = "textarea",
  Number = "number",
  Color = "color",
  Choice = "choice",
  FlowSystemChoice = "flow-system-choice",
  Rotation = "rotation",
  Boolean = "boolean",
  TwoPointScale = "two-point-scale",
  Title = "title",
  Image = "advert",
  Button = "button",
  Tabs = "tabs",
  Divider = "divider",
  SettingNumberFieldBind = "setting-number-bind",
  ChoiceSelect = "choice-select",
  FittingModel = "fittings-model",
  EntityPicker = "entity-picker",
  DisplayTextOnly = "display-text-only",

  // Customs must be overridden with a vue template, like in RiserProperties.vue
  Custom = "custom",
}

export interface InputParams extends FieldParams {
  min: number | null;
  max: number | null;
}
export interface BoundedInputSteppedParams extends FieldParams {
  min: number;
  max: number;
  noslider: true;
}

export interface FluidSliderParams extends FieldParams {
  min: number;
  max: number;
}

export interface SliderParams extends FieldParams {
  min: number | null;
  max: number | null;
  step: number;
}
export interface RangedSliderParams extends FieldParams {
  min: number | null;
  max: number | null;
  step: number;
  displayMin: number;
  displayMax: number;
}

export type NumberParams =
  | InputParams
  | FluidSliderParams
  | BoundedInputSteppedParams
  | SliderParams
  | RangedSliderParams;

export interface TextAreaParams extends FieldParams {
  rows: number;
}

export interface ColorParams extends FieldParams {
  palette?: string[];
}

export interface ChoiceParams extends FieldParams {
  choices: Choice[];
}

export interface FlowSystemChoiceParams extends FieldParams {
  systems: FlowSystem[];
  disabledSystems?: FlowSystem["uid"][];
}

export interface FieldParams {
  initialValue?: any;
}

export type CustomSetFn = (
  newValue: any,
  args: { defaultSet: (val: any) => void },
) => void;

export interface PropertyFieldBase<TValue = any> {
  // TODO: drop any in the template argument
  type: FieldType;
  property: string;
  settingPropertyParam?: SettingParamBase<TValue>;
  title: string;
  multiFieldId: string | null;
  hasDefault: boolean;
  isCalculated: boolean;
  hint?: string;
  highlightOnOverride?: Color;
  hideFromPropertyWindow?: boolean;
  requiresInput?: boolean;
  readonly?: boolean;
  units?: Units;
  rawUnits?: string; // used when you want to display units without converting based on locale
  description?: string;
  slot?: boolean;
  slotButton?: boolean;

  beforeSet?: (newValue: any) => TValue | undefined;
  afterSet?: (newValue: TValue) => void;
  defaultValue?: () => TValue;

  // If nothing, assume to be None
  unitContext?: UnitsContext;

  // Note: Even if these validation rules fail, the value will still be set.
  // An error will still prevent calculations from being run.
  // TODO: Ensure all fields in PropretiesFieldBuilder display the validation rules.
  // The failed validation rules are only displayed in the property window.
  validationRules?: Settings2ValidationRule<number>[];
}

export interface NumberField
  extends PropertyFieldBase<number | undefined | null> {
  type: FieldType.Number;
  params: NumberParams;
}

export interface FlowSystemChoiceField extends PropertyFieldBase {
  type: FieldType.FlowSystemChoice;
  params: FlowSystemChoiceParams;
}

export interface ChoiceField extends PropertyFieldBase {
  type: FieldType.Choice;
  params: ChoiceParams;
}

export interface TextAreaField extends PropertyFieldBase {
  type: FieldType.TextArea;
  params: TextAreaParams;
}

export interface ColorField extends PropertyFieldBase {
  type: FieldType.Color;
  params: ColorParams | null;
}

export interface RotationField extends PropertyFieldBase {
  type: FieldType.Rotation;
  params: {
    step: number;
    disableFreeInput: boolean;
  };
}

export interface TextField extends PropertyFieldBase {
  type: FieldType.Text;
  params: FieldParams | null;
}

export interface BooleanField extends PropertyFieldBase {
  type: FieldType.Boolean;
  params: null;
}

export interface TitleField extends PropertyFieldBase {
  type: FieldType.Title;
  hasDefault: false;
  isCalculated: false;
  params: null;
}
export interface AdvertField extends PropertyFieldBase {
  type: FieldType.Image;
  params: {
    url: string | null;
    titleHtml: string;
    subtitleHtml: string;
    imagePath: string;
  };
}

export interface CustomField extends PropertyFieldBase {
  type: FieldType.Custom;
  params: {};
}

export interface FittingModelField extends PropertyFieldBase {
  type: FieldType.FittingModel;
  params: {
    reference: FittingReference;
    levelsView: FittingModelLevelsView;
  };
}

export interface EntityEntry {
  name: string;
  uid: string;
  filled: DrawableEntityConcrete;
  color: string | null;
}

export interface EntityPickerField extends PropertyFieldBase {
  type: FieldType.EntityPicker;
  params: {
    type: "single" | "multiple";
    levelUid: string | null;
    entityTypes: EntityType[];
    emptyListPlaceholder?: string;
    // These are too hard to type cohere with entityTypes, don't try.
    filter: (entity: DrawableEntityConcrete) => boolean;
    getOptionName?: (entity: DrawableEntityConcrete) => string;
    getLabelName?: (entity: DrawableEntityConcrete) => string;
    getColor?: (entity: DrawableEntityConcrete) => string | null;
    beforeSet?: (value: EntityEntry | EntityEntry[] | null) => void;
  };
}
export interface DisplayTextOnlyField extends PropertyFieldBase {
  type: FieldType.DisplayTextOnly;
  params: { text: string };
}

// Assume select choice's property always lead to
// PropertyObject: {
//   [key: string]: PropertyObjectEntry
// }
// Naming of below that used in arrow function in select choice param indicate those
export interface PropertyObjectEntry {
  [key: string]: any;
}
export type PropertyObject = {
  [key: string]: PropertyObjectEntry;
};

export interface BaseChoiceSelectTableEntry {
  title: string;
  displayValue: (
    propertyObjectEntry: PropertyObjectEntry,
    entry: ChoiceSelectTableEntry,
  ) => string;
  displayUnit?: () => string;
  editable: boolean;
  units: Units;
  unitsContext?: UnitsContext;
}

export interface EditableChoiceSelectTableEntry
  extends BaseChoiceSelectTableEntry {
  editable: true;
  input: (
    events: Event,
    propertyObject: PropertyObjectEntry,
    entry: ChoiceSelectTableEntry,
  ) => void;
}

export interface NonEditableChoiceSelectTableEntry
  extends BaseChoiceSelectTableEntry {
  editable: false;
  input?: never;
}

export type ChoiceSelectTableEntry =
  | EditableChoiceSelectTableEntry
  | NonEditableChoiceSelectTableEntry;

export interface ChoiceSelectChoiceEntry {
  name: string;
  value: number;
  units: Units;
  unitsContext?: UnitsContext;
}

/**
 * Current use case of this field
 * - A list of choice consist of name, value (with/without unit)
 *
 * Then user pick several choices of above,
 * displayed as a table.
 *     [Name]   [Quantity]    [Unit]
 *
 * Result:
 * [Title] => Multiple quantity with unit
 */
export function defaultDisplayEntry(
  units: UnitsParameters,
  entry: ChoiceSelectChoiceEntry,
) {
  let unitEntry = createEntryWithUnit(
    units,
    entry.value,
    entry.units,
    2,
    entry.unitsContext || UnitsContext.NONE,
  );
  return `${entry.name} ${unitEntry}`;
}

export interface ChoiceSelectField extends PropertyFieldBase {
  type: FieldType.ChoiceSelect;
  params: {
    choiceParams: {
      title: string;
      choices: ChoiceSelectChoiceEntry[];
      newChoiceEntry: (key: string) => any;
      displayEntry?: (
        units: UnitsParameters,
        entry: ChoiceSelectChoiceEntry,
      ) => string;
    };
    tableParams: {
      title: string;

      // Currently default to be three columns, not customizable
      name: ChoiceSelectTableEntry;
      quantity: ChoiceSelectTableEntry;
      unit: ChoiceSelectTableEntry;
    };
    resultParams: {
      title: string;
      displaySum: (propertyObject: PropertyObject) => string;
      // Display the sum of quantity * unit
      // Currently not customizable
    };
  };
}

/**
 * A second template argument TInput may be needed for cases when the return type of
 * fetchValue differs from the argument type of updateValue.
 * E.g. fetchValue: () => Choice[]; updateValue: (choiceKey: Choice["key"]) => void;
 */
export interface SettingParamBase<TValue> {
  fetchValue?: () => TValue;
  updateValue?: CustomSetFn;
  confirmMessage?: (value: any) => {
    title: string;
    message: string;
  };
  validateValue?: (value: any) => boolean;
}

export interface SettingNumberParam
  extends SettingParamBase<number | undefined | null> {
  entity: any;
  min: number;
  max: number;
}

export interface SettingNumberFieldBindField extends PropertyFieldBase {
  type: FieldType.SettingNumberFieldBind;
  settingPropertyParam: SettingNumberParam;
}

export interface ButtonField extends PropertyFieldBase {
  type: FieldType.Button;
  size?: "sm" | "lg";
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "outline-primary"
    | "outline-secondary"
    | "outline-success"
    | "outline-danger"
    | "outline-warning"
    | "outline-info"
    | "outline-light"
    | "outline-dark";
  pill?: boolean;
  params: {
    handler: () => Promise<void>;
  };
}

export interface DividerField extends PropertyFieldBase {
  type: FieldType.Divider;
}

export interface TabField {
  tabId: string;
  tabName: string;
  fields: PropertyField[];
  isPrerelease?: boolean;
  hidden?: boolean;
}

export interface TabsField {
  type: FieldType.Tabs;
  id: string;
  tabs: TabField[];
}

export type FlatPropertyFields =
  | NumberField
  | ChoiceField
  | TextAreaField
  | FlowSystemChoiceField
  | ColorField
  | RotationField
  | TextField
  | BooleanField
  | TitleField
  | AdvertField
  | ButtonField
  | DividerField
  | SettingNumberFieldBindField
  | ChoiceSelectField
  | FittingModelField
  | EntityPickerField
  | DisplayTextOnlyField
  | CustomField;

export type ValuePropertyFields =
  | NumberField
  | ChoiceField
  | TextAreaField
  | FlowSystemChoiceField
  | ColorField
  | RotationField
  | TextField
  | BooleanField
  | TitleField
  | AdvertField
  | ButtonField
  | CustomField;

export type PropertyField = FlatPropertyFields | TabsField;

export function isFlatPropertyField(
  field: PropertyField,
): field is FlatPropertyFields {
  return field.type !== FieldType.Tabs;
}

export function isValuePropertyField(
  field: PropertyField,
): field is ValuePropertyFields {
  return field.type !== FieldType.Tabs && field.type !== FieldType.Divider;
}

export function withPropertyTracking<T extends PropertyField>(
  ctx: CoreContext,
  entity: any,
): (field: T) => T {
  return (field: T) => {
    if (field.type === FieldType.Tabs) {
      return {
        ...field,
        tabs: field.tabs.map(
          (t) =>
            ({
              ...t,
              fields: t.fields.map(withPropertyTracking(ctx, entity)),
            }) as TabField,
        ),
      } as T;
    }

    if (!isFlatPropertyField(field)) {
      return field;
    }

    return {
      ...field,
      afterSet: (value: any) => {
        field.afterSet?.call(field, value);
        // This try/catch likely isnt needed, just being super cautious on the initial rollout
        try {
          const trackingValue = sanitizePropertyValueForTracking(field, value);
          TRACKER?.trackEvent({
            type: "Property Changed",
            props: {
              name: field.title,
              property: field.property,
              type: field.type,
              value: trackingValue,
              units: field.units ?? Units.None,
              unitContext: field.unitContext ?? UnitsContext.NONE,
              ...getEntityAnalyticProperties(ctx, entity),
            },
          });
        } catch (error) {
          console.warn("Error tracking property changes, skipping:", error);
        }
      },
    } as T;
  };
}

export function sanitizePropertyValueForTracking(
  field: PropertyField,
  value: any,
): string {
  const { type } = field;

  switch (type) {
    case FieldType.Color:
      return value.hex.toString();
    case FieldType.Rotation:
    case FieldType.Title:
    case FieldType.Image:
    case FieldType.Button:
    case FieldType.Tabs:
    case FieldType.Divider:
    case FieldType.SettingNumberFieldBind:
    case FieldType.ChoiceSelect:
    case FieldType.FittingModel:
    case FieldType.EntityPicker:
    case FieldType.DisplayTextOnly:
    case FieldType.Custom:
    case FieldType.Text:
    case FieldType.TextArea:
    case FieldType.Number:
    case FieldType.Boolean:
    case FieldType.Choice:
    case FieldType.FlowSystemChoice:
    default:
      if (typeof value === "number") {
        return value.toString();
      } else if (typeof value === "string") {
        // Sanity here, only take the first 100 characters for string values.
        // For things like text areas, this could be 1000s of characters.
        return value.length < 100
          ? value
          : value.slice(0, 100) + "...(Omitted)";
      } else if (typeof value === "boolean") {
        return value.toString();
      } else {
        console.warn(
          `Unsanitized Type for property tracking, please add rules and/or omit it from tracking: ${type}`,
        );
      }
  }
  return "";
}
