import { Color } from "../../lib/color";
import { Currency, CurrencySymbol } from "../../lib/currency";
import {
  AreaMeasurementSystem,
  FlowRateMeasurementSystem,
  GasEnergyMeasurementSystem,
  MeasurementSystem,
  MechanicalEnergyMeasurementSystem,
  PressureDropMeasurementSystem,
  PressureMeasurementSystem,
  RainfallMeasurementSystem,
  VelocityMeasurementSystem,
  VolumeMeasurementSystem,
  in2MM,
} from "../../lib/measurements";
import { Choice, DeepPartial, assertUnreachable } from "../../lib/utils";
import { FireNodeProps, MechanicalNodeProps } from "../../models/CustomEntity";
import { ReportingStatus } from "../../reporting/ReportingFilter";
import {
  EnergyPerformanceCertificateInformation,
  ExistingHeatingSystem,
} from "../calculations/heatloss/heat-loss-result-type";
import {
  AirChangeRateStandards,
  AirChangeRateValueSpec,
  DeprecatedAirChangeDevYear,
  DeprecatedTemperatureDevYear,
  HeatLoadItem,
  HeatLossMaterialSpec,
} from "../catalog/heatload/types";
import { PriceTable } from "../catalog/price-table";
import {
  ComponentPressureLossMethod,
  EN12056FrequencyFactor,
  RingMainCalculationMethod,
  StandardFlowSystemUids,
  SupportedDistirctHeating,
  SupportedDrainageMethods,
  SupportedDwellingStandards,
  SupportedGasCalculationMethods,
  SupportedPsdStandards,
} from "../config";
import { Lines } from "../linetypes/types";
import { SupportedLocales } from "../locale";
import { DrawableEntityConcrete } from "./entities/concrete-entity";
import { DoorType, WindowType } from "./entities/fenestration-entity";
import { VentilationNode } from "./entities/load-node-entity";
import RiserEntity from "./entities/riser-entity";
import { RoofSpec, RoofType } from "./entities/rooms/roof-type";
import {
  DrainageProperties,
  FlowSystem,
  isDuctFlowSystem,
  isPipeFlowSystem,
} from "./flow-systems";
import { FlowSystemsMetadata } from "./flow-systems/flow-systems-metadata";
import { MetaPDFData } from "./meta-pdf-types";

export const AHU_HEIGHT_ABOVE_FLOOR_MM_US = in2MM(40);

export interface Level {
  entities: { [key: string]: DrawableEntityConcrete };
  floorHeightM: number;
  name: string;
  abbreviation: string;
  uid: string;
}

export type Workflows = {
  heat_gain: {
    enabled: boolean;
  };
  heat_loss: {
    enabled: boolean;
  };
  mech_heating: {
    enabled: boolean;
  };
  mech_ventilation: {
    enabled: boolean;
  };
  mech_underfloor_heating: {
    enabled: boolean;
  };
  mech_chilled: {
    enabled: boolean;
  };
  plumbing_water: {
    enabled: boolean;
  };
  plumbing_gas: {
    enabled: boolean;
  };
  plumbing_wastewater: {
    enabled: boolean;
  };
  plumbing_stormwater: {
    enabled: boolean;
  };
  fire_sprinklers: {
    enabled: boolean;
  };
  fire_hydrants: {
    enabled: boolean;
  };
  fire_hosereels: {
    enabled: boolean;
  };
};
export type Workflow = keyof Workflows;

export type DrawingStateMetadata = {
  generalInfo: GeneralInfo;
  units: UnitsParameters;
  flowSystems: FlowSystemsMetadata;
  flowSystemUidsInOrder: Array<StandardFlowSystemUids | string>;
  calculationParams: CalculationParameters;
  availableFixtures: string[];
  nodes: {
    mechanical: MechanicalNodeProps[];
    fire: FireNodeProps[];
    // TODO: migrate custom nodes to here instead of stored in database.
    // Actual proper TODO: migrate custom nodes to organization-level store.
  };
  catalog: MetadataCatalog;
  heatLoss: HeatLoss;
  priceTable: DeepPartial<PriceTable>;
  lines: Lines;
  annotations: Annotations;
  roomResultsSettings: RoomResultsSettings;
  // stores the marketplace item uids that have been added to this drawing
  marketplaceItems: string[];
  workflows: Workflows;
  metaPdf: MetaPDFData;
};

/**
 * A drawing is a snapshot of a drawing - its shapes, pipes, fixtures, entities, title, etc, as is.
 */
export interface DrawingState {
  metadata: DrawingStateMetadata;
  levels: { [key: string]: Level };
  shared: { [key: string]: RiserEntity };
}

export interface CurrencySystem {
  symbol: CurrencySymbol;
  multiplierPct: number;
}
export interface UnitsParameters {
  lengthMeasurementSystem: MeasurementSystem;
  pressureMeasurementSystem: PressureMeasurementSystem;
  ventilationPressureMeasurementSystem: PressureMeasurementSystem;
  velocityMeasurementSystem: VelocityMeasurementSystem;
  temperatureMeasurementSystem: MeasurementSystem;
  volumeMeasurementSystem: VolumeMeasurementSystem;
  gasEnergyMeasurementSystem: GasEnergyMeasurementSystem;
  mechanicalEnergyMeasurementSystem: MechanicalEnergyMeasurementSystem;
  pressureDropMeasurementSystem: PressureDropMeasurementSystem;
  rainfallMeasurementSystem: RainfallMeasurementSystem;
  areaMeasurementSystem: AreaMeasurementSystem;
  flowRateMeasurementSystem: FlowRateMeasurementSystem;
  ventilationFlowRateMeasurementSystem: FlowRateMeasurementSystem;
  currency: CurrencySystem;
}

export interface RoomResultsSettings extends Record<string, boolean> {
  roomName: boolean;
  roomTemperature: boolean;
  roomHeight: boolean;
  roomArea: boolean;
  roomVolume: boolean;
  roomPerimeter: boolean;
  heatLoss: boolean;
  heatSupplied: boolean;
  heatLoadPerArea: boolean;
  heatGain: boolean;
  coolingSupplied: boolean;
  ventAirChanges: boolean;
  heatingAirChanges: boolean;
  underfloorHeating: boolean;
  unheatedArea: boolean;
}

export const ROOM_RESULTS_SETTINGS_CHOICES: Choice<
  keyof RoomResultsSettings
>[] = [
  { name: "Room Name", key: "roomName" },
  { name: "Room Temperature", key: "roomTemperature" },
  { name: "Room Height", key: "roomHeight" },
  { name: "Room Area", key: "roomArea" },
  { name: "Room Volume", key: "roomVolume" },
  { name: "Room Perimeter", key: "roomPerimeter" },
  { name: "Ventilation Air Changes", key: "ventAirChanges" },
  { name: "Heating Air Changes", key: "heatingAirChanges" },
  { name: "Heat Loss", key: "heatLoss" },
  { name: "Heat Supplied", key: "heatSupplied" },
  { name: "Heat Gain", key: "heatGain" },
  { name: "Cooling Supplied", key: "coolingSupplied" },
  { name: "Heat Gain/Loss by Area ", key: "heatLoadPerArea" },
  { name: "Underfloor Heating", key: "underfloorHeating" },
  { name: "Unheated Area", key: "unheatedArea" },
];

export const LENGTH_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (mm)", key: MeasurementSystem.METRIC },
  { name: "Imperial (in, ft)", key: MeasurementSystem.IMPERIAL },
];

export const PRESSURE_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (kPa)", key: PressureMeasurementSystem.METRIC },
  { name: "Metric - Small (Pa)", key: PressureMeasurementSystem.METRIC_SMALL },
  { name: "Imperial (psi)", key: PressureMeasurementSystem.IMPERIAL },
  { name: "UK (bar)", key: PressureMeasurementSystem.UK },
];

export const TEMPERATURE_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (\u00B0C)", key: MeasurementSystem.METRIC },
  { name: "Imperial (\u00B0F)", key: MeasurementSystem.IMPERIAL },
];

export const VELOCITY_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (m/s)", key: VelocityMeasurementSystem.METRIC },
  { name: "Imperial (ft/s)", key: VelocityMeasurementSystem.IMPERIAL },
];

export const VOLUME_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (L)", key: VolumeMeasurementSystem.METRIC },
  { name: "UK Imperial (L)", key: VolumeMeasurementSystem.IMPERIAL },
  { name: "US Imperial (US gal)", key: VolumeMeasurementSystem.US },
];

export const GAS_ENERGY_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Megajoules (mj)", key: GasEnergyMeasurementSystem.METRIC },
  { name: "Therms (Btu)", key: GasEnergyMeasurementSystem.IMPERIAL },
  { name: "Kilowatts (Kw)", key: GasEnergyMeasurementSystem.UNIVERSAL },
];

export const MECHANICAL_ENERGY_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Therms (Btu)", key: MechanicalEnergyMeasurementSystem.IMPERIAL },
  { name: "Kilowatts (Kw)", key: MechanicalEnergyMeasurementSystem.UNIVERSAL },
];

export const PRESSURE_DROP_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (kPa/m)", key: PressureMeasurementSystem.METRIC },
  {
    name: "Metric - Small (Pa/m)",
    key: PressureMeasurementSystem.METRIC_SMALL,
  },
  { name: "Imperial (psi/100ft)", key: PressureMeasurementSystem.IMPERIAL },
  { name: "UK (Pa/m)", key: PressureMeasurementSystem.UK },
];

export const FLOW_RATE_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (L/s)", key: FlowRateMeasurementSystem.METRIC },
  {
    name: "Metric (L/min)",
    key: FlowRateMeasurementSystem.METRIC_MIN,
  },
  {
    name: "Imperial (ft³/min)",
    key: FlowRateMeasurementSystem.IMPERIAL_CFM,
  },
  { name: "UK Imperial (gal/min)", key: FlowRateMeasurementSystem.IMPERIAL },
  { name: "US Imperial(US gal/min)", key: FlowRateMeasurementSystem.US },
];

export const AIR_CHANGE_RATE_STANDARD_CHOICES: Choice<AirChangeRateStandards>[] =
  [
    { name: "CIBSE 2016", key: "CIBSE2016" },
    { name: "MCS Pre 2000", key: "MCSPre2000" },
    { name: "MCS Post 2000", key: "MCSPost2000" },
    { name: "MCS Post 2006", key: "MCSPost2006" },
    {
      name: "UK Building Regs 2021 (Part F) Dwellings",
      key: "UKBuildingRegs2021Dwellings",
    },
    {
      name: "UK Building Regs 2021 (Part F) Non Dwellings",
      key: "UKBuildingRegs2021NonDwellings",
    },
    { name: "ASHRAE 62.1 2022", key: "ASHRAE62.1" },
    { name: "AS 1668.2-2012", key: "AS1668.2-2012" },
  ];

export function airChangeStandardToLabel(
  standard: AirChangeRateStandards,
): string {
  const s = AIR_CHANGE_RATE_STANDARD_CHOICES.find((e) => e.key === standard);
  return s?.name ?? "";
}

export type ProjectAddress = {
  formattedAddress: string;
  latitude: number | null;
  longitude: number | null;
  country: string;
};

export interface GeneralInfo {
  title: string;
  projectNumber: string;
  projectStage: string;
  designer: string;
  reviewed: string;
  approved: string;
  revision: number;
  client: string;
  description: string;
  reportingStatus?: ReportingStatus;
  projectAddress: ProjectAddress;
}

export const UFH_CAPACITY_LKW = 23;
export const MANIFOLD_CAPACITY_LKW = 23;
export const FCU_CAPACITY_LKW = 11;

export const AHU_HEIGHT_ABOVE_FLOOR_MM = 1000;

export type PipeSizingMethod = "velocity" | "pressure" | "both";
export type DuctSizingMethod = "velocity" | "pressure" | "both";

export function isVelocitySizingEnabled(
  drawing: DrawingState,
  flowSystem: FlowSystem,
) {
  if (isPipeFlowSystem(flowSystem)) {
    switch (drawing.metadata.calculationParams.pipeSizingMethod) {
      case "velocity":
      case "both":
        return true;
      case "pressure":
        return false;
      default:
        assertUnreachable(drawing.metadata.calculationParams.pipeSizingMethod);
    }
  }

  if (isDuctFlowSystem(flowSystem)) {
    switch (drawing.metadata.calculationParams.ductSizingMethod) {
      case "velocity":
      case "both":
        return true;
      case "pressure":
        return false;
      default:
        assertUnreachable(drawing.metadata.calculationParams.ductSizingMethod);
    }
  }
  return false;
}

export function isPressureSizingEnabled(
  drawing: DrawingState,
  flowSystem: FlowSystem,
) {
  if (isPipeFlowSystem(flowSystem)) {
    switch (drawing.metadata.calculationParams.pipeSizingMethod) {
      case "velocity":
        return false;
      case "both":
      case "pressure":
        return true;
      default:
        assertUnreachable(drawing.metadata.calculationParams.pipeSizingMethod);
    }
  }

  if (isDuctFlowSystem(flowSystem)) {
    switch (drawing.metadata.calculationParams.ductSizingMethod) {
      case "velocity":
        return false;
      case "both":
      case "pressure":
        return true;
      default:
        assertUnreachable(drawing.metadata.calculationParams.ductSizingMethod);
    }
  }
  return false;
}

export interface CalculationParameters {
  psdMethod: SupportedPsdStandards;
  loadingUnitVariant: string;
  dwellingMethod: SupportedDwellingStandards | null;
  drainageMethod: SupportedDrainageMethods;
  drainageSystem:
    | "drainageSystem1"
    | "drainageSystem2"
    | "drainageSystem3"
    | "drainageSystem4";
  en12056FrequencyFactor: EN12056FrequencyFactor;
  ringMainCalculationMethod: RingMainCalculationMethod;
  ductSizingMethod: DuctSizingMethod;
  pipeSizingMethod: PipeSizingMethod;
  componentPressureLossMethod: ComponentPressureLossMethod;
  pipePressureLossAddOnPCT: number;

  roomTemperatureC: number;
  rainfallIntensityMm_H: number;
  windSpeedForHeatLossMS: number;
  apartmentNum: number;
  occupantNum: number;
  gravitationalAcceleration: number;
  combineLUs: boolean;
  districtHeating: SupportedDistirctHeating | null;
  gasCalcMethod: SupportedGasCalculationMethods | null;

  // Isochoric only exists for legacy reasons, there's no real reason to use this.
  // Which is why this is a global setting after all, just for old projects using
  // isochoric to easily switch to isobaric.
  // New projects have "null" defaulting to isobaric which will disallow the setting
  // to be changed.
  specificHeatMethod: "isobaric" | "isochoric" | null;
}

export type WindowSpec = {
  [WindowType.WINDOW]: {
    heightM: number;
    lengthM: number;
  };
  [WindowType.VELUX]: {
    widthM: number;
    lengthM: number;
  };
};

export type DoorSpec = {
  heightM: number;
  lengthM: {
    [key in DoorType]: number;
  };
};

export type WallSpec = {
  roomHeightM: number;
  internalWidthMM: number;
  externalWidthMM: number;
  partyWidthMM: number;
};

export type Cost = {
  value: number;
  currency: Currency;
};

export interface CustomRoomSpecV1 {
  name: string;
  attributes: {
    [key in DeprecatedTemperatureDevYear]: {
      defaultTemperatureC: number;
      airChangeRate: number;
    };
  };
}

export interface CustomRoomSpecV2 {
  name: string;
  defaultTemperatureC: Record<DeprecatedTemperatureDevYear, number>;
  airChangeRate: Record<DeprecatedAirChangeDevYear, number>;
}

export interface CustomRoomSpec {
  name: string;
  defaultTemperatureC: number;
  airChangeRate: AirChangeRateValueSpec[];
}

export interface SolarRadiation {
  top: number;
  bottom: number;
  left: number;
  right: number;
  leftBottom: number;
  leftTop: number;
  rightTop: number;
  rightBottom: number;
}

export interface HeatLoss {
  material: {
    [key in HeatLoadItem]: {
      [key in string]: SelectedMaterialManufacturer;
    };
  };
  defaultMaterial: {
    [key in HeatLoadItem]: string;
  };
  defaultColor: {
    [key in HeatLoadItem]: Color;
  };
  customMaterial: {
    [key in HeatLoadItem]: {
      [key in string]: HeatLossMaterialSpec;
    };
  };
  customRoom: {
    [key in string]: CustomRoomSpec;
  };
  roofSpec: RoofSpec;
  defaultRoofType: RoofType;
  doorSpec: DoorSpec;
  windowSpec: WindowSpec;
  wallSpec: WallSpec;
  ventAirChangesRateStandard: AirChangeRateStandards;
  heatingAirChangesRateStandard: AirChangeRateStandards;
  defaultRoomTemperatureC: number;
  externalWinterTemperatureC: number;
  groundTemperatureC: number;
  thermalBridgingCoefficient: number;
  ventHeatLossRecoveryPct: number; // 0 -> 100
  grilleFlowRateType: VentilationNode["flowRateType"];

  spareHeatLossPrecent: number; // typo
  spareHeatGainPrecent: number; // typo

  externalSummerTemperatureC: number;
  solarHeatGainCoefficient: number;
  solarRadiationWPerMin: SolarRadiation;

  internalHeatSource: InternalHeatSource;

  baseTemperatureC: number;
  heatingDegreeDays: number;
  coolingDegreeDays: number;
  winterTempCutoffPctThreshold: number;
  summerTempCutoffPctThreshold: number;
  winterPartyTemperatureC: number;
  summerPartyTemperatureC: number;
  SCOP: number;

  roomsBelowTransparentPct: number;

  // Heat Pump System Performance Report
  energyPerformanceCertificateInformation: EnergyPerformanceCertificateInformation;
  existingHeatingSystem: ExistingHeatingSystem;

  electricityCostPerUnit: Cost;

  // Underfloor Heating settings
  // TODO: remove this.
}

export const DEFAULT_LEGIONELLA_PURGE_TEMPERATURE_C = 70;
export const DEFAULT_DIARY_DOMESTIC_WATER = 60;
export const DEFAULT_SPF = 4;

export type InternalHeatSourceEntry = {
  heatSourceName: string;
  heatSourceWatts: number;
};

export type InternalHeatSource = {
  [key in string]: InternalHeatSourceEntry;
};

export interface Annotations {
  fontSize: number;
}

export interface MetadataCatalog {
  pipes: SelectedPipeMaterialManufacturer[];
  backflowValves: SelectedMaterialManufacturer[];
  mixingValves: SelectedMaterialManufacturer[];
  prv: SelectedMaterialManufacturer[];
  balancingValves: SelectedMaterialManufacturer[];
  hotWaterPlant: SelectedMaterialManufacturer[];
  fixtures: SelectedMaterialManufacturer[];
  greaseInterceptorTrap?: SelectedMaterialManufacturer[];
  floorWaste: SelectedMaterialManufacturer[];
  inspectionOpening: SelectedMaterialManufacturer[];
  pump: SelectedMaterialManufacturer[];
  pumpTank: SelectedMaterialManufacturer[];
  heatEmitters: SelectedMaterialManufacturer[];
  underfloorHeating: SelectedMaterialManufacturer[];
  underfloorHeatingCoils: SelectedCoilMaterialManufacturer[];
  filters: SelectedMaterialManufacturer[];
  roPlant: SelectedMaterialManufacturer[];
  ducts: SelectedMaterialManufacturer[];
  [key: string]: SelectedMaterialManufacturer[] | undefined;
}

export interface SelectedMaterialManufacturer {
  uid: string;
  manufacturer: string;
  selected: string | null;
}

export interface SelectedCoilMaterialManufacturer
  extends SelectedMaterialManufacturer {
  excludedRollLenghts?: Partial<Record<number, boolean>>;
}

export interface SelectedPipeMaterialManufacturer
  extends SelectedMaterialManufacturer {
  excludedSizes?: Partial<Record<string, boolean>>;
}

export const initialStormwaterPropertiesV10: DrainageProperties = {
  ventColor: { hex: "#ff7755" },
  availablePipeSizesMM: [40, 50, 65, 80, 100, 125, 150, 225, 300, 375],
  horizontalPipeSizing: [
    { minUnits: 0, maxUnits: 7, sizeMM: 100, gradePCT: 1 },
    { minUnits: 7, maxUnits: 20, sizeMM: 150, gradePCT: 1 },
    { minUnits: 20, maxUnits: 50, sizeMM: 225, gradePCT: 1 },
    { minUnits: 50, maxUnits: 125, sizeMM: 300, gradePCT: 1 },
  ],
  // Placeholder, Won't use these
  maxUnventedCapacityWCs: {},
  maxUnventedLengthM: {},
  stackDedicatedVent: false,
  stackPipeSizing: [
    { minUnits: 0, maxUnits: 7, sizeMM: 100, maximumUnitsPerLevel: 0 },
    { minUnits: 7, maxUnits: 20, sizeMM: 150, maximumUnitsPerLevel: 0 },
    { minUnits: 20, maxUnits: 50, sizeMM: 225, maximumUnitsPerLevel: 0 },
    { minUnits: 50, maxUnits: 125, sizeMM: 300, maximumUnitsPerLevel: 0 },
  ],
  stackSizeDiminish: false,
  stackVentPipeSizing: [],
  ventSizing: [],
};

export type AddressData = {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
  route: string;
  street_number: string;
  newVal: string;
  oldVal: string;
};

export type PlaceResult = {
  formatted_address: string;
};

export function getAHUSupplyExtractHeightAboveFloorMM(
  locale: SupportedLocales,
) {
  switch (locale) {
    case SupportedLocales.AU:
    case SupportedLocales.UK:
      return AHU_HEIGHT_ABOVE_FLOOR_MM;
    case SupportedLocales.US:
      return AHU_HEIGHT_ABOVE_FLOOR_MM_US;
  }
  assertUnreachable(locale);
}

export function getAHUHeatingChilledHeightAboveFloorMM(
  locale: SupportedLocales,
) {
  switch (locale) {
    case SupportedLocales.AU:
    case SupportedLocales.UK:
      return 2400;
    case SupportedLocales.US:
      return AHU_HEIGHT_ABOVE_FLOOR_MM_US;
  }
  assertUnreachable(locale);
}
