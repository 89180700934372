import { countBy, groupBy } from "lodash";
import * as TM from "transformation-matrix";
import { CoreObjectConcrete } from ".";
import { Coord } from "../../lib/coord";
import { getAreaM2 } from "../../lib/mathUtils/mathutils";
import { collectRecord } from "../../lib/record-utils";
import { assertType, cloneNaive } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
  UnderFloorHeatingCoilBreakdown,
  UnderfloorHeatingActuatorBreakdown,
  UnderfloorHeatingEdgeExpansionFoamBreakdown,
} from "../calculations/types";
import { getEffectiveHeatLoad } from "../calculations/utils";
import { getManufacturerRecord } from "../catalog/manufacturers/utils";
import { Catalog } from "../catalog/types";
import { ActuatorModel } from "../catalog/underfloor-heating/ufh-types";
import {
  CoilMapping,
  RoomCalculation,
  RoomLiveCalculation,
  UnderfloorHeatingCalc,
} from "../document/calculations-objects/room-calculation";
import { UnderfloorHeatingLoopCalculation } from "../document/calculations-objects/underfloor-heating-loop-calculation";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import { ManifoldPlant } from "../document/entities/plants/plant-types";
import { isManifoldPlantEntity } from "../document/entities/plants/utils";
import {
  RoomEntity,
  RoomRoomEntity,
  RoomType,
  fillDefaultRoomFields,
  isRoomRoomEntity,
} from "../document/entities/rooms/room-entity";
import {
  getAvailableCoilLengths,
  getLoopCoilSpec,
  getUnderfloorHeatingEdgeExpansionFoam,
  getUnderfloorHeatingEdgeExpansionFoams,
} from "../document/entities/rooms/utils";
import { EntityType } from "../document/entities/types";
import { CorePolygon } from "./core-traits/corePolygon";
import CoreEdge from "./coreEdge";
import CoreFen from "./coreFenestration";
import CorePlant from "./corePlant";
import CoreVertex from "./coreVertex";
import CoreWall from "./coreWall";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";

const Base = CorePolygon(CoreCalculatableObject<RoomEntity>);

export default class CoreRoom extends Base {
  type: EntityType.ROOM = EntityType.ROOM;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.room.roomType}`;
  }

  get filledEntity(): RoomEntity {
    return fillDefaultRoomFields(this.context, this.entity);
  }

  // @ts-ignore 2611
  get position() {
    const scale = 1 / this.fromParentToWorldLength(1);
    return TM.transform(
      TM.translate(this.entity.virtualCenter.x, this.entity.virtualCenter.y),
      TM.scale(scale, scale),
    );
  }

  isRoomSpaceTypeValid() {
    const filled = fillDefaultRoomFields(this.context, this.entity);
    if (filled.room.roomType === RoomType.ROOM) {
      const spaceType = filled.room.spaceType;
      const heatingSpaceType = filled.room.heatingSpaceType;
      if (!spaceType || !heatingSpaceType) {
        return false;
      }

      const effective = getEffectiveHeatLoad(
        this.context.catalog,
        this.drawing,
      );
      if (!(spaceType in effective.ventAirChangeRate)) {
        return false;
      }
      if (!(heatingSpaceType in effective.heatingAirChangeRate)) {
        return false;
      }
    }

    return true;
  }

  getCoreNeighbours(): CoreObjectConcrete[] {
    return this.entity.edgeUid
      .map((uid) => {
        return [
          this.context.globalStore.get(uid),
          ...this.context.globalStore.get(uid).getCoreNeighbours(),
        ];
      })
      .reduce((a, b) => a.concat(b), [])
      .concat(
        this.context.globalStore
          .getArcsByPolygon(this.entity.uid)
          .map((x) => this.context.globalStore.get(x)),
      );
  }

  collectWalls(): CoreWall[] {
    let edges: CoreWall[] = [];
    this.entity.edgeUid.forEach((edgeUid) => {
      this.globalStore.getWallsByRoomEdge(edgeUid).forEach((wallUid) => {
        let wall = this.globalStore.get<CoreWall>(wallUid);
        if (wall === undefined || wall.type !== EntityType.WALL) {
          throw new Error("Wall not found");
        }
        edges.push(wall);
      });
    });
    return edges;
  }

  collectFens(): CoreFen[] {
    let edges: CoreFen[] = [];
    this.entity.edgeUid.forEach((edgeUid) => {
      this.globalStore.getFensByRoomEdge(edgeUid).forEach((fenUid) => {
        let fen = this.globalStore.get<CoreFen>(fenUid);
        if (fen === undefined || fen.type !== EntityType.FENESTRATION) {
          throw new Error("fen not found");
        }
        edges.push(fen);
      });
    });
    return edges;
  }

  collectVisibleWalls(): CoreWall[] {
    return this.collectWalls().filter((wall) => wall.isManifested);
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }

  getCalculationEntities(context: CoreContext): RoomEntity[] {
    let res = cloneNaive(this.entity);
    res.uid = this.getCalculationUid(context);
    (res.edgeUid as any) = [];
    for (let uid of this.entity.edgeUid) {
      let edge = context.globalStore.get<CoreEdge>(uid);
      res.edgeUid.push(edge.getCalculationUid(context));
    }

    if (res.room.roomType === RoomType.ROOM) {
      if (res.room.underfloorHeating.manifoldUid) {
        const manifold = context.globalStore.get<CorePlant>(
          res.room.underfloorHeating.manifoldUid,
        );
        if (manifold) {
          res.room.underfloorHeating.manifoldUid =
            manifold.getCalculationUid(context);
        }
      }
    }

    return [res];
  }

  hasDetailedUnderfloorHeatingLoops(context: CoreContext) {
    if (this.entity.room.roomType !== RoomType.ROOM) {
      return false;
    }

    const manifoldUid = this.entity.room.underfloorHeating
      .manifoldUid as string;

    const manifold = context.globalStore.get<CorePlant>(manifoldUid);
    if (!manifold) {
      return false;
    }
    assertType<ManifoldPlant>(manifold.entity.plant);

    if (manifold.entity.plant.ufhMode !== "full") {
      return false;
    }

    return true;
  }

  collectCalculations(context: CoreContext): RoomCalculation {
    let ret = context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
    ret.totalHeatLossAddressedWATT = ret.totalHeatLossAddressedWATT ?? 0;
    ret.totalHeatGainAddressedWATT = ret.totalHeatGainAddressedWATT ?? 0;
    return ret;
  }

  collectLiveCalculations(context: CoreContext): RoomLiveCalculation {
    const standardCalculations = this.collectCalculations(context);
    const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );

    liveCalcs.totalHeatGainWatt = standardCalculations.totalHeatGainWatt;
    liveCalcs.totalHeatLossWatt = standardCalculations.totalHeatLossWatt;
    liveCalcs.totalHeatLossAddressedWATT =
      standardCalculations.totalHeatLossAddressedWATT ?? 0;
    liveCalcs.totalHeatGainAddressedWATT =
      standardCalculations.totalHeatGainAddressedWATT ?? 0;
    liveCalcs.ventAirChangeRatePerHour =
      standardCalculations.ventAirChangeRatePerHour;
    liveCalcs.heatingAirChangeRatePerHour =
      standardCalculations.heatingAirChangeRatePerHour;
    liveCalcs.underfloorHeating = standardCalculations.underfloorHeating;
    liveCalcs.buildingHeatLoadInfo = standardCalculations.buildingHeatLoadInfo;
    liveCalcs.floorHeadLoadInfo = standardCalculations.floorHeadLoadInfo;
    liveCalcs.zoneVentFlowRateLS = standardCalculations.zoneVentFlowRateLS;
    liveCalcs.zoneExtractVentFlowRateAddressedLS =
      standardCalculations.zoneExtractVentFlowRateAddressedLS;
    liveCalcs.zoneSupplyVentFlowRateAddressedLS =
      standardCalculations.zoneSupplyVentFlowRateAddressedLS;

    liveCalcs.reference = standardCalculations.reference
      ? standardCalculations.reference
      : null;
    liveCalcs.ventilationFlowRateLS =
      standardCalculations.ventilationFlowRateLS;
    liveCalcs.heatingFlowRateLS = standardCalculations.heatingFlowRateLS;
    liveCalcs.isZoneLeader = standardCalculations.isZoneLeader;
    liveCalcs.isLeaderRoomPerFloor = standardCalculations.isLeaderRoomPerFloor;
    liveCalcs.volumeM3 = standardCalculations.volumeM3;
    liveCalcs.floorType = standardCalculations.floorType;
    liveCalcs.debug = standardCalculations.debug;
    return liveCalcs;
  }

  private getEdgeExpansionFoamBreakdowns(
    filled: RoomRoomEntity,
  ): UnderfloorHeatingEdgeExpansionFoamBreakdown[] {
    const edgeExpansionFoamManufacturer =
      filled.room.underfloorHeating.edgeExpansionFoamManufacturer;
    const edgeExpansionFoamModel =
      filled.room.underfloorHeating.edgeExpansionFoamModel;

    if (!edgeExpansionFoamManufacturer || !edgeExpansionFoamModel) {
      return [];
    }

    const componentId: [string, string] = [
      edgeExpansionFoamManufacturer,
      edgeExpansionFoamModel,
    ];

    const edgeExpansionFoam = getUnderfloorHeatingEdgeExpansionFoam(
      this.context.catalog,
      componentId,
    );

    // Shouldn't happen in real projects
    if (!edgeExpansionFoam.model) {
      console.log(
        edgeExpansionFoamManufacturer,
        edgeExpansionFoamModel,
        "not found in catalog",
        edgeExpansionFoam,
        getUnderfloorHeatingEdgeExpansionFoams(this.context.catalog),
      );
      return [];
    }

    return [
      {
        type: "ufh-edge-expansion-foam",
        path: "Equipment.Underfloor Edge Expansion Foam",
        description: edgeExpansionFoam.model.description,
        productKey: edgeExpansionFoam.model.productKey,
        manufacturer: edgeExpansionFoam.manufacturer.name,
        // Note: This is a lie and has to be recalculated after all rooms have been processed
        qty: 1,
        rollLengthM: edgeExpansionFoam.model.rollLengthM,
        perimeterLengthM: this.perimeterM,
      } satisfies UnderfloorHeatingEdgeExpansionFoamBreakdown,
    ];
  }

  private getCoilBreakdowns(
    underfloorCalc: UnderfloorHeatingCalc,
  ): UnderFloorHeatingCoilBreakdown[] {
    const { coils } = underfloorCalc;
    const grouped = groupBy(
      coils,
      (l) =>
        `${l.loopsCovered[0].coilManufacturer} ${l.loopsCovered[0].pipeMaterial}`,
    );

    return collectRecord(grouped, ([_key, coilsForMaterial]) => {
      return this.getCoilBreakdownForMaterial(coilsForMaterial);
    }).flat();
  }

  private getCoilBreakdownForMaterial(
    coilsForMaterial: CoilMapping[],
  ): UnderFloorHeatingCoilBreakdown[] {
    const countByLength = countBy(
      coilsForMaterial,
      (mapping) => mapping.coilLengthM,
    );

    const firstCoil = coilsForMaterial[0].loopsCovered[0];
    return collectRecord(countByLength, ([length, count]) =>
      this.coilBreakDownForLength(firstCoil, Number(length), count),
    );
  }

  private coilBreakDownForLength(
    firstCoil: UnderfloorHeatingLoopCalculation,
    rollLength: number,
    count: number,
  ): UnderFloorHeatingCoilBreakdown | null {
    const coil = getLoopCoilSpec(firstCoil, this.context.catalog, rollLength);

    if (!coil) {
      console.warn(
        `Missing coil in catalog for room`,
        this.context.catalog,
        getAvailableCoilLengths(firstCoil, this.context.catalog),
        rollLength,
        firstCoil.coilManufacturer,
        firstCoil.pipeDiameterMM,
        firstCoil.pipeMaterial,
      );
      return null;
    }

    // Note: For generic, rollLength/pipeSize will be null
    return {
      type: "ufh-coil",
      path: "Equipment.Underfloor Heating Coils",
      lengthM: coil.model.rollLengthM ?? rollLength,
      productKey: coil.model.productKey,
      manufacturer: coil.manufacturer.name,
      model: coil.model.model,
      description: coil.model.description,
      pipeMaterial: coil.pipeMaterial.name,
      pipeSizeMM: coil.model.pipeSizeMM ?? firstCoil.pipeDiameterMM,
      qty: count,
    };
  }
  private getActuatorBreakdown(
    catalog: Catalog,
    ufhCalcs: UnderfloorHeatingCalc,
  ): UnderfloorHeatingActuatorBreakdown[] {
    if (!this.context.featureAccess.fullUnderfloorHeatingLoops) return [];
    if (!ufhCalcs.manifoldUid) return [];

    const manifold = this.context.globalStore.get<CorePlant>(
      ufhCalcs.manifoldUid,
    );
    const filled = fillPlantDefaults(this.context, manifold.entity);

    if (
      !isManifoldPlantEntity(manifold.entity) ||
      !isManifoldPlantEntity(filled)
    ) {
      return [];
    }

    if (manifold.entity.plant.ufhMode !== "full") return [];

    const calc = this.globalStore.getOrCreateCalculation(filled);

    const model =
      manifold.entity.plant.actuatorModel ??
      calc.manifoldManufacturers.actuatorModel;
    const manufacturer = getManufacturerRecord(
      filled,
      this.context.catalog,
      "actuator",
    );

    let actuatorModel: ActuatorModel | null = null;

    if (model && filled.plant.actuatorManufacturer) {
      actuatorModel =
        catalog.underfloorHeating.actuator.datasheet[
          filled.plant.actuatorManufacturer
        ][model];
    }

    const qty = ufhCalcs.loopsStats.reduce(
      (qty, loop) => qty + (loop.hasActuator ? 1 : 0),
      0,
    );

    return [
      {
        type: "ufh-actuator",
        path: "Equipment.Underfloor Heating Actuators",
        qty,
        ...(model ? { model } : {}),
        ...(manufacturer ? { manufacturer: manufacturer.name } : {}),
        ...(actuatorModel ? { productKey: actuatorModel.productKey } : {}),
        ...(actuatorModel ? { description: actuatorModel.description } : {}),
        ...(calc.manifoldManufacturers.manifoldModel
          ? { manifoldModel: calc.manifoldManufacturers.manifoldModel }
          : {}),
        parentUid: ufhCalcs.manifoldUid,
      },
    ];
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    if (this.entity.room.roomType === RoomType.ROOM) {
      const manifoldUid = this.entity.room.underfloorHeating.manifoldUid;

      if (manifoldUid) {
        const manifold = context.globalStore.get(manifoldUid);

        if (manifold) {
          const filled = fillDefaultRoomFields(context, this.entity);
          if (!isRoomRoomEntity(filled)) return null;
          const calc = context.globalStore.getOrCreateCalculation(this.entity);

          return {
            cost: context.priceTable.Equipment["Room Underfloor Heating"],
            breakdown: [
              {
                type: "room-ufh",
                areaM2: Number(calc.areaM2?.toFixed(2)) ?? 0,
                qty: Number(calc.areaM2?.toFixed(2)) ?? 0,
                path: "Equipment.Room Underfloor Heating",
                roomName: filled.entityName!,
              },
              ...this.getCoilBreakdowns(calc.underfloorHeating),
              ...this.getActuatorBreakdown(
                this.context.catalog,
                calc.underfloorHeating,
              ),
              ...this.getEdgeExpansionFoamBreakdowns(filled),
            ],
          };
        }
      }
    }

    return null;
  }

  getRoomAreaM2(): number {
    const polygon: Coord[] = this.collectVerticesInOrder().map(
      (v: CoreVertex) => v.toWorldCoord(),
    );
    return getAreaM2(polygon);
  }
}
