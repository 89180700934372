import * as TM from "transformation-matrix";
import uuid from "uuid";
import {
  isHeatingPlantSystem,
  isUnderfloor,
  StandardFlowSystemUids,
} from "../../../../common/src/api/config";
import CorePlant from "../../../../common/src/api/coreObjects/corePlant";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import { isLiveWarningVisible } from "../../../../common/src/api/document/calculations-objects/warnings";
import BigValveEntity, {
  FlowConfiguration,
} from "../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { Coord, coordDist2 } from "../../../../common/src/lib/coord";

import DirectedValveEntity, {
  createBareValveEntity,
} from "../../../../common/src/api/document/entities/directed-valves/directed-valve-entity";

import Vue from "vue";
import {
  getManufacturerRecord,
  radiatorDimension,
  radiatorModelNameShortener,
} from "../../../../common/src/api/catalog/manufacturers/utils";
import { Manufacturer } from "../../../../common/src/api/catalog/types";
import ConduitEntity from "../../../../common/src/api/document/entities/conduit-entity";
import { ValveType } from "../../../../common/src/api/document/entities/directed-valves/valve-types";
import { MultiwayValveEntity } from "../../../../common/src/api/document/entities/multiway-valves/multiway-valve-entity";
import {
  PREHEAT_PIPE_EXTENSION_IN,
  PREHEAT_PIPE_EXTENSION_MM,
  PREHEAT_VALVE_SIZE,
} from "../../../../common/src/api/document/entities/plants/consts";
import { getCustomManufFields } from "../../../../common/src/api/document/entities/plants/customManufFields";
import { fillPlantDefaults } from "../../../../common/src/api/document/entities/plants/plant-defaults";
import PlantEntity from "../../../../common/src/api/document/entities/plants/plant-entity";
import {
  configurationToName,
  GreaseInterceptorTrapManufacturers,
  HotWaterPlantManufacturers,
  PlantManufacturers,
  PlantShapes,
  PlantType,
  PreheatInlet,
  ReturnSystemPlant,
  ReturnSystemType,
  RheemVariant,
} from "../../../../common/src/api/document/entities/plants/plant-types";
import {
  isDualSystemNodePlant,
  isHotWaterRheem,
  isManifoldPlant,
  isPlantReturnSystem,
} from "../../../../common/src/api/document/entities/plants/utils";
import { SystemNodeEntity } from "../../../../common/src/api/document/entities/system-node-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { DEFAULT_HORIZONTAL_SYSTEM_NETWORKS } from "../../../../common/src/api/document/flow-systems";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { hexToRgba } from "../../../../common/src/lib/color";
import {
  chooseByUnitsMetric,
  convertMeasurementSystem,
  Precision,
  Units,
  UnitsContext,
} from "../../../../common/src/lib/measurements";
import {
  assertUnreachable,
  assertUnreachableAggressive,
} from "../../../../common/src/lib/utils";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import { getGlobalContext, globalStore } from "../../store/globalCoreContext";
import { MainEventBus } from "../../store/main-event-bus";
import store from "../../store/store";
import {
  drawArrow,
  drawSystemNodeAccentIfNeeded,
} from "../helpers/draw-helper";
import {
  getCoolDragCorrelations,
  MoveIntent,
} from "../lib/black-magic/cool-drag";
import { makeConduitEntity } from "../lib/black-magic/utils";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { LiveWarnings } from "../lib/entity-popups/live-warnings";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { HeatmapMode } from "../lib/heatmap/heatmap";
import { Interaction, InteractionType } from "../lib/interaction";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { HoverableObject } from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import {
  getBlockLikeSnapLocations,
  SnapIntention,
  SnappableObject,
  SnapTarget,
} from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { getHighlightColor } from "../lib/utils";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete } from "./concrete-object";
import { MIN_PIPE_PIXEL_WIDTH } from "./drawableConduit";
import DrawableSystemNode from "./drawableSystemNode";
import { createCalculationBoxes, isFlowSystemActive } from "./utils";

const Base = HoverableObject(
  CalculatedObject(
    SelectableObject(
      AttachableObject(
        CoolDraggableObject(
          CenteredObject(SnappableObject(Core2Drawable(CorePlant))),
        ),
      ),
    ),
  ),
);

interface SolidArrowLayout {
  front?: number;
  center?: number;
  back?: number;
}

type FlowDirectionArrowSpec =
  | {
      arrowType: "none" | "slim-arrow-with-text";
    }
  | {
      arrowType: "solid-triangle";
      layout: SolidArrowLayout;
    };

export default class DrawablePlant extends Base {
  shouldCreatePreheatAccessories: Record<number, boolean> = {};
  shouldCreateDualSystemAccessories = false;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<PlantEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  getSnapTargets(request: SnapIntention[], mouseWc: Coord): SnapTarget[] {
    return getBlockLikeSnapLocations(
      request,
      mouseWc,
      this.getInletsOutlets(),
      this.toWorldCoord(),
      this.toWorldAngleDeg(0) * (Math.PI / 180),
    );
  }

  get heightMM() {
    const filled = fillPlantDefaults(this.context, this.entity);
    const isCylinder = filled.plant.shape === PlantShapes.CYLINDER;

    let entityHeightMM = filled.depthMM!;
    const diameter = filled.plant.diameterMM!;
    if (isCylinder) {
      entityHeightMM = diameter;
    }
    // TODO: remove all these grease_interceptor_trap special case stuff.
    else if (filled.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP) {
      entityHeightMM = filled.widthMM!;
    }

    return entityHeightMM;
  }

  get widthMM() {
    const filled = fillPlantDefaults(this.context, this.entity);
    const isCylinder = filled.plant.shape === PlantShapes.CYLINDER;

    let entitywidthMM = filled.widthMM!;
    const diameter = filled.plant.diameterMM!;
    if (isCylinder) {
      entitywidthMM = diameter;
    }
    // TODO: remove all these grease_interceptor_trap special case stuff.
    else if (filled.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP) {
      entitywidthMM = filled.plant.lengthMM!;
    }

    return entitywidthMM;
  }

  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    const { ctx } = context;
    const { selected, heatmapMode } = args;

    const filled = fillPlantDefaults(context, this.entity);

    const isCylinder = filled.plant.shape === PlantShapes.CYLINDER;

    this.withWorldScale(context, { x: 0, y: 0 }, () => {
      const lastAlpha = ctx.globalAlpha;

      if (
        isUnderfloor(
          getFlowSystem(
            this.document.drawing,
            this.document.activeflowSystemUid,
          ),
        ) &&
        isManifoldPlant(this.entity.plant)
      ) {
        ctx.globalAlpha = 1;
      } else if (!this.isActive()) {
        ctx.globalAlpha = 0.5;
      }

      const entitywidthMM = this.widthMM;
      const entityHeightMM = this.heightMM;
      const diameter = filled.plant.diameterMM!;

      const l = -entitywidthMM / 2;
      const r = entitywidthMM / 2;
      const b = entityHeightMM / 2;
      const t = -entityHeightMM / 2;

      const boxl = l * 1.2;
      const boxw = (r - l) * 1.2;
      const boxt = t * 1.2;
      const boxh = (b - t) * 1.2;

      const scale = context.vp.currToSurfaceScale(ctx);
      const baseLineWidth = Math.max(1 / scale, 10 * this.toWorldLength(1));
      ctx.lineWidth = baseLineWidth;
      ctx.strokeStyle = "#000";
      ctx.fillStyle = "rgba(255, 255, 255, 0.85)";

      // Dual System Plants have their own color
      if (isDualSystemNodePlant(filled.plant)) {
        ctx.fillStyle = hexToRgba(filled.plant.color.hex, 0.85);
      }

      // Approximate Manifolds are colored so the hatching makes sense
      if (
        isManifoldPlant(filled.plant) &&
        filled.plant.ufhMode === "approximate"
      ) {
        ctx.fillStyle = hexToRgba(filled.plant.color.hex, 0.85);
      }

      if (isCylinder) {
        ctx.beginPath();
        ctx.arc(0, 0, diameter / 2, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
      } else {
        ctx.fillRect(l, t, r - l, b - t);

        ctx.beginPath();
        ctx.rect(l, t, r - l, b - t);
        ctx.stroke();
      }
      // system caps
      if (!args.forExport) {
        // there is a bug at the moment causeing exports to show completely
        // black plants.
        for (const io of this.getInletsOutletSpecs()) {
          drawSystemNodeAccentIfNeeded({
            context,
            systemNode: this.globalStore.get(io.uid) as DrawableSystemNode,
            ioType: io.type,
            style: "mushroom",
            orientation: "horizontal",
            isReturn: io.isReturn,
            isRecirculation: io.isRecirculation,
            length: io.length,
          });
        }

        // Draw squiggly for problem connections
        const liveCalc = this.globalStore.getOrCreateLiveCalculation(
          this.entity,
        );

        if (liveCalc.problemSystemNodes.length) {
          const s = context.graphics.worldToSurfaceScale;
          const dynamicWidth = Math.max(
            MIN_PIPE_PIXEL_WIDTH / s,
            20 / context.graphics.unitWorldLength,
            (MIN_PIPE_PIXEL_WIDTH / s) * (5 + Math.log(s) * 0.5),
          );
          ctx.beginPath();
          ctx.strokeStyle = "red";
          ctx.lineWidth = dynamicWidth;
          ctx.setLineDash([dynamicWidth * 2, dynamicWidth]);
          const borderMult = 1.2;
          ctx.rect(
            l + dynamicWidth * borderMult,
            t + dynamicWidth * borderMult,
            r - l - dynamicWidth * borderMult * 2,
            b - t - dynamicWidth * borderMult * 2,
          );
          ctx.stroke();
        }
      }

      type InteriorSpec = {
        paddingHeightMM: number;
        name: {
          y: number;
          height: number;
          maxWidth?: number;
        };
        flowDirectionArrow:
          | {
              type: "none";
            }
          | {
              type: "slim-arrow-with-text";
              arrow: {
                y: number;
                height: number;
              };
              text: {
                y: number;
                height: number;
              };
            }
          | {
              type: "solid-triangle";
              layout: SolidArrowLayout;
            };
      };

      const interiorSpec = ((): InteriorSpec => {
        const flowArrowSpec = this.getFlowArrowSpec();
        const paddingHeightMM = entityHeightMM / 6;

        switch (flowArrowSpec.arrowType) {
          case "none":
            /* From top to bottom: 
              padding,
              equipment name,
              padding
            */
            return {
              paddingHeightMM,
              name: {
                y: 0,
                height: entityHeightMM - paddingHeightMM * 2,
              },
              flowDirectionArrow: {
                type: flowArrowSpec.arrowType,
              },
            };
          case "solid-triangle":
            /* From top to bottom: 
              padding,
              equipment name and the arrow overlapping,
              padding
            */
            return {
              paddingHeightMM,
              name: {
                y: 0,
                height: entityHeightMM - paddingHeightMM * 2,
                maxWidth: entitywidthMM * 0.8,
              },
              flowDirectionArrow: {
                type: flowArrowSpec.arrowType,
                layout: flowArrowSpec.layout,
              },
            };
          case "slim-arrow-with-text":
            /* From top to bottom: 
              padding,
              equipment name, 
              flow direction arrow, 
              arrow text,
              padding
            */
            const nameHeightMM = entityHeightMM / 2;
            const nameSpec = {
              y: t + paddingHeightMM + nameHeightMM / 2,
              height: nameHeightMM,
            };

            const arrowWithTextHeightMM =
              entityHeightMM - nameHeightMM - 2 * paddingHeightMM;
            const arrowHeightMM = arrowWithTextHeightMM / 4;
            const arrowTextHeightMM = arrowWithTextHeightMM - arrowHeightMM;
            const arrowSpec = {
              y: t + paddingHeightMM + nameHeightMM + arrowHeightMM / 2,
              height: arrowHeightMM,
            };
            const arrowTextSpec = {
              y: b - paddingHeightMM - arrowTextHeightMM / 2,
              height: arrowTextHeightMM,
            };

            return {
              paddingHeightMM,
              name: nameSpec,
              flowDirectionArrow: {
                type: flowArrowSpec.arrowType,
                arrow: arrowSpec,
                text: arrowTextSpec,
              },
            };
          default:
            assertUnreachableAggressive(flowArrowSpec);
        }
      })();

      // Draw the equipment's name
      const name = this.resolveDisplayName(context, filled);
      const nameLines = name.split("\n");

      const maxNameLineLen = Math.max(
        ...nameLines.map((line) => line.split("").length),
      );

      const maxNameWidth = interiorSpec.name.maxWidth ?? entitywidthMM;

      const nameFontSize = Math.round(
        Math.min(
          this.toWorldLength(maxNameWidth) / maxNameLineLen,
          this.toWorldLength(interiorSpec.name.height) / nameLines.length,
        ),
      );

      ctx.font = `${nameFontSize}px ${DEFAULT_FONT_NAME}`;

      nameLines.forEach((line, i) => {
        const measure = ctx.measureText(line);

        const angle = this.toWorldAngleDeg(0);
        const shouldFlip = angle > 90 || angle < -90;
        if (shouldFlip) {
          ctx.rotate(Math.PI);
        }

        ctx.fillStyle = "#000000";
        ctx.fillTextStable(
          line,
          -measure.width / 2,
          +nameFontSize / 3 +
            (i - nameLines.length / 2 + 0.5) * nameFontSize +
            interiorSpec.name.y,
          undefined,
          "alphabetic",
        );

        if (shouldFlip) {
          ctx.rotate(-Math.PI);
        }
      });

      const flowArrowSpec = interiorSpec.flowDirectionArrow;
      switch (flowArrowSpec.type) {
        case "none":
          break;
        case "slim-arrow-with-text": {
          // Draw the direction arrow and its text if needed
          const drawFlowDirectionArrowText = (
            centerX: number,
            centerY: number,
            text: string,
            fontSize: number,
            arrowText: string,
          ) => {
            const existingFillStyle = ctx.fillStyle;
            const existingTransform = ctx.getTransform();

            ctx.translate(centerX, centerY);

            ctx.font = `${fontSize}px ${DEFAULT_FONT_NAME}`;
            const measure = ctx.measureText(arrowText);

            const angle = this.toWorldAngleDeg(0);
            const shouldFlip = angle > 90 || angle < -90;
            if (shouldFlip) {
              ctx.rotate(Math.PI);
            }

            ctx.fillStyle = "rgba(0, 0, 0, 0.8)";

            ctx.fillText(text, centerX - measure.width / 2, +fontSize / 3);

            ctx.fillStyle = existingFillStyle;
            ctx.setTransform(existingTransform);
          };

          const { arrow: arrowSpec, text: arrowTextSpec } = flowArrowSpec;

          const arrowText = "Flow";
          const arrowTextFontSize = Math.round(
            Math.min(
              nameFontSize - 1,
              this.toWorldLength(entitywidthMM) / arrowText.length,
              this.toWorldLength(arrowTextSpec.height * 0.8),
            ),
          );

          drawArrow({
            ctx,
            x: 0,
            y: arrowSpec.y,
            bodyLength: entitywidthMM * 0.4,
            headLength: entitywidthMM * 0.1,
            headWidth: arrowSpec.height * 0.8,
            bodyWidth: arrowSpec.height * 0.2,
            angle: filled.rightToLeft ? Math.PI : 0,
            color: { hex: "rgba(0, 0, 0, 0.8)" },
          });

          drawFlowDirectionArrowText(
            0,
            arrowTextSpec.y,
            arrowText,
            arrowTextFontSize,
            arrowText,
          );
          break;
        }
        case "solid-triangle": {
          ctx.fillStyle = "rgba(0, 0, 0, 0.25)";
          const drawArrow = (
            x: number,
            y: number,
            w: number,
            h: number,
            siblings: number,
          ) => {
            const ts = Math.min(w, h) * 0.7;
            const ew = Math.min(w, ts * (1 + siblings * 0.3));
            const xTrans = filled.rightToLeft ? -1 : 1;

            ctx.beginPath();
            ctx.moveTo((x + -ew / 2) * xTrans, y + -ts / 2);
            ctx.lineTo((x + +ew / 2) * xTrans, y + 0);
            ctx.lineTo((x + -ew / 2) * xTrans, y + +ts / 2);
            ctx.closePath();
            ctx.fill();
          };

          const { front = 0, back = 0, center = 0 } = flowArrowSpec.layout;

          for (let i = 0; i < back; i++) {
            const w = (r - l) / 3;
            const h = (b - t) / 2 / back;
            drawArrow(l + w / 2, 0 + (-back / 2 + 0.5 + i) * h, w, h, back - 1);
          }
          for (let i = 0; i < center; i++) {
            const w = (r - l) * 0.8;
            const h = (b - t) / center;
            drawArrow(0, 0 + (-center / 2 + 0.5 + i) * h, w, h, center - 1);
          }
          for (let i = 0; i < front; i++) {
            const w = (r - l) / 3;
            const h = (b - t) / 2 / front;
            drawArrow(
              r - w / 2,
              0 + (-front / 2 + 0.5 + i) * h,
              w,
              h,
              front - 1,
            );
          }
          break;
        }
        default:
          assertUnreachableAggressive(flowArrowSpec);
      }

      // draw selection box if selected.
      if (
        (selected || args.overrideColorList.length) &&
        (heatmapMode === HeatmapMode.Off ||
          this.document.uiState.drawingMode !== DrawingMode.Calculations)
      ) {
        ctx.fillStyle = rgb2style(
          getHighlightColor(selected, args.overrideColorList),
          0.4,
        );
        ctx.fillRect(boxl, boxt, boxw, boxh);
      }

      if (this.isHovering) {
        ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
        if (isCylinder) {
          ctx.beginPath();
          ctx.arc(0, 0, diameter / 2, 0, 2 * Math.PI);
          ctx.fill();
        } else {
          ctx.fillRect(boxl, boxt, boxw, boxh);
        }
      }

      ctx.globalAlpha = lastAlpha;
    });
  }

  getFlowArrowSpec(): FlowDirectionArrowSpec {
    switch (this.entity.plant.type) {
      case PlantType.RADIATOR:
      case PlantType.MANIFOLD:
        return {
          arrowType: "solid-triangle",
          layout: {
            back: 1,
            front: 1,
          },
        };
      case PlantType.PUMP:
      case PlantType.PUMP_TANK:
      case PlantType.FILTER:
      case PlantType.UFH:
      case PlantType.TANK:
      case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      case PlantType.DRAINAGE_PIT:
      case PlantType.CUSTOM:
      case PlantType.VOLUMISER:
      case PlantType.RO:
      case PlantType.DUCT_MANIFOLD:
        return {
          arrowType: "slim-arrow-with-text",
        };
      case PlantType.AHU:
      case PlantType.AHU_VENT:
      case PlantType.FCU:
        return {
          arrowType: "none",
        };
      case PlantType.RETURN_SYSTEM:
        switch (this.entity.plant.returnSystemType) {
          case ReturnSystemType.AIR_SOURCE_HEAT_PUMP:
          case ReturnSystemType.GROUND_SOURCE_HEAT_PUMP:
          case ReturnSystemType.CHILLER:
          case ReturnSystemType.COOLING_TOWER:
            return {
              arrowType: "none",
            };
          case ReturnSystemType.HEADER:
          case ReturnSystemType.BUFFER_TANK:
          case ReturnSystemType.HEAT_SOURCE:
          case ReturnSystemType.GAS_BOILER:
          case ReturnSystemType.DHW_CYLINDER:
          case ReturnSystemType.DHW_CYLINDER_W_STORAGE:
          case ReturnSystemType.HOT_WATER_PLANT_W_RETURN:
          case ReturnSystemType.CUSTOM:
            return {
              arrowType: "slim-arrow-with-text",
            };
        }
      default:
        assertUnreachableAggressive(this.entity.plant);
    }
  }

  isActive() {
    for (const { systemUid } of this.getInletsOutletSpecs()) {
      if (isFlowSystemActive(this.context, this.document.uiState, systemUid)) {
        return true;
      }
    }

    if (isManifoldPlant(this.entity.plant)) {
      if (
        isFlowSystemActive(
          this.context,
          this.document.uiState,
          this.entity.plant.ufhSystemUid,
        )
      ) {
        return true;
      }
    }

    return false;
  }

  locateCalculationBoxWorld(
    context: DrawingContext,
    data: CalculationData[],
    scale: number,
  ): TM.Matrix[] {
    const entityHeightMM = this.heightMM;

    return createCalculationBoxes({
      wc: this.toWorldCoord(),
      angle: (this.toWorldAngleDeg(0) / 180) * Math.PI,
      scale,
      distanceCandidates: [entityHeightMM / 2],
      data,
    });
  }

  refreshObjectInternal(_obj: BigValveEntity): void {
    //
  }

  inBounds(objectCoord: Coord) {
    if (!this.isActive()) {
      return false;
    }

    const entitywidthMM = this.widthMM;
    const entityHeightMM = this.heightMM;
    const capDistance = 100;

    const l = -entitywidthMM / 2 - capDistance;
    const r = entitywidthMM / 2 + capDistance;
    const b = entityHeightMM / 2 + capDistance;
    const t = -entityHeightMM / 2 - capDistance;
    if (
      objectCoord.x >= l &&
      objectCoord.y >= t &&
      objectCoord.x <= r &&
      objectCoord.y <= b
    ) {
      return true;
    }
    return false;
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    return [
      ...this.getInletsOutlets(true)
        .map((o) => o?.prepareDelete(context))
        .filter(Boolean)
        .flat(),
      this,
    ];
  }

  getInletsOutlets(all: boolean = false): DrawableSystemNode[] {
    return this.getCoreInletsOutlets(all) as DrawableSystemNode[];
  }

  offerJoiningInteraction(
    requestSystemUid: string | undefined,
    interaction: Interaction,
  ): DrawableEntityConcrete[] | null {
    const inouts = this.getInletsOutlets();
    inouts.sort((a, b) => {
      const awc = a.toWorldCoord();
      const bwc = b.toWorldCoord();
      return (
        coordDist2(awc, interaction.worldCoord) -
        coordDist2(bwc, interaction.worldCoord)
      );
    });
    for (const sys of inouts) {
      if (sys.offerInteraction(interaction)) {
        return [sys.entity, this.entity];
      }
    }
    return null;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    switch (interaction.type) {
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
        return this.offerJoiningInteraction(
          interaction.system?.uid,
          interaction,
        );
      case InteractionType.SNAP_ONTO_RECEIVE:
        if (interaction.src.type === EntityType.FITTING) {
          return this.offerJoiningInteraction(
            interaction.src.systemUid,
            interaction,
          );
        } else {
          return null;
        }
      case InteractionType.INSERT:
      case InteractionType.SNAP_ONTO_SEND:
      case InteractionType.EXTEND_NETWORK:
        return null;
      case InteractionType.LINK_ENTITY:
        return [this.entity];
    }
  }

  getCoolDragCorrelations(
    myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    return getCoolDragCorrelations({
      globalStore: this.globalStore,
      systemNodes: this.getInletsOutlets(),
      self: this,
      includedDistanceMM: 300,
      includedShape: this.shape || undefined,
      selfMove: myMove,
    });
  }

  // this method creates the missing inlets
  // and deletes the inlets that should not exist anymore
  updateInlets(filled: PlantEntity) {
    const newInlets: SystemNodeEntity[] = [];

    if (
      filled.plant.type === PlantType.RETURN_SYSTEM &&
      this.entity.plant.type === PlantType.RETURN_SYSTEM
    ) {
      if (filled.plant.addColdWaterInlet && !filled.inletUid) {
        const coldWaterInletUid = uuid();
        const coldWaterInlet: SystemNodeEntity = {
          // this center is not used
          center: {
            x: 0,
            y: 0,
          },
          parentUid: filled.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: StandardFlowSystemUids.ColdWater,
          uid: coldWaterInletUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.INPUT,
        };
        this.entity.inletUid = coldWaterInletUid;
        newInlets.push(coldWaterInlet);
      } else if (!filled.plant.addColdWaterInlet && filled.inletUid) {
        MainEventBus.$emit("delete-entity", globalStore.get(filled.inletUid));
        this.entity.inletUid = null;
      }

      if (filled.plant.addGasInlet && !filled.plant.gasNodeUid) {
        const gasInletUid = uuid();
        const defaultGasInletSystemUid = StandardFlowSystemUids.Gas;
        const gasInlet: SystemNodeEntity = {
          // this center is not used
          center: {
            x: 0,
            y: 0,
          },
          parentUid: filled.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: defaultGasInletSystemUid,
          uid: gasInletUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.INPUT,
        };
        this.entity.plant.gasNodeUid = gasInletUid;
        this.entity.plant.gasInletSystemUid = defaultGasInletSystemUid;
        newInlets.push(gasInlet);
      } else if (!filled.plant.addGasInlet && filled.plant.gasNodeUid) {
        MainEventBus.$emit(
          "delete-entity",
          globalStore.get(filled.plant.gasNodeUid),
        );
        this.entity.plant.gasNodeUid = null;
        this.entity.plant.gasInletSystemUid = null;
      }
    }

    if (isDualSystemNodePlant(this.entity.plant)) {
      if (
        this.entity.plant.addHeatingIO &&
        !this.entity.plant.heatingInletUid
      ) {
        const heatingInletUid = uuid();
        const heatingOutletUid = uuid();

        const heatingInlet: SystemNodeEntity = {
          center: { x: 0, y: 0 },
          parentUid: filled.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: this.entity.plant.heatingSystemUid,
          uid: heatingInletUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.INPUT,
        };
        const heatingOutlet: SystemNodeEntity = {
          center: { x: 0, y: 0 },
          parentUid: filled.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: this.entity.plant.heatingSystemUid,
          uid: heatingOutletUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.OUTPUT,
        };

        this.entity.plant.heatingInletUid = heatingInletUid;
        this.entity.plant.heatingOutletUid = heatingOutletUid;
        this.shouldCreateDualSystemAccessories = true;

        newInlets.push(heatingInlet, heatingOutlet);
      }

      if (
        this.entity.plant.addChilledIO &&
        !this.entity.plant.chilledInletUid
      ) {
        const chilledInletUid = uuid();
        const chilledOutletUid = uuid();

        const chilledInlet: SystemNodeEntity = {
          center: { x: 0, y: 0 },
          parentUid: filled.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: this.entity.plant.chilledSystemUid,
          uid: chilledInletUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.INPUT,
        };
        const chilledOutlet: SystemNodeEntity = {
          center: { x: 0, y: 0 },
          parentUid: filled.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: this.entity.plant.chilledSystemUid,
          uid: chilledOutletUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.OUTPUT,
        };

        this.entity.plant.chilledInletUid = chilledInletUid;
        this.entity.plant.chilledOutletUid = chilledOutletUid;
        this.shouldCreateDualSystemAccessories = true;
        newInlets.push(chilledInlet, chilledOutlet);
      }

      if (
        !this.entity.plant.addHeatingIO &&
        this.entity.plant.heatingInletUid &&
        this.entity.plant.heatingOutletUid
      ) {
        this.deleteConnectedPipeAndValve(this.entity.plant.heatingInletUid);
        this.deleteConnectedPipeAndValve(this.entity.plant.heatingOutletUid);

        const heatingInletUid = this.entity.plant.heatingInletUid;
        const heatingOutletUid = this.entity.plant.heatingOutletUid;
        this.entity.plant.heatingInletUid = null;
        this.entity.plant.heatingOutletUid = null;
        MainEventBus.$emit("delete-entity", globalStore.get(heatingInletUid));
        MainEventBus.$emit("delete-entity", globalStore.get(heatingOutletUid));
      }

      if (
        !this.entity.plant.addChilledIO &&
        this.entity.plant.chilledInletUid &&
        this.entity.plant.chilledOutletUid
      ) {
        this.deleteConnectedPipeAndValve(this.entity.plant.chilledInletUid);
        this.deleteConnectedPipeAndValve(this.entity.plant.chilledOutletUid);

        const chilledInletUid = this.entity.plant.chilledInletUid;
        const chilledOutletUid = this.entity.plant.chilledOutletUid;
        this.entity.plant.chilledInletUid = null;
        this.entity.plant.chilledOutletUid = null;
        MainEventBus.$emit("delete-entity", globalStore.get(chilledInletUid));
        MainEventBus.$emit("delete-entity", globalStore.get(chilledOutletUid));
      }
    }

    if (this.entity.plant.type === PlantType.AHU_VENT) {
      if (this.entity.plant.addIntakeIO && !this.entity.plant.intakeUid) {
        const intakeUid = uuid();
        const intakeInlet: SystemNodeEntity = {
          center: { x: 0, y: 0 },
          parentUid: this.entity.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: this.entity.plant.intakeSystemUid,
          uid: intakeUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.INPUT,
        };
        this.entity.plant.intakeUid = intakeUid;
        newInlets.push(intakeInlet);
      }

      if (this.entity.plant.addExhaustIO && !this.entity.plant.exhaustUid) {
        const exhaustUid = uuid();
        const exhaustOutlet: SystemNodeEntity = {
          center: { x: 0, y: 0 },
          parentUid: this.entity.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: this.entity.plant.exhaustSystemUid,
          uid: exhaustUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.OUTPUT,
        };
        this.entity.plant.exhaustUid = exhaustUid;
        newInlets.push(exhaustOutlet);
      }

      if (!this.entity.plant.addIntakeIO && this.entity.plant.intakeUid) {
        MainEventBus.$emit(
          "delete-entity",
          globalStore.get(this.entity.plant.intakeUid),
        );
        this.entity.plant.intakeUid = null;
      }

      if (!this.entity.plant.addExhaustIO && this.entity.plant.exhaustUid) {
        MainEventBus.$emit(
          "delete-entity",
          globalStore.get(this.entity.plant.exhaustUid),
        );
        this.entity.plant.exhaustUid = null;
      }
    }

    newInlets.forEach((inlet) => {
      store.dispatch("document/addEntity", inlet);
    });
  }

  updateOutlets(filled: PlantEntity) {
    const newOutlets: SystemNodeEntity[] = [];
    if (
      filled.plant.type === PlantType.RETURN_SYSTEM &&
      this.entity.plant.type === PlantType.RETURN_SYSTEM
    ) {
      for (const [index, outlet] of filled.plant.outlets.entries()) {
        if (!outlet.outletUid) {
          const outletUid = uuid();
          newOutlets.push({
            // this center is not used
            center: {
              x: 0,
              y: 0,
            },
            parentUid: filled.uid,
            type: EntityType.SYSTEM_NODE,
            calculationHeightM: null,
            systemUid: outlet.outletSystemUid,
            uid: outletUid,
            allowAllSystems: false,
            configuration: FlowConfiguration.OUTPUT,
          });
          this.entity.plant.outlets[index].outletUid = outletUid;
        }
        if (outlet.addRecirculation && !outlet.outletReturnUid) {
          const outletReturnUid = uuid();
          newOutlets.push({
            center: {
              // this center is not used
              x: 0,
              y: 0,
            },
            parentUid: filled.uid,
            type: EntityType.SYSTEM_NODE,
            calculationHeightM: null,
            systemUid: outlet.outletSystemUid,
            uid: outletReturnUid,
            allowAllSystems: false,
            configuration: FlowConfiguration.INPUT,
          });
          this.entity.plant.outlets[index].outletReturnUid = outletReturnUid;
        } else if (!outlet.addRecirculation && outlet.outletReturnUid) {
          MainEventBus.$emit(
            "delete-entity",
            globalStore.get(outlet.outletReturnUid),
          );
          this.entity.plant.outlets[index].outletReturnUid = null;
        }
      }
    }

    if (this.entity.plant.type === PlantType.AHU_VENT) {
      if (this.entity.plant.addExtractIO && !this.entity.plant.extractUid) {
        const extractUid = uuid();
        const extractOutlet: SystemNodeEntity = {
          center: { x: 0, y: 0 },
          parentUid: this.entity.uid,
          type: EntityType.SYSTEM_NODE,
          calculationHeightM: null,
          systemUid: this.entity.plant.extractSystemUid,
          uid: extractUid,
          allowAllSystems: false,
          configuration: FlowConfiguration.INPUT,
        };
        this.entity.plant.extractUid = extractUid;
        newOutlets.push(extractOutlet);
      }

      if (!this.entity.plant.addExtractIO && this.entity.plant.extractUid) {
        MainEventBus.$emit(
          "delete-entity",
          globalStore.get(this.entity.plant.extractUid),
        );
        this.entity.plant.extractUid = null;
      }
    }

    newOutlets.forEach((outlet) => {
      store.dispatch("document/addEntity", outlet);
    });
  }

  updating = false;

  onUpdate() {
    if (this.updating) {
      return;
    }
    this.updating = true;
    super.onUpdate();

    this.keepPlantDimensionsInSync();
    this.resetDefaultManufacturersFields();
    this.validateCustomManufFields();

    const filled = fillPlantDefaults(getGlobalContext(), this.entity);

    this.updateInlets(filled);
    this.updateOutlets(filled);
    this.updateInletsOutletsPositions(filled);
    this.updateAccessoriesIfNeeded();
    this.createAccessories();

    this.updating = false;
  }

  keepPlantDimensionsInSync() {
    switch (this.entity.plant.type) {
      case PlantType.RADIATOR:
        switch (this.entity.plant.radiatorType) {
          case "specify":
            if (
              this.entity.plant.widthMM.type === "upper" &&
              this.entity.plant.heightMM.type === "upper" &&
              this.entity.plant.widthMM.value !== null &&
              this.entity.plant.heightMM.value !== null &&
              this.entity.plant.manufacturer === "generic"
            ) {
              this.entity.plant.heightMM.value = null;
            }
            this.entity.plant.widthMM.value = this.entity.widthMM;
            this.entity.plant.depthMM = this.entity.depthMM;
            break;
          case "fixed":
            this.entity.plant.widthMM = this.entity.widthMM;
            this.entity.plant.depthMM = this.entity.depthMM;
            break;
          default:
            assertUnreachable(this.entity.plant);
        }
        break;
      case PlantType.RETURN_SYSTEM:
      case PlantType.CUSTOM:
      case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      case PlantType.DRAINAGE_PIT:
      case PlantType.PUMP:
      case PlantType.PUMP_TANK:
      case PlantType.TANK:
      case PlantType.VOLUMISER:
      case PlantType.AHU:
      case PlantType.AHU_VENT:
      case PlantType.FCU:
      case PlantType.MANIFOLD:
      case PlantType.UFH:
      case PlantType.FILTER:
      case PlantType.RO:
      case PlantType.DUCT_MANIFOLD:
        break;
      default:
        assertUnreachable(this.entity.plant);
    }
  }

  createAccessories() {
    if (this.entity.plant.type === PlantType.RETURN_SYSTEM) {
      for (let i = 0; i < this.entity.plant.preheats.length; i++) {
        if (this.shouldCreatePreheatAccessories[i]) {
          this.createPreheatAccessories(this.entity.plant.preheats[i]);
          this.shouldCreatePreheatAccessories[i] = false;
        }
      }
    }
    if (this.shouldCreateDualSystemAccessories) {
      this.createDualSystemAccessories();
      this.shouldCreateDualSystemAccessories = false;
    }
  }

  addPreheat(fields?: Partial<PreheatInlet>) {
    if (!isPlantReturnSystem(this.entity)) {
      return;
    }

    const inlet: SystemNodeEntity = {
      uid: uuid(),
      allowAllSystems: false,
      center: { x: 0, y: 0 },
      configuration: FlowConfiguration.INPUT,
      parentUid: this.entity.uid,
      calculationHeightM: null,
      systemUid:
        this.entity.plant.preheatSystemUid || StandardFlowSystemUids.Heating,
      type: EntityType.SYSTEM_NODE,
    };

    const outlet: SystemNodeEntity = {
      uid: uuid(),
      allowAllSystems: false,
      center: { x: 0, y: 0 },
      configuration: FlowConfiguration.OUTPUT,
      parentUid: this.entity.uid,
      calculationHeightM: null,
      systemUid:
        this.entity.plant.preheatSystemUid || StandardFlowSystemUids.Heating,
      type: EntityType.SYSTEM_NODE,
    };

    if (this.entity.plant.preheatSystemUid == null) {
      const outletSystem =
        this.document.drawing.metadata.flowSystems[
          this.entity.plant.outlets[0].outletSystemUid
        ];
      if (
        this.entity.plant.returnSystemType === ReturnSystemType.HEADER ||
        this.entity.plant.returnSystemType === ReturnSystemType.BUFFER_TANK
      ) {
        this.entity.plant.preheatSystemUid = outletSystem.uid;
      } else if (isHeatingPlantSystem(outletSystem)) {
        this.entity.plant.preheatSystemUid = StandardFlowSystemUids.Heating;
      } else {
        this.entity.plant.preheatSystemUid = StandardFlowSystemUids.Condenser;
      }
    }

    this.entity.plant.preheats.push({
      inletUid: inlet.uid,
      returnUid: outlet.uid,
      explicitRating: {
        type: "energy",
        KW: 0,
      },
      heightAboveFloorM: null,
      pressureDropKPA: null,
      ratingMode: "percentage",
      ratingPCT: null,
      volumeL: null,
      ...(fields ? fields : {}),
    });

    store.dispatch("document/addEntity", inlet);
    store.dispatch("document/addEntity", outlet);

    for (let i = 0; i < this.entity.plant.preheats.length; i++) {
      const preheat = this.entity.plant.preheats[i];
      this.deleteConnectedPipeAndValve(preheat.inletUid);
      this.deleteConnectedPipeAndValve(preheat.returnUid);
      this.shouldCreatePreheatAccessories[i] = true;
    }

    this.onUpdate();
  }

  removePreheat(index: number) {
    if (!isPlantReturnSystem(this.entity)) {
      return;
    }

    const preheat = this.entity.plant.preheats[index];
    this.deleteConnectedPipeAndValve(preheat.inletUid);
    this.deleteConnectedPipeAndValve(preheat.returnUid);

    const preheatNode = this.globalStore.get(preheat.inletUid);
    if (preheatNode) {
      store.dispatch("document/deleteEntity", preheatNode);
    }
    const returnNode = this.globalStore.get(preheat.returnUid);
    if (returnNode) {
      store.dispatch("document/deleteEntity", returnNode);
    }

    this.entity.plant.preheats.splice(index, 1);

    // Reshuffle them anyway to reset their positions
    for (let i = 0; i < this.entity.plant.preheats.length; i++) {
      const preheat = this.entity.plant.preheats[i];
      this.deleteConnectedPipeAndValve(preheat.inletUid);
      this.deleteConnectedPipeAndValve(preheat.returnUid);
      this.shouldCreatePreheatAccessories[i] = true;
    }

    if (this.entity.plant.preheats.length === 1) {
      // Reset the rating mode to percentage if there is only one preheat because
      // the rating mode fields are hidden.
      this.entity.plant.preheats[0].ratingMode = "percentage";
      this.entity.plant.preheats[0].ratingPCT = null;
    }

    this.onUpdate();
  }

  // Check to make sure the accessories have the correct flow system
  // This is required when the preheat flow system is changed
  updateAccessoriesIfNeeded() {
    switch (this.entity.plant.type) {
      case PlantType.RETURN_SYSTEM:
        for (let i = 0; i < this.entity.plant.preheats.length; i++) {
          const preheat = this.entity.plant.preheats[i];

          const connectedPipe = this.getConnectedPipeToNode(preheat.returnUid);

          if (
            connectedPipe &&
            connectedPipe.systemUid != this.entity.plant.preheatSystemUid
          ) {
            this.deleteConnectedPipeAndValve(preheat.inletUid);
            this.deleteConnectedPipeAndValve(preheat.returnUid);
            this.shouldCreatePreheatAccessories[i] = true;
          }
        }
      case PlantType.CUSTOM:
      case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      case PlantType.DRAINAGE_PIT:
      case PlantType.PUMP:
      case PlantType.PUMP_TANK:
      case PlantType.RADIATOR:
      case PlantType.TANK:
      case PlantType.VOLUMISER:
      case PlantType.AHU:
      case PlantType.AHU_VENT:
      case PlantType.FCU:
      case PlantType.MANIFOLD:
      case PlantType.UFH:
      case PlantType.FILTER:
      case PlantType.RO:
      case PlantType.DUCT_MANIFOLD:
        break;
      default:
        assertUnreachable(this.entity.plant);
    }
  }

  getConnectedPipeToNode(nodeId: string): ConduitEntity | null {
    const cons = this.globalStore.getConnections(nodeId);

    for (const con of cons) {
      const o = this.globalStore.get(con);
      if (o.entity.type === EntityType.CONDUIT) {
        return o.entity;
      }
    }

    return null;
  }

  deleteConnectedPipeAndValve(nodeId: string) {
    const connectedPipe = this.getConnectedPipeToNode(nodeId);
    if (connectedPipe) {
      let connectedValve: DirectedValveEntity | null = null;

      for (const con of connectedPipe.endpointUid) {
        const o = this.globalStore.get(con);
        if (o.entity.type === EntityType.DIRECTED_VALVE) {
          connectedValve = o.entity;
        }
      }

      store.dispatch("document/deleteEntity", connectedPipe);
      if (connectedValve) {
        store.dispatch("document/deleteEntity", connectedValve);
      }
    }
  }

  private updateInletsOutletsPositions(_filled: PlantEntity) {
    const positions = this.getInletsOutletsPositions();

    for (const [uid, { systemUid }] of Object.entries(positions)) {
      const connectable = this.drawableStore.get<DrawableSystemNode>(uid);
      if (connectable) {
        if (connectable.entity.systemUid !== systemUid) {
          connectable.entity.systemUid = systemUid;
        }
        // System node positions are live now and not
        // stored on the entity
        // if (Math.abs(connectable.entity.center.x - x) > EPS) {
        //   connectable.entity.center.x = x;
        // }
        // if (Math.abs(connectable.entity.center.y - y) > EPS) {
        //   connectable.entity.center.y = y;
        // }
      }
    }
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    return [this, ...this.getInletsOutlets(true)];
  }

  resetDefaultManufacturersFields() {
    switch (this.entity.plant.type) {
      case PlantType.RETURN_SYSTEM:
        if (
          isHotWaterRheem(this.document.drawing, this.entity.plant.returnType)
        ) {
          this.entity.plant.addColdWaterInlet = true;
          this.entity.plant.addGasInlet = true;
          for (let i = this.entity.plant.preheats.length - 1; i >= 0; i--) {
            this.removePreheat(i);
          }
        }
        break;
      case PlantType.TANK:
      case PlantType.PUMP_TANK:
      case PlantType.CUSTOM:
      case PlantType.PUMP:
      case PlantType.DRAINAGE_PIT:
      case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      case PlantType.RADIATOR:
      case PlantType.VOLUMISER:
      case PlantType.AHU:
      case PlantType.AHU_VENT:
      case PlantType.FCU:
      case PlantType.MANIFOLD:
      case PlantType.UFH:
      case PlantType.FILTER:
      case PlantType.RO:
      case PlantType.DUCT_MANIFOLD:
        break;
      default:
        assertUnreachable(this.entity.plant);
    }
  }

  validateCustomManufFields() {
    const manufFields = getCustomManufFields(
      this.entity.plant,
      this.document.drawing.metadata.catalog,
    );
    if (manufFields.length === 0) {
      if ("customManufFields" in this.entity.plant) {
        delete this.entity.plant.customManufFields;
      }
    }

    if (manufFields.length > 0) {
      if (!Object.hasOwn(this.entity.plant, "customManufFields")) {
        // @ts-ignore changing object
        Vue.set(this.entity.plant, "customManufFields", {});
      }
      for (const field of manufFields) {
        // @ts-ignore changing object
        if (!Object.hasOwn(this.entity.plant.customManufFields, field.uid)) {
          // @ts-ignore changing object
          Vue.set(this.entity.plant.customManufFields, field.uid, null);
        }
      }
    }
  }

  resolveDisplayName(context: DrawingContext, entity: PlantEntity): string {
    const filled = fillPlantDefaults(getGlobalContext(), this.entity);

    let manufacturer: PlantManufacturers = "generic";
    let name = filled.name!;

    switch (filled.plant.type) {
      case PlantType.RETURN_SYSTEM:
        if (entity.name === "Hot Water Plant" || !entity.name) {
          manufacturer =
            (context.doc.drawing.metadata.catalog.hotWaterPlant.find(
              (i) => i.uid === "hotWaterPlant",
            )?.manufacturer as HotWaterPlantManufacturers) || manufacturer;

          if (manufacturer === "rheem") {
            name = `${manufacturer} ${
              RheemVariant[filled.plant.rheemVariant!]
            }`.toUpperCase();
          }
        }

        break;
      case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
        manufacturer = context.doc.drawing.metadata.catalog
          .greaseInterceptorTrap![0]
          .manufacturer as GreaseInterceptorTrapManufacturers;

        name = `${manufacturer} ${filled.plant.brand!} ${
          filled.plant.capacity
        } ${filled.name}`;

        break;
      case PlantType.FILTER:
        const man = getManufacturerRecord(filled, context.catalog);
        name = "";
        if (man && man.uid !== "generic") {
          name = `${man.name}\n`;
        }
        name += `${configurationToName(filled.plant.configuration!)} ${
          filled.name
        }`;
        break;
      case PlantType.RO:
        const man2 = getManufacturerRecord(filled, context.catalog);
        name = "";
        if (man2 && man2.uid !== "generic") {
          name = `${man2.name}\n`;
        }
        name += filled.name;
      case PlantType.PUMP:
      case PlantType.PUMP_TANK:
      case PlantType.TANK:
      case PlantType.DRAINAGE_PIT:
      case PlantType.CUSTOM:
      case PlantType.VOLUMISER:
        break;
      case PlantType.RADIATOR:
        name = "RADIATOR";
        if (filled.plant.radiatorType === "specify") {
          const man3: Manufacturer<any> | undefined = getManufacturerRecord(
            filled,
            context.catalog,
          );
          if (man3 && man3.uid !== "generic") {
            name = radiatorModelNameShortener(filled.plant);
          }
        }
        name += ` ` + radiatorDimension(filled.plant);
      case PlantType.MANIFOLD:
      case PlantType.UFH:
        if (name === "Manifold") {
          const liveCalcs = this.globalStore.getOrCreateLiveCalculation(
            this.entity,
          );
          name = `Manifold ${liveCalcs.manifoldId} `;
        }

        switch (filled.plant.rating.type) {
          case "energy": {
            const [units, value] = convertMeasurementSystem(
              this.document.drawing.metadata.units,
              Units.KiloWatts,
              filled.plant.rating.KW,
              Precision.DISPLAY,
              UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
            );
            const val = value ? `${value} ${units}` : `? ${units}`;
            name +=
              (name === "" ? entity.name?.toUpperCase() + " " : " ") + val;
            break;
          }
          case "flow-rate": {
            const [units, value] = convertMeasurementSystem(
              this.document.drawing.metadata.units,
              Units.LitersPerSecond,
              filled.plant.rating.LS,
              Precision.DISPLAY,
            );
            const val = value ? `${value} ${units}` : `? ${units}`;
            name +=
              (name === "" ? entity.name?.toUpperCase() + " " : " ") + val;
            break;
          }
          default:
            assertUnreachable(filled.plant.rating);
        }
        break;
      case PlantType.AHU_VENT:
      case PlantType.FCU:
      case PlantType.AHU:
        let ratingVal = "";
        if (filled.plant.heatingInletUid) {
          switch (filled.plant.heatingRating.type) {
            case "energy":
              const [eUnits, eValue] = convertMeasurementSystem(
                this.document.drawing.metadata.units,
                Units.KiloWatts,
                filled.plant.heatingRating.KW,
                Precision.DISPLAY,
                UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
              );
              ratingVal += eValue
                ? `\n\u2600 ${eValue} ${eUnits}`
                : `\n\u2600 ? ${eUnits}`;
              break;
            case "flow-rate":
              const [fUnits, fValue] = convertMeasurementSystem(
                this.document.drawing.metadata.units,
                Units.LitersPerSecond,
                filled.plant.heatingRating.LS,
                Precision.DISPLAY,
              );
              ratingVal += fValue
                ? `\n\u2600 ${fValue} ${fUnits}`
                : `\n\u2600 ? ${fUnits}`;
              break;
            default:
              assertUnreachable(filled.plant.heatingRating);
          }
        }

        if (filled.plant.chilledInletUid) {
          switch (filled.plant.chilledRating.type) {
            case "energy":
              const [eUnits, eValue] = convertMeasurementSystem(
                this.document.drawing.metadata.units,
                Units.KiloWatts,
                filled.plant.chilledRating.KW,
                Precision.DISPLAY,
                UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
              );
              ratingVal += eValue
                ? `\n\u2744 ${eValue} ${eUnits}`
                : `\n\u2744 ? ${eUnits}`;
              break;
            case "flow-rate":
              const [fUnits, fValue] = convertMeasurementSystem(
                this.document.drawing.metadata.units,
                Units.LitersPerSecond,
                filled.plant.chilledRating.LS,
                Precision.DISPLAY,
              );
              ratingVal += fValue
                ? `\n\u2744 ${fValue} ${fUnits}`
                : `\n\u2744 ? ${fUnits}`;
              break;
            default:
              assertUnreachable(filled.plant.chilledRating);
          }
        }

        return entity.name?.toUpperCase() + " " + ratingVal;
      case PlantType.DUCT_MANIFOLD:
        return (entity.name ?? "MANIFOLD").toUpperCase();
      default:
        assertUnreachable(filled.plant);
    }

    return name.toUpperCase();
  }

  createPreheatAccessories(preheat: PreheatInlet) {
    if (this.entity.plant.type !== PlantType.RETURN_SYSTEM) return;
    this.createPreHeatInletAccessories(preheat);
    this.createPreHeatOutletAccessories(preheat);
  }

  createDualSystemAccessories() {
    if (this.entity.plant.type !== PlantType.AHU_VENT) return;
    const plant = this.entity.plant;

    if (plant.heatingOutletUid) {
      const heatingConns = this.globalStore.getConnections(
        plant.heatingOutletUid,
      );
      if (heatingConns.length === 0) {
        this.createLSVOutletAccessories(
          plant.heatingOutletUid,
          plant.heatingSystemUid,
          plant.heatingHeightAboveFloorM || undefined,
        );
      }
    }

    if (plant.chilledOutletUid) {
      const chilledConns = this.globalStore.getConnections(
        plant.chilledOutletUid,
      );
      if (chilledConns.length === 0) {
        this.createLSVOutletAccessories(
          plant.chilledOutletUid,
          plant.chilledSystemUid,
          plant.chilledHeightAboveFloorM || undefined,
        );
      }
    }
  }

  getHoverSiblings() {
    return [];
  }

  getPopupContent() {
    const result: EntityPopupContent[] = [];
    const liveCalcs = this.globalStore.getOrCreateLiveCalculation(this.entity);
    for (const warning of liveCalcs.warnings) {
      switch (warning.type) {
        case "FLOW_SYSTEM_NOT_CONNECTED_TO_PLANT":
          if (
            isLiveWarningVisible({
              warning,
              drawingLayout: this.document.uiState.drawingLayout,
            })
          ) {
            result.push(LiveWarnings.UNCONNECTED_PLANT);
          }
          break;
      }
    }
    if (liveCalcs.problemSystemNodes.length > 0) {
      result.push(LiveWarnings.HEAT_EMITTER_CONNECTED_WRONG_WAY);
    }
    return result;
  }

  private createPreHeatOutletAccessories(preheat: PreheatInlet) {
    const plant = this.entity.plant as ReturnSystemPlant;

    const newLsvPosition = this.inletPositionWorld(
      chooseByUnitsMetric(
        this.drawing.metadata.units,
        {
          [Units.Millimeters]: PREHEAT_PIPE_EXTENSION_MM / 2,
          [Units.Inches]: PREHEAT_PIPE_EXTENSION_IN / 2,
        },
        UnitsContext.NONE,
      )[1],
      preheat.returnUid,
    );

    const outletObject = this.globalStore.get(
      preheat.returnUid,
    )! as DrawableSystemNode;

    const newLsv: DirectedValveEntity = createBareValveEntity(
      {
        entityType: EntityType.DIRECTED_VALVE,
        catalogId: "trv", // TODO: lsv catalog entry
        valveType: ValveType.LSV,
        valveSizeMM: PREHEAT_VALVE_SIZE,
      },
      newLsvPosition,
    );

    const newOutletPipe = makeConduitEntity({
      fields: {
        systemUid: plant.preheatSystemUid || StandardFlowSystemUids.Heating,
        heightAboveFloorM: preheat.heightAboveFloorM ?? 0.1,
        endpointUid: [newLsv.uid, preheat.returnUid],
        conduitType: "pipe",
        conduit: {
          network: DEFAULT_HORIZONTAL_SYSTEM_NETWORKS["mechanical"],
        },
      },
      objectStore: this.globalStore, // needed to determine configurationCosmetic
      extendedFrom: outletObject.entity,
    });

    store.dispatch("document/addEntity", newLsv);
    store.dispatch("document/addEntity", newOutletPipe);
  }

  private createPreHeatInletAccessories(preheat: PreheatInlet) {
    const plant = this.entity.plant as ReturnSystemPlant;

    const newValvePosition = this.inletPositionWorld(
      PREHEAT_PIPE_EXTENSION_MM,
      preheat.inletUid,
    );

    const newValve = (() => {
      const { returnSystemType } = plant;
      switch (returnSystemType) {
        case ReturnSystemType.DHW_CYLINDER:
        case ReturnSystemType.DHW_CYLINDER_W_STORAGE:
          const newDiverterValve: MultiwayValveEntity = createBareValveEntity(
            {
              entityType: EntityType.MULTIWAY_VALVE,
              valveType: "diverter-valve",
              valveSizeMM: PREHEAT_VALVE_SIZE,
            },
            newValvePosition,
          );
          return newDiverterValve;
        case ReturnSystemType.AIR_SOURCE_HEAT_PUMP:
        case ReturnSystemType.HEAT_SOURCE:
        case ReturnSystemType.GROUND_SOURCE_HEAT_PUMP:
        case ReturnSystemType.GAS_BOILER:
        case ReturnSystemType.HEADER:
        case ReturnSystemType.BUFFER_TANK:
        case ReturnSystemType.CHILLER:
        case ReturnSystemType.COOLING_TOWER:
        case ReturnSystemType.HOT_WATER_PLANT_W_RETURN:
        case ReturnSystemType.CUSTOM:
          const newTrv: DirectedValveEntity = createBareValveEntity(
            {
              entityType: EntityType.DIRECTED_VALVE,
              valveType: ValveType.TRV,
              catalogId: "trv",
              valveSizeMM: PREHEAT_VALVE_SIZE,
            },
            newValvePosition,
          );
          return newTrv;
        default:
          assertUnreachableAggressive(returnSystemType);
      }
    })();

    const inletObject = this.globalStore.get<DrawableSystemNode>(
      preheat.inletUid,
    );

    const newInletPipe = makeConduitEntity({
      fields: {
        systemUid: plant.preheatSystemUid || StandardFlowSystemUids.Heating,
        heightAboveFloorM: preheat.heightAboveFloorM ?? 0.1,
        endpointUid: [newValve.uid, preheat.inletUid],
        conduitType: "pipe",
        conduit: {
          network: DEFAULT_HORIZONTAL_SYSTEM_NETWORKS["mechanical"],
        },
      },
      extendedFrom: inletObject.entity,
      objectStore: this.globalStore, // needed to determine configurationCosmetic
    });

    store.dispatch("document/addEntity", newValve);
    store.dispatch("document/addEntity", newInletPipe);
  }

  private createLSVOutletAccessories(
    outletUid: string,
    systemUid: string,
    heightAboveFloorM?: number,
  ) {
    const newLsvPosition = this.inletPositionWorld(
      chooseByUnitsMetric(
        this.drawing.metadata.units,
        {
          [Units.Millimeters]: PREHEAT_PIPE_EXTENSION_MM / 2,
          [Units.Inches]: PREHEAT_PIPE_EXTENSION_IN / 2,
        },
        UnitsContext.NONE,
      )[1],
      outletUid,
    );

    const outletObject = this.globalStore.get(outletUid)! as DrawableSystemNode;

    const newLsv: DirectedValveEntity = createBareValveEntity(
      {
        entityType: EntityType.DIRECTED_VALVE,
        catalogId: "trv",
        valveType: ValveType.LSV,
        valveSizeMM: PREHEAT_VALVE_SIZE,
      },
      newLsvPosition,
    );

    const newOutletPipe = makeConduitEntity({
      fields: {
        systemUid: systemUid,
        heightAboveFloorM: heightAboveFloorM ?? 0.1,
        endpointUid: [newLsv.uid, outletUid],
        conduitType: "pipe",
        conduit: {
          network: DEFAULT_HORIZONTAL_SYSTEM_NETWORKS["mechanical"],
        },
      },
      objectStore: this.globalStore, // needed to determine configurationCosmetic
      extendedFrom: outletObject.entity,
    });

    store.dispatch("document/addEntity", newLsv);
    store.dispatch("document/addEntity", newOutletPipe);
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    const filled = fillPlantDefaults(this.context, this.entity);
    let width = filled.widthMM!;
    let depth = filled.depthMM!;

    const isCylinder = filled.plant.shape === PlantShapes.CYLINDER;
    if (isCylinder) {
      const diameter = filled.plant.diameterMM!;
      width = diameter;
      depth = diameter;
    }

    return [
      // left
      {
        x: -width / 2 - this.attachmentOffset,
        y: 0,
      },
      // right
      {
        x: width / 2 + this.attachmentOffset,
        y: 0,
      },
      // top
      {
        x: 0,
        y: -depth / 2 - this.attachmentOffset,
      },
      // bottom
      {
        x: 0,
        y: depth / 2 + this.attachmentOffset,
      },
    ];
  }
}
