import { CoreObjectConcrete } from ".";
import { cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import { DamperLiveCalculation } from "../document/calculations-objects/damper-calculation";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import DamperEntity, {
  fillDefaultDamperFields,
} from "../document/entities/damper-entity";
import { EntityType } from "../document/entities/types";
import { CoreTerminus } from "./core-traits/coreTerminus";
import CoreEdge from "./coreEdge";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import { getIdentityCalculationEntityUid } from "./utils";

export const RING_MAIN_HEAD_LOSS_CONSTANT = 1.28;
const Base = CoreTerminus(CoreCalculatableObject<DamperEntity>);
export default class CoreDamper extends Base {
  type: EntityType.DAMPER = EntityType.DAMPER;

  get refPath(): string {
    return `${this.entity.type}`;
  }

  get filledEntity(): DamperEntity {
    return fillDefaultDamperFields(this.context, this.entity);
  }

  getComponentPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }

  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    const e: DamperEntity = cloneSimple(this.entity);
    e.uid = this.getCalculationUid(context);
    e.parentUid = getIdentityCalculationEntityUid(
      context,
      this.entity.parentUid,
    );
    e.edgeUid = this.globalStore
      .get<CoreEdge>(e.edgeUid)
      .getCalculationUid(context);

    return [e];
  }

  collectCalculations(context: CoreContext): CalculationConcrete {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    return {
      cost: context.priceTable.Equipment.Damper,
      breakdown: [
        {
          type: "valve",
          qty: 1,
          path: `Equipment.Damper`,
          pipeSize: 0,
          manufacturer: "generic",
        },
      ],
    };
  }

  getCoreNeighbours(): CoreObjectConcrete[] {
    return [this.globalStore.get(this.entity.edgeUid)];
  }

  collectLiveCalculations(context: CoreContext): DamperLiveCalculation {
    return context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );
  }
}
