import { cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
  PressurePushMode,
} from "../calculations/types";
import { bestVerticalDuctAxialAngleRAD } from "../calculations/ventilation/ducts";
import { DuctManufacturerSpec } from "../catalog/ventilation/ducts";
import { isSewer, isStormwater } from "../config";
import RiserCalculation, {
  RiserLiveCalculation,
  emptyRiserCalculations,
} from "../document/calculations-objects/riser-calculation";
import { addWarning } from "../document/calculations-objects/warnings";
import { Level } from "../document/drawing";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import {
  fillDefaultConduitFields,
  isDuctEntity,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import { FittingEntity } from "../document/entities/fitting-entity";
import RiserEntity, {
  DuctRiserEntity,
  fillRiserDefaults,
  isDuctRiserEntity,
} from "../document/entities/riser-entity";
import { EntityType } from "../document/entities/types";
import { VentilationFlowSystem } from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import { I18N } from "../locale/values";
import { CoreConnectable } from "./core-traits/coreConnectable";
import CoreConduit from "./coreConduit";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import { levelIncludesRiser } from "./lib/utils";

export default class CoreRiser extends CoreConnectable(
  CoreCalculatableObject<RiserEntity>,
) {
  type: EntityType.RISER = EntityType.RISER;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.riserType}`;
  }

  get filledEntity(): RiserEntity {
    return fillRiserDefaults(this.context, this.entity);
  }

  getComponentPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("This entity shouldn't be part of calculations");
  }

  getRiserCalculationTower(context: CoreContext, forRiser: boolean = false) {
    const tower = this.getCalculationTower(context, forRiser);
    for (const entities of tower) {
      if (entities.length > 1) {
        if (isPipeEntity(entities[1]!) || isDuctEntity(entities[1]!)) {
          entities[1]!.conduit.material = this.entity.riser.material;
          entities[1]!.conduit.diameterMM = this.entity.riser.diameterMM;
        }
      }
    }

    // do a dumb 2d version of rotation alignment for now.
    const angles = this.getSortedAnglesRAD().angles;
    const bestAngle = bestVerticalDuctAxialAngleRAD(context, angles);

    for (const segment of tower) {
      if (segment.length > 1) {
        segment[1]!.calculationAxialRotDEG = (bestAngle * 180) / Math.PI;
      }
    }

    if (this.entity.riserType === "duct") {
      if (this.entity.riser.shape) {
        for (const segment of tower) {
          if (segment.length > 1 && segment[1]!.conduitType === "duct") {
            segment[1]!.conduit.shape = this.entity.riser.shape;
          }
        }
      }
    }

    return tower;
  }

  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    return this.getRiserCalculationTower(context).flat();
  }

  // Collect all calculation object result into RiserCalculation object
  // and store it in globalStore.
  collectCalculations(context: CoreContext): RiserCalculation {
    const res = emptyRiserCalculations();
    const iAmSewer = isSewer(
      context.drawing.metadata.flowSystems[this.entity.systemUid],
    );

    const iAmStormwater = isStormwater(
      context.drawing.metadata.flowSystems[this.entity.systemUid],
    );

    // For determine showing result in bottom and floor
    // Drainage have a hacking solution, so it doesn't show actual height
    const tower = this.getRiserCalculationTower(context, true);

    // For determine actual height
    const towerForHeights = this.getCalculationTower(context, false);

    const levels = context.drawing.levels;
    const levelUidsByHeight = Object.keys(context.drawing.levels).sort(
      (a, b) => {
        return (
          context.drawing.levels[a].floorHeightM -
          context.drawing.levels[b].floorHeightM
        );
      },
    );

    // Fill with bottomHeightM, topHeightM by the connection riser have
    // If there is less equal to one connection, mark both field to be default (null)
    if (towerForHeights.length > 1) {
      let connectionHeightsM = towerForHeights.map((ele) => {
        return ele[0].calculationHeightM;
      });
      let isNum = (num: number | null): num is number => {
        return num !== null;
      };
      connectionHeightsM = connectionHeightsM.filter(isNum);
      connectionHeightsM.sort((num1, num2) => Number(num1) - Number(num2));

      res.topHeightM = connectionHeightsM[connectionHeightsM.length - 1];
      res.bottomHeightM = connectionHeightsM[0];
    }

    // Find size of base vent for dedicated vent sizing
    let biggestDedicatedVentSize: number | null = null;
    if (iAmSewer) {
      for (const segment of tower) {
        const pipe = segment[1];
        if (pipe) {
          switch (pipe.conduitType) {
            case "pipe":
              const pcalc = context.globalStore.getOrCreateCalculation(pipe);
              if (
                pcalc.stackDedicatedVentSize !== null &&
                (biggestDedicatedVentSize === null ||
                  pcalc.stackDedicatedVentSize > biggestDedicatedVentSize)
              ) {
                biggestDedicatedVentSize = pcalc.stackDedicatedVentSize;
              }
              break;
            case "duct":
            case "cable":
              throw new Error("Duct and cable risers not implemented yet");
              break;
          }
        }
      }
    }

    // Collect all levels together
    let topOfPipe = 0;
    let isVentExit = false;
    for (const lvlUid of levelUidsByHeight) {
      res.heights[lvlUid] = {
        reference: undefined,
        flowRateLS: null,
        heightAboveGround: null,
        psdUnits: null,
        pressureKPA: null,
        staticPressureKPA: null,
        sizeMM: null,
        axialRotDEG: null,
        shape: null,
        widthMM: null,
        heightMM: null,
        ventSizeMM: null,
        velocityRealMS: null,
        isVentExit: null,
      };

      // iterate pipe if need be. Note, we don't want to go over.
      while (
        topOfPipe + 1 < tower.length &&
        tower[topOfPipe][0].calculationHeightM! <= levels[lvlUid].floorHeightM
      ) {
        const fittingCalc = context.globalStore.getOrCreateCalculation(
          tower[topOfPipe][0] as FittingEntity,
        );
        isVentExit = isVentExit || !!fittingCalc.isVentExit;
        topOfPipe++;
      }

      if (topOfPipe < tower.length) {
        const fittingCalc = context.globalStore.getOrCreateCalculation(
          tower[topOfPipe][0] as FittingEntity,
        );
        isVentExit = isVentExit || !!fittingCalc.isVentExit;
      }

      res.heights[lvlUid].isVentExit = isVentExit;

      if (
        topOfPipe > 0 &&
        tower[topOfPipe][0].calculationHeightM! >= levels[lvlUid].floorHeightM
      ) {
        const entity = tower[topOfPipe][1]!;

        const calc = context.globalStore.getOrCreateCalculation(entity);

        const pipe = context.globalStore.get<CoreConduit>(entity.uid);

        const totalPressureDrop = pipe.getFrictionPressureLossKPA({
          context,
          flowLS: calc.totalPeakFlowRateLS!,
          from: {
            connectable: tower[topOfPipe - 1][0].uid,
            connection: pipe.uid,
          },
          to: { connectable: tower[topOfPipe][0].uid, connection: pipe.uid },
          signed: true,
          pressurePushMode: PressurePushMode.PSD,
        });

        let sizeMM: number | null = null;
        let widthMM: number | null = null;
        let heightMM: number | null = null;
        let axialRotDEG: number | null = null;
        let shape: "circular" | "rectangular" | null = null;
        switch (entity.conduitType) {
          case "pipe": {
            const calc = context.globalStore.getOrCreateCalculation(entity);
            sizeMM = calc.realNominalPipeDiameterMM;
            break;
          }
          case "duct": {
            const ductCalc = context.globalStore.getOrCreateCalculation(entity);
            sizeMM = ductCalc.diameterMM;
            widthMM = ductCalc.widthMM;
            heightMM = ductCalc.heightMM;
            const filled = fillDefaultConduitFields(context, entity);
            shape = filled.conduit.shape;
            break;
          }
        }
        axialRotDEG = entity.calculationAxialRotDEG ?? null;

        if (totalPressureDrop.pressureLossKPA != null && !iAmSewer) {
          const totalLength =
            tower[topOfPipe][0].calculationHeightM! -
            tower[topOfPipe - 1][0].calculationHeightM!;
          const partialLength =
            levels[lvlUid].floorHeightM -
            tower[topOfPipe - 1][0].calculationHeightM!;
          const partialPressureDropKPA =
            totalPressureDrop.pressureLossKPA * (partialLength / totalLength);

          const bottomPipeCalc = context.globalStore.getOrCreateCalculation(
            tower[topOfPipe - 1][0],
          );
          const bottomPressure = bottomPipeCalc.pressureKPA;
          const bottomStaticPressure = bottomPipeCalc.staticPressureKPA;

          res.heights[lvlUid] = {
            reference: calc.reference,
            flowRateLS: calc.totalPeakFlowRateLS,
            heightAboveGround: levels[lvlUid].floorHeightM,
            psdUnits: calc.psdUnits,
            pressureKPA: bottomPressure
              ? bottomPressure
              : 0 - partialPressureDropKPA,
            staticPressureKPA: bottomStaticPressure
              ? bottomStaticPressure
              : 0 - partialPressureDropKPA,
            sizeMM,
            widthMM,
            heightMM,
            shape,
            axialRotDEG,
            ventSizeMM: null,
            velocityRealMS: calc.velocityRealMS,
            isVentExit: res.heights[lvlUid].isVentExit,
          };
        } else if (iAmSewer || iAmStormwater) {
          res.heights[lvlUid] = {
            reference: calc.reference,
            flowRateLS: iAmSewer ? null : calc.totalPeakFlowRateLS,
            heightAboveGround: levels[lvlUid].floorHeightM,
            psdUnits: calc.psdUnits,
            pressureKPA: null,
            staticPressureKPA: null,
            sizeMM,
            widthMM,
            heightMM,
            shape,
            axialRotDEG,
            ventSizeMM: biggestDedicatedVentSize,
            velocityRealMS: calc.velocityRealMS,
            isVentExit: res.heights[lvlUid].isVentExit,
          };
        }
      }
    }

    if (iAmSewer) {
      const system = getFlowSystem<"sewer">(
        context.drawing,
        this.entity.systemUid,
      );
      if (system) {
        const overFlowedLevels: string[] = [];
        // Do warnings of max thing per level

        for (let i = 0; i < levelUidsByHeight.length - 1; i++) {
          const thisLvlUid = levelUidsByHeight[i];
          const nextLvlUid = levelUidsByHeight[i + 1];
          const levelRes = res.heights[thisLvlUid];
          const nextLevelRes = res.heights[nextLvlUid];

          if (!levelRes || !nextLevelRes) {
            continue;
          }

          let drainageUnitsLimit = 1e10;
          for (const sizing of system.stackPipeSizing) {
            if (sizing.sizeMM === levelRes.sizeMM) {
              drainageUnitsLimit = sizing.maximumUnitsPerLevel;
            }
          }

          const drainageUnits =
            (levelRes.psdUnits?.drainageUnits || 0) -
            (nextLevelRes.psdUnits?.drainageUnits || 0);

          if (drainageUnits > drainageUnitsLimit) {
            overFlowedLevels.push(context.drawing.levels[thisLvlUid].name);
          }
        }

        if (overFlowedLevels.length > 0) {
          addWarning(this.context, "MAX_PER_LEVEL_EXCEEDED", [this.entity], {
            mode: "drainage",
            params: {
              value: I18N.loadingUnits[context.locale],
              level: overFlowedLevels.join(", "),
            },
          });
        }
      }
    }

    return res;
  }

  collectLiveCalculations(context: CoreContext): RiserLiveCalculation {
    const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
      this.entity,
    );
    const standardCalcs = this.collectCalculations(context);
    const res: RiserLiveCalculation = cloneSimple(liveCalcs);
    for (const lvlUid of Object.keys(standardCalcs.heights)) {
      res.heights[lvlUid] = {
        isVentExit: standardCalcs.heights[lvlUid].isVentExit,
      };
    }
    return res;
  }

  static getDuctManufacturerCatalogPage(
    context: CoreContext,
    entity: DuctRiserEntity,
  ): DuctManufacturerSpec | null {
    const { drawing, catalog } = context;
    if (isDuctRiserEntity(entity)) {
      const system = drawing.metadata.flowSystems[
        entity.systemUid
      ] as VentilationFlowSystem;
      const network = system.networks["risers"];
      const material = entity.riser.material || network.material;

      const page = catalog.ducts[material];
      if (!page) {
        return null;
      }
      const manufacturer =
        drawing.metadata.catalog.ducts.find((m) => m.uid === material)
          ?.manufacturer || "generic";

      return page.data[manufacturer] || null;
    }
    return null;
  }

  // Here, risers are turned into normal pipes for calculations, and so this function shouldn't be called.
  costBreakdown(context: CoreContext): CostBreakdown {
    // TODO: Riser cost for a level.
    return { cost: 0, breakdown: [] };
  }
  getCalculationUid(context: CoreContext): string {
    return this.getCalculationEntities(context)[0].uid;
  }
  preCalculationValidation(context: CoreContext) {
    return null;
  }

  getRiserLevels(): Level[] {
    const riserLevels: Level[] = [];
    const sortedLevels = Object.values(this.context.drawing.levels).sort(
      (a, b) => b.floorHeightM - a.floorHeightM,
    );

    for (const level of sortedLevels) {
      if (levelIncludesRiser(level, this.entity, sortedLevels)) {
        riserLevels.push(level);
      }
    }

    return riserLevels;
  }

  isVent() {
    return this.entity.riserType === "pipe" && this.entity.riser.isVent;
  }
}
