import { Precision, Units, UnitsContext } from "../../../../lib/measurements";
import { assertType, assertUnreachable } from "../../../../lib/utils";
import { createEntryWithUnit } from "../../../calculations/heatloss/heat-loss-result-type";
import { ReturnCalculations } from "../../../calculations/returns";
import { CoreContext } from "../../../calculations/types";
import {
  getManufacturersByPlant,
  hasCustomManufacturers,
} from "../../../catalog/manufacturers/utils";
import CorePlant from "../../../coreObjects/corePlant";
import CoreSystemNode from "../../../coreObjects/coreSystemNode";
import { getTooltip } from "../../../tooltips/tooltips";
import { DrawableEntityConcrete } from "../concrete-entity";
import { FieldType, PropertyField } from "../property-field";
import { EntityType } from "../types";
import { ConditionalPropertyField, filterConditionalFields } from "../utils";
import { getCustomManufPropertyFields } from "./customManufFields";
import { RadiatorPlantEntity, plantHasSingleRating } from "./plant-entity";
import {
  PlantShapes,
  PlantType,
  PressureMethod,
  RadiatorBase,
  RadiatorManufacturer,
  RadiatorPlant,
  SpecifyRadiatorPlant,
} from "./plant-types";
import {
  AvailableRadiatorSizes,
  getHeatSourceOutletDrawableObjectUidFromNetworkObjectUid,
  getHotWaterOutletInfo,
  getPlantModelChoices,
  getRadiatorAvailableSizes,
  getRadiatorRangeTypeOptions,
  isHotWaterOutlet,
  isRadiatorPlant,
  isReadonly,
} from "./utils";

export function createRadiatorModelField(
  filled: RadiatorPlantEntity,
  context: CoreContext,
  inARoom: boolean,
): ConditionalPropertyField {
  const isManufacturerRadiator =
    filled.plant.radiatorType === "specify" &&
    filled.plant.manufacturer !== "generic";

  const models = getPlantModelChoices(filled.plant, context.catalog, {
    ...(filled.plant.radiatorType === "specify"
      ? { rangeType: filled.plant.rangeType }
      : {}),
  });

  return {
    property: "plant.model",
    title: "Model",
    hasDefault: false,
    isCalculated: inARoom,
    type: FieldType.Choice,
    multiFieldId: "model",
    params: {
      initialValue: (filled.plant as SpecifyRadiatorPlant).model || "",
      choices: models.map((m) => ({
        name: m,
        key: m,
      })),
    },
    isShown: isManufacturerRadiator && models.length > 0,
    beforeSet: (newVal: string) => {
      const obj = context.globalStore.get<CorePlant>(filled.uid);
      const ent = obj.entity as RadiatorPlantEntity;
      if (ent.plant.radiatorType === "specify") {
        ent.plant = {
          ...ent.plant,
          widthMM: {
            type: "upper",
            value: null,
          },
          heightMM: {
            type: "upper",
            value: null,
          },
          depthMM: null,
          model: newVal,
        };
        ent.widthMM = null;
        ent.depthMM = null;
      }
      return newVal;
    },
  };
}

export function createRadiatorDimensionsTabFields(
  filled: RadiatorPlantEntity,
  radiatorPlant: RadiatorPlant,
  context: CoreContext,
  readOnly: boolean,
): PropertyField[] {
  const { drawing } = context;

  // 3 types of radiators
  const isFixedRadiator = filled.plant.radiatorType === "fixed";

  const isGenericSpecifyRadiator =
    filled.plant.radiatorType === "specify" &&
    filled.plant.manufacturer === "generic";

  const isManufacturerRadiator =
    filled.plant.radiatorType === "specify" &&
    filled.plant.manufacturer !== "generic";

  const hasRadiatorModel =
    isManufacturerRadiator &&
    (radiatorPlant as SpecifyRadiatorPlant).model !== null;

  const isDynamicSizingRadiator =
    !isFixedRadiator &&
    (filled.plant as SpecifyRadiatorPlant).widthMM.type !== "exact";

  const radiator = context.globalStore.get<CorePlant>(filled.uid).entity
    .plant as RadiatorPlant;

  const isDynamicRadiatorSizingByWidth =
    !isFixedRadiator &&
    (radiator as SpecifyRadiatorPlant).widthMM.type === "upper" &&
    (radiator as SpecifyRadiatorPlant).heightMM.value === null;

  const isDynamicRadiatorSizingByHeight =
    !isFixedRadiator &&
    (radiator as SpecifyRadiatorPlant).heightMM.type === "upper" &&
    (radiator as SpecifyRadiatorPlant).widthMM.value === null;

  const rangeTypeOptions = (
    !isFixedRadiator
      ? getRadiatorRangeTypeOptions(
          context,
          (filled.plant as SpecifyRadiatorPlant).manufacturer,
        )
      : []
  ).map((shape) => {
    return {
      key: shape.key,
      name: shape.label,
    };
  });

  const customManufPropertyFields = getCustomManufPropertyFields(
    filled.plant,
    readOnly,
  );

  let radSizes: AvailableRadiatorSizes | null = null;
  if (isManufacturerRadiator) {
    assertType<SpecifyRadiatorPlant>(radiatorPlant);
    radSizes = getRadiatorAvailableSizes(radiatorPlant, context.catalog);
  }

  const plantCalc = context.globalStore.getOrCreateLiveCalculation(filled);
  const inARoom = plantCalc.associatedRoomUid !== null;

  const res: ConditionalPropertyField[] = [
    {
      property: "plant.radiatorType",
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Fixed Rating", key: "fixed" },
          { name: "Specify", key: "specify" },
        ],
      },
      hasDefault: false,
      isCalculated: false,
      multiFieldId: "plant-radiator-type",
      title: "Radiator Type",
      hint: getTooltip("Radiator", "Radiator Type"),
      beforeSet: (newVal: RadiatorBase["radiatorType"]) => {
        const obj = context.globalStore.get<CorePlant>(filled.uid);
        const ent = obj.entity as RadiatorPlantEntity;
        if (ent.plant.radiatorType === "fixed" && newVal === "specify") {
          ent.plant = {
            ...ent.plant,
            model: null,
            manufacturer: "generic",
            radiatorType: "specify",
            rangeType: "11",
            widthMM: {
              type: "exact",
              value: 1000,
            },
            heightMM: {
              type: "exact",
              value: 500,
            },
          };
        } else if (ent.plant.radiatorType === "specify" && newVal === "fixed") {
          // @ts-ignore-next-line
          delete ent.plant.rangeType;
          ent.plant = {
            ...ent.plant,
            radiatorType: "fixed",
            widthMM: 1000,
            heightMM: 500,
          };
        }
      },
      isShown: !isManufacturerRadiator,
    },
    {
      property: "plant.rangeType",
      type: FieldType.Choice,
      params: {
        choices: rangeTypeOptions,
      },
      hasDefault: false,
      isCalculated: false,
      requiresInput: true,
      multiFieldId: "plant-radiator-shape",
      title: isManufacturerRadiator ? "Range" : "Style",
      isShown: !isFixedRadiator && rangeTypeOptions.length > 0,
    },
    ...customManufPropertyFields,
    {
      property: "plant.widthMM.type",
      title: "Sizing Method",
      hint: "Exact Sizing will use the exact dimensions specified below. Upper Bound will use the upper bound of your chosen dimension while the other dimension will be sized based on the required heat rating required by the room and provided heat from the heat pump.",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Exact", key: "exact" },
          { name: "Upper Bound", key: "upper" },
        ],
      },
      multiFieldId: "plant-width-type",
      isShown: isGenericSpecifyRadiator,
      beforeSet: (newValue) => {
        const obj = context.globalStore.get<CorePlant>(filled.uid);
        if (
          isRadiatorPlant(obj.entity.plant) &&
          obj.entity.plant.radiatorType === "specify"
        ) {
          obj.entity.plant = {
            ...obj.entity.plant,
            widthMM: {
              ...obj.entity.plant.widthMM,
              value: 2000,
            },
            heightMM: {
              ...obj.entity.plant.heightMM,
              type: newValue,
              value: 1000,
            },
          };
        }
      },
    },
    {
      property: "plant.heightMM",
      title: "Height",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      readonly: isReadonly(drawing, filled),
      isShown: isFixedRadiator,
    },
    {
      property: "plant.heightMM.value",
      title: isDynamicRadiatorSizingByHeight
        ? "Height (upper limit)"
        : "Height",
      hasDefault: !isDynamicSizingRadiator,
      isCalculated: isDynamicSizingRadiator && !isDynamicRadiatorSizingByHeight,
      requiresInput: isDynamicRadiatorSizingByHeight,
      type: FieldType.Number,
      params: { min: isDynamicRadiatorSizingByHeight ? 50 : 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      readonly:
        isReadonly(drawing, filled) ||
        (isDynamicSizingRadiator && !isDynamicRadiatorSizingByHeight),
      isShown: !isFixedRadiator && !isManufacturerRadiator,
    },
    {
      property: "computedHeightMM",
      title: "Height",
      slot: true,
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "computedHeightMM",
      units: Units.Millimeters,
      readonly: true,
      isShown:
        isDynamicSizingRadiator &&
        isDynamicRadiatorSizingByHeight &&
        !isManufacturerRadiator,
    },
    {
      property: "widthMM",
      title: isDynamicRadiatorSizingByWidth ? "Length (upper limit)" : "Length",
      hasDefault: !isDynamicSizingRadiator,
      isCalculated: isDynamicSizingRadiator && !isDynamicRadiatorSizingByWidth,
      type: FieldType.Number,
      params: { min: isDynamicRadiatorSizingByWidth ? 50 : 0, max: null },
      requiresInput: isDynamicRadiatorSizingByWidth,
      multiFieldId: null,
      units: Units.Millimeters,
      readonly:
        isReadonly(drawing, filled) ||
        (isDynamicSizingRadiator && !isDynamicRadiatorSizingByWidth),
      isShown:
        filled.plant.shape === PlantShapes.RECTANGULAR &&
        !isManufacturerRadiator,
    },
    {
      property: "computedWidthMM",
      title: "Length",
      slot: true,
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "computedWidthMM",
      units: Units.Millimeters,
      readonly: true,
      isShown:
        isDynamicSizingRadiator &&
        isDynamicRadiatorSizingByWidth &&
        !isManufacturerRadiator,
    },
    {
      property: `plant.heightMM.value`,
      title: "Height",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      multiFieldId: "plant-heightMM-value",
      params: {
        choices:
          radSizes?.heightMM.map((w) => ({
            key: w.value,
            name: createEntryWithUnit(
              context.drawing.metadata.units,
              w.value,
              Units.Millimeters,
              Precision.DISPLAY,
              UnitsContext.NONE,
            ),
            softDisabled: !w.available,
          })) || [],
      },
      beforeSet: (newVal: number) => {
        const size = radSizes?.heightMM.find((h) => h.value === newVal);
        if (size && !size.available) {
          const obj = context.globalStore.get<CorePlant>(filled.uid);
          assertType<SpecifyRadiatorPlant>(obj.entity.plant);
          obj.entity.widthMM = null;
        }
      },
      isShown: isManufacturerRadiator && inARoom,
      readonly: hasRadiatorModel,
    },
    {
      property: `widthMM`,
      title: "Length",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      multiFieldId: "plant-widthMM-value",
      params: {
        choices:
          radSizes?.widthMM.map((w) => ({
            key: w.value,
            name: createEntryWithUnit(
              context.drawing.metadata.units,
              w.value,
              Units.Millimeters,
              Precision.DISPLAY,
              UnitsContext.NONE,
            ),
            softDisabled: !w.available,
          })) || [],
      },
      beforeSet: (newVal: number) => {
        const size = radSizes?.widthMM.find((w) => w.value === newVal);
        if (size && !size.available) {
          const obj = context.globalStore.get<CorePlant>(filled.uid);
          assertType<SpecifyRadiatorPlant>(obj.entity.plant);
          obj.entity.plant.heightMM.value = null;
        }
      },
      isShown: isManufacturerRadiator && inARoom,
      readonly: hasRadiatorModel,
    },
    {
      property: "depthMM",
      title: "Depth",
      hasDefault: !readOnly,
      isCalculated: readOnly,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      isShown: inARoom,
      readonly: readOnly,
    },
    createRadiatorModelField(filled, context, inARoom),
  ];
  return filterConditionalFields(res);
}

export function getRadiatorHeatSourceLabelOptions(
  context: CoreContext,
  r: DrawableEntityConcrete,
): string {
  if (!r.parentUid) return "";
  const systemNode = context.globalStore.get<CoreSystemNode>(r.uid);
  const [outlet, index] = getHotWaterOutletInfo(context, systemNode);
  if (!outlet || index == -1) return "";
  const temperatureFormatted = `${createEntryWithUnit(
    context.drawing.metadata.units,
    outlet.outletTemperatureC,
    Units.Celsius,
    0,
    UnitsContext.NONE,
  )} / ${createEntryWithUnit(
    context.drawing.metadata.units,
    outlet.returnLimitTemperatureC,
    Units.Celsius,
    0,
    UnitsContext.NONE,
  )}`;
  const parent = context.globalStore.get<CorePlant>(r.parentUid);
  let name = parent.entity.name;
  if (
    "plant" in parent.entity &&
    "model" in parent.entity.plant &&
    parent.entity.plant.model
  ) {
    name += ` (${parent.entity.plant.model})`;
  }
  const outletName = index >= 1 ? `Outlet Group ${index}` : "Outlet";
  return `${temperatureFormatted} - ${name} ${outletName}`;
}

export function createRadiatorTechnicalTabFields(
  filled: RadiatorPlantEntity,
  context: CoreContext,
  readOnly: boolean,
): PropertyField[] {
  // 3 types of radiators
  const isFixedRadiator = filled.plant.radiatorType === "fixed";

  const specifyRadiator = filled.plant.radiatorType === "specify";

  const isPressureMethodFixedPressureLoss =
    filled.plant.pressureLoss.pressureMethod ===
    PressureMethod.FIXED_PRESSURE_LOSS;
  const isPressureMethodKvValue =
    filled.plant.pressureLoss.pressureMethod ===
    PressureMethod.KV_PRESSURE_LOSS;
  const manufacturers = getManufacturersByPlant(filled.plant, context.catalog);

  const plantCalc = context.globalStore.getOrCreateLiveCalculation(filled);
  const inARoom = plantCalc.associatedRoomUid !== null;

  const liveCalcs = context.globalStore.getOrCreateLiveCalculation(filled);
  const isConnected = liveCalcs.connected === true;

  const res: ConditionalPropertyField[] = [
    {
      property: "plant.manufacturer",
      title: "Manufacturer",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Choice,
      params: {
        choices: manufacturers.map((m) => ({
          name: m.name,
          key: m.uid,
        })),
      },
      multiFieldId: "manufacturer",
      isShown: !isFixedRadiator && hasCustomManufacturers(manufacturers),
      beforeSet: (newVal: RadiatorManufacturer) => {
        const obj = context.globalStore.get<CorePlant>(filled.uid);
        const ent = obj.entity as RadiatorPlantEntity;
        switch (newVal) {
          case "generic":
            // @ts-ignore
            delete ent.plant.customManufFields;
            const genericRadiator: SpecifyRadiatorPlant = {
              ...ent.plant,
              manufacturer: "generic",
              model: null,
              rangeType: "11",
              widthMM: {
                type: "upper",
                value: 1000,
              },
              heightMM: {
                type: "upper",
                value: 500,
              },
              radiatorType: "specify",
            };
            ent.plant = genericRadiator;
            break;
          default:
            const manufacturerRadiator: SpecifyRadiatorPlant = {
              ...ent.plant,
              manufacturer: newVal,
              model: null,
              rangeType: getRadiatorRangeTypeOptions(context, newVal)[0].key,
              widthMM: {
                type: "upper",
                value: 1000,
              },
              heightMM: {
                type: "upper",
                value: 500,
              },
              pressureLoss: {
                pressureMethod: PressureMethod.KV_PRESSURE_LOSS,
                kvValue: null,
              },
              radiatorType: "specify",
            };
            ent.plant = manufacturerRadiator;
            switch (ent.plant.rating.type) {
              case "energy":
                ent.plant.rating.KW = null;
                break;
              case "flow-rate":
                ent.plant.rating.LS = null;
                break;
              default:
                assertUnreachable(ent.plant.rating);
                break;
            }
            break;
        }
      },
    },
    createRadiatorModelField(filled, context, inARoom),
    {
      property: "plant.rating.type",
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Power", key: "energy" },
          { name: "Flow Rate", key: "flow-rate" },
        ],
      },
      hasDefault: false,
      isCalculated: false,
      multiFieldId: "plant-rating-type",
      title: "Rating Method",
      hint: getTooltip("Radiator", "Rating Method"),
      beforeSet: (newVal) => {
        const liveCalcs =
          context.globalStore.getOrCreateLiveCalculation(filled);
        const obj = context.globalStore.get<CorePlant>(filled.uid);
        const plant = obj.entity.plant as RadiatorPlant;

        if (newVal === "flow-rate") {
          if (plant.rating.type === "energy") {
            if (
              liveCalcs.returnDeltaC != null &&
              liveCalcs.returnAverageC != null &&
              plant.rating.KW != null
            ) {
              plant.rating = {
                ...plant.rating,
                type: "flow-rate",
                LS: ReturnCalculations.KW2LS(
                  context,
                  obj.entity.inletSystemUid,
                  plant.rating.KW,
                  liveCalcs.returnDeltaC,
                  liveCalcs.returnAverageC,
                ),
              };
            } else {
              plant.rating = {
                ...plant.rating,
                type: "flow-rate",
                LS: null,
              };
            }
          }
        } else if (newVal === "energy") {
          if (plant.rating.type === "flow-rate") {
            if (
              liveCalcs.returnDeltaC != null &&
              liveCalcs.returnAverageC != null &&
              plant.rating.LS != null
            ) {
              plant.rating = {
                ...plant.rating,
                type: "energy",
                KW: ReturnCalculations.LS2KW(
                  context,
                  obj.entity.inletSystemUid,
                  plant.rating.LS,
                  liveCalcs.returnDeltaC,
                  liveCalcs.returnAverageC,
                ),
              };
            } else {
              plant.rating = {
                ...plant.rating,
                type: "energy",
                KW: null,
              };
            }
          }
        }
      },
      isShown: true,
    },
    {
      property: "plant.rating.KW",
      title: "Rating",
      hasDefault: false,
      isCalculated: readOnly,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
        initialValue: 0,
      },
      multiFieldId: "plant-ratingKW",
      units: Units.KiloWatts,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      isShown: true,
      readonly: readOnly,
    },
    {
      property: "plant.rating.LS",
      title: "Flow Rate Rating",
      hasDefault: false,
      isCalculated: readOnly,
      requiresInput: true,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
      },
      multiFieldId: "plant-ratingLS",
      units: Units.LitersPerSecond,
      isShown:
        plantHasSingleRating(filled.plant) &&
        filled.plant.rating.type === "flow-rate",
      readonly: readOnly,
    },
    {
      property: "plant.capacityRateLKW",
      title: "Volume Rate at Delta T50",
      hint: getTooltip("Radiator", "Capacity Delta T50"),
      hasDefault: false,
      requiresInput: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "capacityRateLKW",
      units: Units.LitersPerKiloWatts,
      isShown: isFixedRadiator,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.volumeL",
      title: "Volume",
      hint: !isFixedRadiator ? getTooltip("Radiator", "Volume") : "",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "volumeL",
      units: Units.Liters,
      isShown: true,
    },
    {
      property: "plant.pressureLoss.pressureLossKPA",
      title: "Pressure Loss",
      hint: getTooltip("Radiator", "Pressure Drop"),
      hasDefault: filled.plant.type !== PlantType.RADIATOR,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-pressureLoss-pressureLossKPA",
      units: Units.KiloPascals,
      isShown: isPressureMethodFixedPressureLoss,
    },
    {
      property: "plant.pressureLoss.kvValue",
      title: "Pressure Loss KV Value",
      hint: getTooltip("Radiator", "KV Value"),
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-pressureLoss-kvValue",
      units: Units.None,
      isShown: isPressureMethodKvValue,
    },
    {
      property: "plant.heatSourceOutletUid",
      title: "Heat Source",
      hint: getTooltip("Radiator", "Heat Source Outlet No Selection"),
      hasDefault: false,
      isCalculated: false,
      type: FieldType.DisplayTextOnly,
      params: {
        text: "Using system default flow temperature",
      },
      multiFieldId: "plant-flowSource",
      isShown: !isConnected && liveCalcs.heatSourceOutletUid === null,
    },
    {
      property: "plant.heatSourceOutletUid",
      title: "Heat Source",
      hint: getTooltip("Radiator", "Heat Source Outlet Selection"),
      hasDefault: false,
      isCalculated: false,
      type: FieldType.EntityPicker,
      params: {
        type: "single",
        levelUid: null,
        entityTypes: [EntityType.SYSTEM_NODE],
        filter: (r) => {
          const systemNode = context.globalStore.get<CoreSystemNode>(r.uid);
          return isHotWaterOutlet(context, systemNode);
        },
        getOptionName: (r) => {
          return getRadiatorHeatSourceLabelOptions(context, r);
        },
        getLabelName: (r) => {
          return getRadiatorHeatSourceLabelOptions(context, r);
        },
      },
      multiFieldId: "plant-flowSource",
      isShown: !isConnected && liveCalcs.heatSourceOutletUid !== null,
      defaultValue: () => {
        const networkObjectUid = liveCalcs.heatSourceOutletUid;
        const drawableUid =
          getHeatSourceOutletDrawableObjectUidFromNetworkObjectUid(
            context,
            networkObjectUid,
          );
        return drawableUid;
      },
    },
  ];
  return filterConditionalFields(res);
}
