import { Primitive } from "./primitive";

/**
 * A simple implementation of a MultiMap.
 **
 * MultiMaps always return a value for `get` calls, even if there are no entries for a given key.
 */
export class MultiMap<K extends Primitive, V> {
  private map: Map<K, V[]> = new Map();

  get(key: K): V[] {
    const current = this.map.get(key);
    if (!current) {
      const newValue: V[] = [];
      this.map.set(key, newValue);
      return newValue;
    }
    return current;
  }

  add(key: K, ...value: V[]): void {
    if (value.length) {
      this.get(key).push(...value);
    }
  }

  /**
   * Important: This is number of entries, not the total number of values.
   */
  length(): number {
    return this.map.size;
  }

  entries(): IterableIterator<[K, V[]]> {
    return this.map.entries();
  }

  values(): IterableIterator<V[]> {
    return this.map.values();
  }

  keys(): IterableIterator<K> {
    return this.map.keys();
  }
}
