import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import CoreWorldObject from "../../../../common/src/api/coreObjects/lib/coreWorldObject";
import { decomposeMatrix } from "../../../../common/src/api/coreObjects/lib/utils";
import { Color } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import CanvasContext from "../../../src/htmlcanvas/lib/canvas-context";
import { DrawingContext } from "../../../src/htmlcanvas/lib/types";
import { MouseMoveResult, UNHANDLED } from "../../../src/htmlcanvas/types";
import { UIState } from "../../store/document/types";
import { HeatmapMode } from "./heatmap/heatmap";

export interface IDrawable {
  draggable: boolean;
  centered: boolean;
  calculated: boolean;
  sizable: boolean;
  snapHoverTimeoutMS: number;

  drawInternal(context: DrawingContext, args: DrawingArgs): void;

  draw(context: DrawingContext, args: DrawingArgs): void;

  get world2object(): TM.Matrix[];
  get selectable(): boolean;
  drawOwnShape(context: DrawingContext): void;
  withScreen(
    { ctx, vp }: DrawingContext,
    current: Coord,
    fun: () => void,
  ): void;
  withScreenScale(
    { ctx, vp }: DrawingContext,
    current: Coord,
    fun: () => void,
  ): void;
  withScreenScale(
    { ctx, vp }: DrawingContext,
    current: Coord,
    fun: () => void,
  ): void;
  withWorld({ ctx, vp }: DrawingContext, current: Coord, fun: () => void): void;
  withWorldScale(
    { ctx, vp }: DrawingContext,
    current: Coord,
    fun: () => void,
  ): void;
  withWorldAngle(
    { ctx, vp }: DrawingContext,
    current: Coord,
    fun: () => void,
  ): void;
  inBounds(objectCoord: Coord, objectRadius?: number): boolean;
  inBounds(objectCoord: Coord, objectRadius?: number): boolean;
  onMouseDown(event: MouseEvent, context: CanvasContext): boolean;
  onMouseMove(event: MouseEvent, context: CanvasContext): MouseMoveResult;
  onMouseUp(event: MouseEvent, context: CanvasContext): boolean;
}

export function MakeDrawableObject<
  T extends abstract new (...args: any[]) => CoreWorldObject,
>(Base: T) {
  abstract class Generated extends Base {
    draggable: boolean = false;
    centered: boolean = false;
    calculated: boolean = false;
    sizable: boolean = false;
    snapHoverTimeoutMS: number = 100;

    get selectable() {
      return false;
    }

    abstract drawInternal(context: DrawingContext, args: DrawingArgs): void;

    draw(context: DrawingContext, args: DrawingArgs) {
      const { ctx, vp } = context;
      // get parent positions
      vp.prepareContext(ctx, ...this.world2object);

      this.drawInternal(context, args);
    }

    drawOwnShape(context: DrawingContext) {
      const { ctx } = context;
      const currentWC00 = this.toObjectCoord({ x: 0, y: 0 });
      this.withWorld(context, currentWC00, () => {
        const s = this.shape;
        if (s instanceof Flatten.Polygon) {
          ctx.beginPath();
          ctx.strokeStyle = "#000000";
          ctx.lineWidth = 5;
          s.edges.forEach((f: Flatten.Segment) => {
            ctx.moveTo(f.start.x, f.start.y);
            ctx.lineTo(f.end.x, f.end.y);
          });
          ctx.stroke();
        }
      });
    }

    withScreen({ ctx, vp }: DrawingContext, current: Coord, fun: () => void) {
      const oldTransform = ctx.getTransform();
      const sc = TM.applyToPoint(vp.currToScreenTransform(ctx), current);

      vp.resetCtxTransformToScreen(ctx);
      ctx.translate(sc.x, sc.y);

      fun();

      ctx.setTransform(oldTransform);
    }

    withScreenScale(
      { ctx, vp }: DrawingContext,
      current: Coord,
      fun: () => void,
    ) {
      const oldTransform = ctx.getTransform();
      const t = decomposeMatrix(vp.currToScreenTransform(ctx));
      const sc = TM.applyToPoint(vp.currToScreenTransform(ctx), current);
      vp.resetCtxTransformToScreen(ctx);
      ctx.translate(sc.x, sc.y);

      ctx.rotate(t.a);

      fun();
      ctx.setTransform(oldTransform);
    }

    withWorld({ ctx, vp }: DrawingContext, current: Coord, fun: () => void) {
      const oldTransform = ctx.getTransform();
      const sc = TM.applyToPoint(vp.currToScreenTransform(ctx), current);

      const wc = vp.toWorldCoord(sc);
      vp.prepareContext(ctx);
      ctx.translate(wc.x, wc.y);

      fun();

      ctx.setTransform(oldTransform);
    }

    // Assumes uniform x/y scale
    withWorldScale(
      { ctx, vp }: DrawingContext,
      current: Coord,
      fun: () => void,
    ) {
      const oldTransform = ctx.getTransform();
      const sc = TM.applyToPoint(vp.currToScreenTransform(ctx), current);

      const t = decomposeMatrix(vp.currToSurfaceTransform(ctx));

      const wc = vp.toWorldCoord(sc);
      vp.prepareContext(ctx);
      ctx.translate(wc.x, wc.y);
      ctx.rotate(t.a);

      fun();
      ctx.setTransform(oldTransform);
    }

    // Assumes uniform x/y scale
    withWorldAngle(
      { ctx, vp }: DrawingContext,
      current: Coord,
      fun: () => void,
    ) {
      const oldTransform = ctx.getTransform();

      const t = decomposeMatrix(vp.currToScreenTransform(ctx));

      ctx.translate(current.x, current.y);
      ctx.rotate(-t.a);

      fun();
      ctx.setTransform(oldTransform);
    }

    abstract inBounds(objectCoord: Coord, objectRadius?: number): boolean;

    onMouseDown(_event: MouseEvent, _context: CanvasContext): boolean {
      return false;
    }

    onMouseMove(_event: MouseEvent, _context: CanvasContext): MouseMoveResult {
      return UNHANDLED;
    }

    onMouseUp(_event: MouseEvent, _context: CanvasContext): boolean {
      return false;
    }
  }

  return Generated;
}

// For non-backed objects, eg. resizing handles
export default abstract class DrawableObject extends MakeDrawableObject(
  CoreWorldObject,
) {}

export interface DrawingArgs {
  layerName: string;
  forExport: boolean;
  active: boolean;
  withCalculation: boolean;
  heatmapMode?: HeatmapMode;
  heatmapSettings?: UIState["heatmapSettings"];
  allSelected?: boolean;
  includesHighlight?: boolean;
}

export interface EntityDrawingArgs {
  layerName: string;
  forExport: boolean;
  layerActive: boolean;
  withCalculation: boolean;
  selected: boolean;
  overrideColorList: Color[];
  heatmapMode?: HeatmapMode;
  heatmapSettings?: UIState["heatmapSettings"];
}
